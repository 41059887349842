import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { useSortBy, useTable } from "react-table";
import { orderTypes } from "../../core/constants";
import {
  exportOrderColumns,
  importOrderColumns
} from "../../core/table-columns";

function OrderTable({
  orderType,
  orders,
  selectRow,
  selectedRows,
  query,
  updateQuery,
  selectAll,
  manualSort = true
}) {
  const navigate = useNavigate();
  const columns = useMemo(
    () =>
      orderType.value.includes(orderTypes.IMPORT)
        ? [...importOrderColumns]
        : [...exportOrderColumns],
    [orderType]
  );
  const data = useMemo(() => orders, [orders]);
  const [sortValue, setSortValue] = useState([]);

  const {
    state: { sortBy },
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable(
    {
      columns,
      data,
      manualSortBy: manualSort,
      initialState: {
        sortBy: manualSort ? [] : sortValue
      }
    },
    (hooks) => {
      selectRow &&
        hooks.visibleColumns.push((columns) => [
          {
            id: "selection",
            Header: () => (
              <div className="wh-100 center" onClick={() => selectAll()}>
                {
                  <input
                    className="table-checkbox"
                    type="checkbox"
                    defaultChecked={orders.every((order) =>
                      selectedRows.has(order.id)
                    )}
                  />
                }
              </div>
            ),
            Cell: ({ row }) => (
              <div className="wh-100 center">
                <input
                  className="table-checkbox"
                  type="checkbox"
                  defaultChecked={selectedRows.has(row.original.id)}
                />
              </div>
            )
          },
          ...columns
        ]);
    },
    useSortBy
  );

  useEffect(() => {
    if (manualSort) {
      if (!!sortBy[0]) {
        const { id: sort } = sortBy[0];

        let direction;
        if (query.sort === sort) {
          if (query.direction) {
            direction = query.direction === "DESC" ? "ASC" : "DESC";
          } else {
            direction = "DESC";
          }
        } else {
          direction = "DESC";
        }
        updateQuery({ sort, direction });
      }
    } else {
      setSortValue(sortBy);
    }
  }, [sortBy]);

  const goToOrder = (id) => {
    navigate(`/orders/${id}`);
  };

  return (
    <div className="table-wrap">
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className="p-1 fs-7"
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} className="clickable" style={
                  row.original.status === "AWB"?{backgroundColor: "lightGreen"}:
                  row.original.status === "CANCELLED"?{backgroundColor: "pink"}:
                  row.original.status === "CLOSED"?{backgroundColor: "lightyellow"}:null
                } title={"Status: " + row.original.status + " " + (row.original.reportDepartureDate?row.original.reportDepartureDate:"")}>
                {row.cells.map((cell) => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      onClick={() =>
                        cell.column.id === "selection"
                          ? selectRow(row.original.id)
                          : goToOrder(row.original.id)
                      }
                      className="p-1 pt-3 pb-3"
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default OrderTable;
