import { useState } from "react";
import WaybillService from "../../services/WaybillService";
import LoadingSpinner from "../LoadingSpinner";

function WaybillPdfButton({ waybillId, handlerSequence }) {
  const [loading, setLoading] = useState(false);

  const getPdf = async () => {
    setLoading(true);
    let a;
    try {
      const response = await WaybillService.getPdf(waybillId);
      if (response) {
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        a = document.createElement("a");
        a.href = url;
        a.download = `waybill${
          handlerSequence ? "-" + handlerSequence : ""
        }.pdf`;
        a.click();
      }
    } catch (e) {
      console.log(e);
    } finally {
      if (a) {
        a.remove();
      }
      setLoading(false);
    }
  };

  return (
    <div className="col-md-4 col-lg-3 col-xl-2 p-2">
      <button className="btn btn-primary w-100" onClick={getPdf}>
        {loading ? (
          <LoadingSpinner small={true} fill={true} theme="secondary" />
        ) : (
          "PDF"
        )}
      </button>
    </div>
  );
}

export default WaybillPdfButton;
