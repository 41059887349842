import React from 'react'
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import SimpleInfoTag from "../../components/SimpleInfoTag";
import { useParams } from 'react-router-dom';
import InformationBlock from "../../components/InformationBlock";
import LoadingSpinner from "../../components/LoadingSpinner";
import DownloadButton from "../../components/DownloadButton";
import handlers from '../../core/handlers';



export const AllDetail = (props) => {
  const params = useParams();
  const navigate = useNavigate();
  const { id } = useParams();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState({});
  const [booleanValue, setBooleanValue] = useState(false);
  const [waybill, setWaybill] = useState({});
  const infoBlocks = useMemo(
    () => ({
      BASIC: {
        header: "INFORMATION",
        data: [
          {
            label: "Fakturering",
            data: (order.invoiceStatus)
          },
          {
            label: "Avvikelse",
            data: (order.deviation)
          },
          {
            label: "Pickup date",
            data: (order.pickupDate) 
          },
          {
            label: "Country of destination",
            data: order.CountryOfDestination
          }
        ]
      },
      CALCULATIONS: {
        header: "CALCULATIONS",
        data: [
          { label: "Gross weight", data: order.grossWeight },
          { label: "Volume", data: order.volume },
          { label: "Volume weight", data: order.volumeWeight },
          { label: "Packages", data: order.reportedPackageCount },
          { label: "Revenue", data: order.revenue }
        ]
      },
      CONSIGNEE: {
        header: "CONSIGNEE",
        data: [
          { label: "Namn", data: order.ConsigneeName },
          { label: "Adress", data: order.ConsigneeAddress },
          {
            label: "Ort",
            data: `${order.ConsigneeZip || ""} ${
              order.ConsigneeCity?.city || ""
            }`
          },
          {
            label: "Country code", data: order.ConsigneeCountryCode
          }
        ],
      },
      PICKUP: {
        data: [
          {
            label: "Pickup location",
            data: order.PickUpLocation,
            hide: order.handler === handlers.DHL_IMPORT.value
          },
          {
            label: "Pickup name",
            data: order.PickUpName,
            hide: order.handler === handlers.DHL_IMPORT.value
          },
          { label: "Airway bill", data: order.AirwayBill },
          { label: "House", data: order.House }
        ]
      },
      INSTRUCTIONS: {
        data: [
          { label: "Kommentarer", data: order.comments },
          {
            label: "Avsändarens instruktioner",
            data: order.forwarderInstructions
          }
        ]
      }
    }),
    [order]
  );

  
  const getSignature = async (username, password) => {
    username =localStorage.getItem("username");
    password= localStorage.getItem("password");
    try {
      const options = {
        method: "GET",
        headers: {
         
          Authorization: `Basic ${window.btoa(`${username}:${password}`)}`
        }
      };

      fetch(`/api/v1/status-updates/signature?id=${id}`, options)
      .then((res) => { return res.blob(); })
      .then((data) => {
        console.log(data);
        var a = document.createElement("a");
        a.href = window.URL.createObjectURL(data);
        a.download = "FILENAME.png";
        a.click();
      });
      } catch (error) {
        console.log(error);
      }finally{
        setLoading(false);
      }
      };
  
  
const getOrder = async (username, password) => {
  username =localStorage.getItem("username");
  password= localStorage.getItem("password");
  setLoading(true);
   try {
    const options = {
      method: "GET",
      headers: {
       
        Authorization: `Basic ${window.btoa(`${username}:${password}`)}`
      }
    };

    fetch(`/api/v1/status-updates/get?id=${params.id}`, options)

      .then((response) => response.json())

      .then((data) => {
        console.log(data);
        setOrder(data);
      }).catch((error) => {
        setError("Failed to Fetched");
      });
    } catch (error) {
      console.log(error);
    }finally{
      setLoading(false);
    }
    };

    useEffect (() => {
      getOrder();
    },[]);

// useEffect (() => async () => {
//   setLoading(true);
//     const result = await fetch(`http://localhost:3000/api/v1/status-updates/waybill-list`,
//     {credentials: 'include', headers: {"Authorization": 'Basic R0VPRElTX0lNUE9SVDoxMjM0NTY='}
      
//     });
// }) 
// async function getBooleanValue(username, password) {
//   username =localStorage.getItem("username");
//   password= localStorage.getItem("password");
//   try {
//     const options = {
//       method: "GET",
//       headers: {
       
//         Authorization: `Basic ${window.btoa(`${username}:${password}`)}`
//       }
//     };
//     const response = await fetch(`http://localhost:3000/api/v1/status-updates/waybill-list`, options);
//     const data = await response.json();
//     console.log(data);
//     return data;
//   } catch (error) {
//     console.error(error);
//   }
// }
// useEffect(() => {
//   async function hasSignature() {
//     const value = await getBooleanValue();
//     setBooleanValue(value);
//   }

//   hasSignature();
// }, []);





  return loading ? (
    <LoadingSpinner fill={true} />
  ) : (
    <>
    <div className="container">
        <div className="row">
          <div className="col-12 p-2">
            <div className="info-card">
              <div className="info-card-header">
                <h4 className="title">ORDER</h4>
                <SimpleInfoTag content={order.handler} />
                <SimpleInfoTag content={order.status} />
              </div>
              <div className="info-card-body">
                <div className="row g-0">
                  {Object.entries(infoBlocks).map(
                    ([key, { header, data, hide }]) =>
                      !hide && (
                        <InformationBlock
                          key={key}
                          header={header}
                          data={data}
                          responsive={true}
                        />
                      )
                  )}
                </div>
              </div>
              {/* <div className="info-card-footer">
                <div className="row g-0">
                  <OrderPdfButton orderId={id} />
                  {editOrderButton()}
                  <OrderOptions order={order} handleChange={loadData} />
                </div>
              </div> */}
              <div className="info-card-footer">
              <div className="row g-0">
               
                {waybill.hasSignature && (
                
                  <div className="col-md-4 col-lg-3 col-xl-2 p-2">
                    <DownloadButton
                     
                      buttonText="Signatur"
                      filename={`waybill_${waybill.id}_signature`}
                      getFunction={getSignature}
                      params={waybill.id}
                      
                    />
                  </div>
                  )} 
              </div>
            </div>
            </div>
          </div>
          
          {/* {order.handler !== handlers.DHL_IMPORT.value && (
            <div className="col-lg-8 p-2">
              <div className="info-card">
                <div className="info-card-header">
                  <h4 className="title">PACKAGES</h4>
                </div>
                <div className="info-card-body">
                  <PackageTable packages={order.packages || []} />
                </div>
              </div>
            </div>
          )} */}
          
          {/* <div className="col-lg-4 p-2">
            <div className="info-card">
              <div className="info-card-header">
                <h4 className="title">Bom</h4>
              </div>
              <div className="info-card-body">
                {[
                  order.invoiceStatuses.OPEN.value,
                  order.invoiceStatuses.INVOICE.value
                ].includes(order.invoiceStatus) && (
                  <div className="center-y mb-2">
                    <button
                      className="btn btn-primary"
                      data-bs-toggle="modal"
                      data-bs-target={`#${bomModalId}`}
                    >
                      Ny bom
                    </button>
                  </div>
                )}
                <BomTable boms={order.boms || []} />
              </div>
            </div>
          </div> */}
        </div>
        <button className="btn btn-primary" onClick={()=>navigate(-1)}>Back to list</button>
      </div>
    </>
  )
}
export default AllDetail;


{/* <update>
        <![CDATA[
ALTER TABLE `user`
	ADD COLUMN `handler` ENUM('DHL','DHL_EXPORT','DHL_IMPORT','SCHENKER','GEODIS_IMPORT', 'SCAN_LOGISTICS', 'SGL_IMPORT') AFTER `id`;
        ]]>
    </update> */}

   