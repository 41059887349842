import { useState } from "react";
import { useNavigate } from "react-router-dom";
import i18next from "../core/translation";

function LanguageSelector() {
  const navigate = useNavigate();
  const [languages] = useState({
    sv: "Svenska",
    en: "English"
  });

  const setLanguage = (languageKey) => {
    document.cookie = `language=${languageKey}; expires=Fri, 31 Dec 9999 23:59:59 GMT`;
    i18next.changeLanguage(languageKey);
    navigate("");
  };

  return (
    <div>
      {Object.entries(languages).map(([key, value]) => (
        <div className="pe-2 d-inline" key={key}>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => setLanguage(key)}
          >
            {value}
          </button>
        </div>
      ))}
    </div>
  );
}

export default LanguageSelector;
