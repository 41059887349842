import { useState } from "react";
import {
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  Legend,
  Sector
} from "recharts";
import handlers from "../core/handlers";

function ResponsivePieChart({ data, minHeight = 400 }) {
  const [activeIndex, setActiveIndex] = useState(-1);
  const activeShape = (data) => {
    const {
      cx,
      cy,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload
    } = data;

    return (
      <>
        <text x={cx} y={cy} dy={1} textAnchor="middle" fill="#000">
          {payload.name}
        </text>
        <text x={cx} y={cy} dy={15} textAnchor="middle" fill="#000">
          {payload.value}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius - 1}
          outerRadius={outerRadius + 3}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
      </>
    );
  };

  const setIndex = (_, index) => setActiveIndex(index);
  const resetIndex = () => setActiveIndex(-1);

  return data.length ? (
    <ResponsiveContainer minHeight={minHeight}>
      <PieChart>
        <Pie
          data={data}
          dataKey="value"
          innerRadius={"50%"}
          paddingAngle={2}
          onClick={setIndex}
          onMouseEnter={setIndex}
          onMouseLeave={resetIndex}
          activeIndex={activeIndex}
          activeShape={activeShape}
        >
          {data.map(({ key }, index) => (
            <Cell key={`cell-${index}`} fill={handlers[key].color} />
          ))}
        </Pie>
        <Legend align="left" verticalAlign="top" />
      </PieChart>
    </ResponsiveContainer>
  ) : (
    <div className="w-100 center">
      <span>Ingen data hittad</span>
    </div>
  );
}

export default ResponsivePieChart;
