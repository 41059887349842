import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAsyncDebounce, useFilters, useGlobalFilter, usePagination, useTable, useSortBy } from "react-table";
import LoadingSpinner from "../../components/LoadingSpinner";
import AllDetail from "./AllDetail";
import LogInModal from "./LogInModal";
// import { useParams } from "react-router";


// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length
  const [value, setValue] = React.useState(globalFilter)
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200)

  return (
    <span>
      Search:{' '}
      <input
        value={value || ""}
        onChange={e => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count} records...`}
        style={{
          fontSize: '1.1rem',
          border: '0',
        }}
      />
    </span>
  )
}

function Table({ columns, data }) {

  
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    setGlobalFilter,
    preGlobalFilteredRows,
    page, 
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, globalFilter },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useFilters,
    useGlobalFilter, 
    useSortBy,
    usePagination,
  )
  return (
    <>
      <GlobalFilter
        preGlobalFilteredRows={preGlobalFilteredRows}
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}
      />
      <div className="table-wrap">
      <table {...getTableProps()} className="table table-striped">
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}
                className="p-1 fs-7">
                {column.render('Header')}
                {/* Add a sort direction indicator */}
                <span>
                  {column.isSorted
                    ? column.isSortedDesc
                      ? ' 🔽'
                      : ' 🔼'
                    : ''}
                </span>
              </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}
              className="clickable"
              //onclick function when clicked should go to specific order detail page
              
              //onClick={() => AllDetail(row.original.id)}
              // onClick={() => navigate(`/alldetail/${cellInfo.row.original.id}`)}>
              
              >
                {row.cells.map(cell => {
                  return <td {...cell.getCellProps()} className="p-1 pt-3 pb-3">
                    {cell.render('Cell')}</td>
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
      </div>
      
      <div className="paginator mt-1">
        <button className="btn btn-small" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {'<<'}
        </button>{' '}
        <button className="btn btn-small" onClick={() => previousPage()} disabled={!canPreviousPage}>
          {'<'}
        </button>{' '}
        <button className="btn btn-small" onClick={() => nextPage()} disabled={!canNextPage}>
          {'>'}
        </button>{' '}
        <button className="btn btn-small" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {'>>'}
        </button>{' '}
        <span className="p-2">
          Page{' '}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{' '}
        </span>
        <span>
          | Go to page:{' '}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={e => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0
              gotoPage(page)
            }}
            style={{ width: '100px' }}
          />
        </span>{' '}
        <select
          value={pageSize}
          onChange={e => {
            setPageSize(Number(e.target.value))
          }}
        >
          {[10, 20, 30, 40, 50].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
    </>
  )
}

function OrderList() {
  const [orders, setOrders] = useState([{}]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [loggedIn, setLoggedIn] = useState(false);
  const navigate = useNavigate();

  const columns = React.useMemo(
    () => [
      {
        Header: 'Pickup Date',
        accessor: 'PickupDate',
      },
      {
        Header: 'Consignee Name',
        accessor: 'ConsigneeName',
      },
      {
        Header: 'Destination',
        accessor: 'CountryOfDestination',
      },
      {
        Header: 'Address',
        accessor: 'ConsigneeAddress',
      },
      {
        Header: 'Volume',
        accessor: 'Volume Weight',
      },
      
      {
        Header: 'Gross Weight',
        accessor: 'Gross Weight',
      },
      {
        Header: 'PKGS',
        accessor: 'reportedPackageCount',
      },
      
      
      {
        Header: 'Details',
        accessor: 'details',
        Cell: (cellInfo) => (
          <button type="button" className="btn btn-primary"
            onClick={() => navigate(`/alldetail/${cellInfo.row.original.id}`)}>
            Show Details
          </button>
        )
      }
    ],
    []
  )


  useEffect(() => {
    let p = localStorage.getItem("password");
    let u = localStorage.getItem("username");
    if (p && u) {
    }
  }, []);

  const retrieveOrders = async (username, password) => {
     try {
          const options = {
            method: "GET",
            headers: {
              Authorization: `Basic ${window.btoa(`${username}:${password}`)}`
            }
          };

          fetch("/api/v1/status-updates/order-list", options)
            .then((response) => response.json())
            .then((data) => {
              setLoggedIn(true);
              setError("");
              localStorage.setItem("password", password);
              localStorage.setItem("username", username);
              localStorage.setItem("isLoggedIn", true);
              setOrders(data.orders);
            }).catch((error) => {
              setLoggedIn(false);
              localStorage.setItem("isLoggedIn", false);
              setError("Failed to login");
            });
        } catch (error) {
          setError("Failed to login");
          localStorage.setItem("isLoggedIn", false);
        } finally {
          setLoading(false);
        }
  }

  const handleLogin = async (username, password, closeModal) => {
    setLoading(true);
    setError(false);

    retrieveOrders(username, password).then(() => {
      closeModal();
    }).catch((error) => {
        setError("Failed to login");
    });

  };

  const modalId = "credentials-modal";
  useEffect(() => {
    const element = document.querySelector("#modal-btn");
    element.click();
  }, []);

  const renderAll = () => {
    if (localStorage.getItem("isLoggedIn") !== "true") return <LogInModal error={error} htmlId={modalId} submit={handleLogin} />
    if (loggedIn && !loading) return <Table columns={columns} data={orders} />
    if (loading) return <LoadingSpinner fill={true} />
    return <></>
  }

  useEffect(() => {
    if (localStorage.getItem("isLoggedIn") === "true")
        retrieveOrders(localStorage.getItem("username"), localStorage.getItem("password"))
}, [loading]);

  return <>
    {
    renderAll()
    }
    <button
      id="modal-btn"
      className="d-none"
      data-bs-toggle="modal"
      data-bs-target={`#${modalId}`}
    ></button></>;
}
export default OrderList;

