import { BASE_URL } from "../core/constants";
import { get, post, put } from "../core/fetch";
import { getUrlParams } from "../core/utils";
const BASE_INVOICEBASES_URL = BASE_URL + "/invoicebases";

const InvoiceBasisService = {
  getInvoiceBasis: async (id) => {
    if (id) {
      const url = `${BASE_INVOICEBASES_URL}/${id}`;
      const response = await get(url);
      if (response.ok) {
        return await response.json();
      }
    }
  },
  getInvoiceBases: async (params) => {
    const paramString = getUrlParams(params).toString();
    const url = `${BASE_INVOICEBASES_URL}?${paramString}`;
    const response = await get(url);
    if (response.ok) {
      return await response.json();
    }
  },
  createOrderInvoiceBasis: async (params) => {
    const paramString = getUrlParams(params).toString();
    const url = `${BASE_INVOICEBASES_URL}/orders?${paramString}`;
    const response = await post(url);
    if (response.ok) {
      return await response.json();
    }
  },
  createWaybillInvoiceBasis: async (params) => {
    const paramString = getUrlParams(params).toString();
    const url = `${BASE_INVOICEBASES_URL}/waybills?${paramString}`;
    const response = await post(url);
    if (response.ok) {
      return await response.json();
    }
  },
  closeInvoiceBasis: async (id) => {
    const response = await put(`${BASE_INVOICEBASES_URL}/${id}/close`);
    if (response.ok) {
      return response;
    }
  },
  updateInvoiceBasis: async (id, updates) => {
    const options = {
      body: JSON.stringify(updates)
    };
    const response = await put(`${BASE_INVOICEBASES_URL}/${id}`, options);
    if (response.ok) {
      return response;
    }
  },
  getPdf: async (id) => {
    const response = await get(`${BASE_INVOICEBASES_URL}/${id}/pdf`);
    if (response.ok) {
      return response;
    }
  },
  getExcel: async (id) => {
    const response = await get(`${BASE_INVOICEBASES_URL}/${id}/excel`);
    if (response.ok) {
      return response;
    }
  }
};

export default InvoiceBasisService;
