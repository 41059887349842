import { useState } from "react";
import { orderStatuses } from "../../core/order-constants";
import OrderService from "../../services/OrderService";
import LoadingSpinner from "../LoadingSpinner";

function OrderBulkPdfButton({ show, query }) {
  const [loading, setLoading] = useState(false);

  const getPdf = async () => {
    setLoading(true);
    let a;
    try {
      const response = await OrderService.getPdfs(query);
      if (response) {
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        a = document.createElement("a");
        a.href = url;
        a.download = `orders-${query.handler}-${
          query.status || orderStatuses.OPEN.value
        }.pdf`;
        a.click();
      }
    } finally {
      if (a) {
        a.remove();
      }
      setLoading(false);
    }
  };

  return (
    show && (
      <div className="col-md-3 col-lg-2">
        <button
          className="btn btn-primary w-100"
          disabled={loading}
          onClick={getPdf}
        >
          {loading ? (
            <LoadingSpinner fill={true} small={true} theme="secondary" />
          ) : (
            "Samlad PDF"
          )}
        </button>
      </div>
    )
  );
}

export default OrderBulkPdfButton;
