import { withMsal } from "@azure/msal-react";
import { Component } from "react";
import { loginRequest } from "../msal-config";
import { isAuthenticationError } from "../utils";
import { withNavigation } from "../with-navigation";

class AuthenticationErrorBoundary extends Component {
  async componentDidCatch(error) {
    await this.handleError({ reason: error });
  }

  handleError = async (error) => {
    if (isAuthenticationError(error)) {
      await this.handleAuthenticationError();
    } else {
      throw error;
    }
  };

  handleAuthenticationError = async () => {
    if (this.props.msalContext.inProgress === "none") {
      try {
        await this.props.msalContext.instance.loginPopup(loginRequest);
        this.forceUpdate();
      } catch {
        this.props.navigate("/login");
      }
    }
  };

  componentDidMount() {
    window.addEventListener("unhandledrejection", this.handleRejection);
  }

  componentWillUnmount() {
    window.removeEventListener("unhandledrejection", this.handleRejection);
  }

  handleRejection = async (error) => {
    if (isAuthenticationError(error)) {
      await this.handleAuthenticationError();
    }
  };

  render() {
    return this.props.children;
  }
}

export default withNavigation(withMsal(AuthenticationErrorBoundary));
