import { useMemo } from "react";
import { useTable } from "react-table";
import { bomColumns } from "../../core/table-columns";

function BomTable({ boms }) {
  const columns = useMemo(() => bomColumns, []);
  const data = useMemo(() => boms, [boms]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data
    });

  return (
    <div className="table-wrap">
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()} className="p-1 fs-7">
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {data.length ? (
            rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} className="p-2">
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()} className="p-1 fs-7">
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={columns.length}>Ingen data</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default BomTable;
