import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router";
import { loginRequest } from "../../core/msal-config";
import { useMsal } from "@azure/msal-react";

function Login() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { instance } = useMsal();

  localStorage.setItem("isLoggedIn", false);

  const handleLogin = async () => {
    try {
      const { account } = await instance.loginPopup(loginRequest);
      instance.setActiveAccount(account);
      navigate(state?.from?.pathname || "/");
    } catch (e) {
      console.error(e);
    }
  };

  // const goToOrderCreation = () => {
  //   navigate("/create-order");
  // };
  const goToOrderList = () => {
    
    navigate ("/kundportal");
  }

  return (
    <div className="flex-col center">
      <button className="btn btn-primary" onClick={handleLogin}>
        Logga in
      </button>
      {/* <span className="my-2">eller</span>
      <button className="btn btn-primary" onClick={goToOrderCreation}>
        Skapa order
      </button> */}
      <span className="my-2">eller</span>
      <button className="btn btn-primary" onClick={goToOrderList}>
      Kundportal
      </button>
    </div>
  );
}

export default Login;
