import { useEffect, useState } from "react";
import i18next from "../core/translation";
import { getErrorType } from "../core/utils";

function ErrorView({ error }) {
  const [errorType, setErrorType] = useState("generic");

  useEffect(() => {
    updateErrorType(error);
  }, [error]);

  const updateErrorType = (newError) => {
    const newErrorType = getErrorType(newError.status);
    setErrorType(newErrorType);
  };

  return (
    <div className="container">
      <h4>{i18next.t(`errors.${errorType}.title`)}</h4>
      {i18next.exists(`errors.${errorType}.description`) && (
        <p>{i18next.t(`errors.${errorType}.description`)}</p>
      )}
      {error?.message && (
        <div className="bg-secondary bg-opacity-25 mb-2 p-2 rounded">
          <code>{error.message}</code>
        </div>
      )}
      {error && (
        <div className="bg-secondary bg-opacity-25 p-2 rounded">
          <code>{JSON.stringify(error)}</code>
        </div>
      )}
    </div>
  );
}

export default ErrorView;
