import { useEffect, useMemo, useState } from "react";
import TransporterService from "../../services/TransporterService";
import RequiredMarker from "../Inputs/RequiredMarker";
import LoadingSpinner from "../LoadingSpinner";

function EditTransporterModal({ htmlId, transporter, afterSave }) {
  const [loading, setLoading] = useState(false);
  const [closeModalId] = useState("close-edit-modal");
  const [transporterObject, setTransporterObject] = useState({
    contactInformation: {}
  });
  const formParts = useMemo(
    () => ({
      BASIC: {
        header: "Grundläggande",
        inputs: [
          {
            label: "Transportörnamn",
            property: "name",
            data: transporterObject.name,
            validation: {
              maxLength: 64
            }
          }
        ]
      }
      // TO KEEP TRANSPORT AS SIMPLE AS POSSIBLE THIS IS REMOVED FOR NOW.
      // CONTACT_PERSON: {
      //   header: "Kontaktperson",
      //   inputs: [
      //     {
      //       label: "Kontaktnamn",
      //       property: "contactInformation.contactPerson",
      //       data: transporterObject.contactInformation.contactPerson,
      //       validation: {
      //         optional: true,
      //         maxLength: 128
      //       }
      //     },
      //     {
      //       label: "Email",
      //       property: "contactInformation.email",
      //       data: transporterObject.contactInformation.email,
      //       validation: {
      //         optional: true,
      //         maxLength: 128
      //       }
      //     },
      //     {
      //       label: "Telefonnr",
      //       property: "contactInformation.phoneNumber",
      //       data: transporterObject.contactInformation.phoneNumber,
      //       validation: {
      //         optional: true,
      //         maxLength: 32
      //       }
      //     }
      //   ]
      // },
      // ADDRESS: {
      //   header: "Adress",
      //   sizes: "col-12",
      //   inputs: [
      //     {
      //       label: "Adress 1",
      //       property: "contactInformation.address1",
      //       data: transporterObject.contactInformation.address1,
      //       validation: {
      //         optional: true,
      //         maxLength: 256
      //       }
      //     },
      //     {
      //       label: "Adress 2",
      //       property: "contactInformation.address2",
      //       data: transporterObject.contactInformation.address2,
      //       validation: {
      //         optional: true,
      //         maxLength: 256
      //       }
      //     },
      //     {
      //       label: "Postnr",
      //       property: "contactInformation.zipCode",
      //       data: transporterObject.contactInformation.zipCode,
      //       validation: {
      //         optional: true,
      //         maxLength: 16
      //       }
      //     },
      //     {
      //       label: "Ort",
      //       property: "contactInformation.city",
      //       data: transporterObject.contactInformation.city,
      //       validation: {
      //         optional: true,
      //         maxLength: 64
      //       }
      //     },
      //     {
      //       label: "Land",
      //       property: "contactInformation.country",
      //       data: transporterObject.contactInformation.country,
      //       validation: {
      //         optional: true,
      //         maxLength: 64
      //       }
      //     }
      //   ]
      // }
    }),
    [transporterObject]
  );

  useEffect(() => {
    if (transporter) {
      setTransporterObject({ ...transporter });
    }
  }, [transporter]);

  const saveTransporter = async (event) => {
    event.preventDefault();

    setLoading(true);

    const saveMethod = transporterObject.id
      ? "updateTransporter"
      : "createTransporter";

    try {
      const response = await TransporterService[saveMethod](transporterObject);
      if (response) {
        afterSave();
        closeModal();
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = ({ property, value }) => {
    if (!value.trim().length) {
      value = value.trim();
    }

    const properties = property.split(".");

    if (properties.length > 1) {
      const clonedPart = { ...transporterObject[properties[0]] };
      clonedPart[properties[1]] = value;
      setTransporterObject((prev) => ({
        ...prev,
        [properties[0]]: clonedPart
      }));
    } else {
      setTransporterObject((prev) => ({ ...prev, [properties[0]]: value }));
    }
  };

  const closeModal = () => {
    if (!transporterObject.id) {
      setTransporterObject({ contactInformation: {} });
    }
    const element = document.querySelector(`#${closeModalId}`);
    element.click();
  };

  return (
    <div className="modal fade" id={htmlId} tabIndex="-1" aria-hidden="true">
      <div className="modal-dialog modal-xl modal-dialog-centered">
        <form onSubmit={saveTransporter} className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">
              {(transporterObject.id ? "Redigera" : "Skapa") + " transportör"}
            </h5>
            <button
              id={closeModalId}
              className="d-none btn-close"
              type="button"
              data-bs-dismiss="modal"
            ></button>
          </div>
          <div className="modal-body">
            <div className="container-fluid">
              <div className="row g-0">
                {Object.entries(formParts).map(
                  ([key, { header, sizes = "col-xl-6", inputs }]) => (
                    <div key={key} className={sizes + " p-2"}>
                      <div className="w-100 rounded border border-secondary p-2">
                        <span className="fs-5 fw-light">{header}</span>
                        {inputs.map(({ label, data, property, validation }) => (
                          <div className="spread mb-2" key={label}>
                            <label className="col-4">
                              {label}
                              {!validation?.optional && <RequiredMarker />}:
                            </label>
                            <input
                              type="text"
                              className="col-8"
                              value={data || ""}
                              required={!validation?.optional}
                              maxLength={validation?.maxLength}
                              onChange={({ target: { value } }) =>
                                handleChange({
                                  property,
                                  value
                                })
                              }
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <div className="col-12 col-sm-4 col-md-3 col-lg-2">
              <button
                type="button"
                className="btn btn-secondary w-100"
                onClick={closeModal}
                disabled={loading}
              >
                Avbryt
              </button>
            </div>

            <div className="col-12 col-sm-4 col-md-3 col-lg-2">
              <button
                type="submit"
                className="btn btn-primary w-100"
                disabled={loading}
              >
                {loading ? (
                  <LoadingSpinner small theme="secondary" />
                ) : (
                  "Spara transportör"
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default EditTransporterModal;
