import { useState } from "react";
import RequiredMarker from "./RequiredMarker";

function PackageInputs({
  packages,
  index,
  packagesProperty = "packages",
  orderPackage,
  handleChange
}) {
  const [inputs] = useState([
    {
      label: "Pickup location",
      property: "pickupLocation",
      width: "col-12",
      validation: {
        maxLength: 256
      }
    },
    {
      label: "Type",
      property: "type",
      width: "col-6",
      validation: {
        maxLength: 256
      }
    },
    {
      label: "Package no",
      property: "packageNo",
      width: "col-6",
      validation: {
        maxLength: 256
      }
    },
    {
      label: "Dangerous Goods Code",
      property: "dangerousGoodsCode",
      width: "col-12",
      validation: {
        maxLength: 256
      }
    },
    {
      label: "Length",
      property: "length",
      type: "number",
      width: "col-6 col-sm-4",
      validation: {
        min: 0.001
      }
    },
    {
      label: "Width",
      property: "width",
      type: "number",
      width: "col-6 col-sm-4",
      validation: {
        min: 0.001
      }
    },
    {
      label: "Height",
      property: "height",
      type: "number",
      width: "col-6 col-sm-4",
      validation: {
        min: 0.001
      }
    },
    {
      label: "Gross Weight",
      property: "grossWeight",
      type: "number",
      width: "col-6 col-sm-4",
      validation: {
        min: 0.001
      }
    },
    {
      label: "Volume",
      property: "volume",
      type: "number",
      width: "col-6 col-sm-4",
      validation: {
        min: 0.001
      }
    }
  ]);

  const handlePackageChange = ({ property, value, type }) => {
    if (type === "number") {
      value = value ? parseFloat(value) : 0;
    } else if (typeof value === "string") {
      if (!value.trim().length) {
        value = value.trim();
      }
    }

    packages[index][property] = value;
    handleChange({ packages });
  };

  const removeMe = (event) => {
    event.stopPropagation();
    packages.splice(index, 1);
    handleChange({ [packagesProperty]: packages });
  };

  return (
    <>
      {packages.length > 1 && (
        <i
          className="bi bi-x-square clickable g-0 pa-tr-4"
          onClick={removeMe}
        ></i>
      )}
      {inputs.map(({ label, property, width, type, validation }) => (
        <div className={(width || "col-lg-6") + " mb-2"} key={label}>
          <label className="d-block">
            {label}
            {!validation.optional && <RequiredMarker />}
          </label>
          <input
            className="w-100"
            type={type || "text"}
            value={orderPackage[property] || (type === "number" ? 0 : "")}
            step={type === "number" ? "any" : undefined}
            required
            maxLength={validation?.maxLength}
            min={type === "number" ? validation.min || 0.001 : undefined}
            onChange={({ target: { value } }) =>
              handlePackageChange({
                property,
                value,
                type
              })
            }
          />
        </div>
      ))}
    </>
  );
}

export default PackageInputs;
