import { useState } from "react";
import { useNavigate } from "react-router-dom";
import InvoiceBasesService from "../../services/InvoiceBasisService";
import LoadingSpinner from "../LoadingSpinner";

function CreateInvoiceBasisButton({ show, selectedHandler }) {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const createInvoiceBasis = async () => {
    setLoading(true);
    try {
      const response = await InvoiceBasesService.createWaybillInvoiceBasis({
        handler: selectedHandler
      });
      if (response) {
        navigate(`/invoicebases/${response.id}`);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    show && (
      <div className="mb-2">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-4 col-lg-3">
              <button
                className="btn btn-primary w-100"
                disabled={loading || !selectedHandler}
                onClick={createInvoiceBasis}
              >
                {loading ? (
                  <LoadingSpinner fill={true} />
                ) : (
                  "Skapa fakturaunderlag"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  );
}

export default CreateInvoiceBasisButton;
