import { useNavigate } from "react-router";
import { getHandlerName, toLocaleDateString } from "../../core/utils";
import SimpleInfoTag from "../SimpleInfoTag";

function OrderGrid({ orders, isImport }) {
  const navigate = useNavigate();
  const goToOrder = (id) => {
    navigate(`/orders/${id}`);
  };

  const gridItem = (order) => (
    <div key={order.id} className="col-sm-6 mb-2">
      <div
        onClick={() => goToOrder(order.id)}
        className="wh-100 p-1 bg-white clickable border border-primary rounded shadowed-sm"
      >
        <div className="tags w-100 mb-1">
          <SimpleInfoTag content={getHandlerName(order.handler)} />
          <SimpleInfoTag content={order.pickupDate} />
        </div>
        {gridItemContent([
          { label: "HOUSE", value: order.house, size: 6 },
          { label: "MASTER", value: order.airwayBill, size: 6 }
        ])}
        {isImport ? importContent(order) : exportContent(order)}
        {gridItemContent([
          { label: "PKGS", value: order.packageCount, size: 3 },
          { label: "GR WEIGHT", value: order.grossWeight, size: 6 },
          { label: "M³", value: order.volume, size: 3 }
        ])}
      </div>
    </div>
  );

  const gridItemContent = (contents) => (
    <div className="row g-0 p-1 mb-1 border border-secondary rounded">
      {contents.map(({ label, value, size = 12 }) => (
        <div key={label} className={`col-${size} flex-col`}>
          <span className="fw-light">{label}</span>
          <span>{value || "n/a"}</span>
        </div>
      ))}
    </div>
  );

  const importContent = ({ consigneeAddress }) =>
    gridItemContent([{ label: "CONSIGNEE", value: consigneeAddress?.name }]);

  const exportContent = ({ pickupLocation }) =>
    gridItemContent([{ label: "PICKUP LOCATION", value: pickupLocation }]);

  return <div className="row">{orders.map((order) => gridItem(order))}</div>;
}

export default OrderGrid;
