import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { useSearchParams } from "react-router-dom";
import OrderTable from "../../components/Orders/OrderTable";
import OrderFilter from "../../components/Orders/OrderFilter";
import Paginator from "../../components/Paginator";
import OrderService from "../../services/OrderService";
import OrderGrid from "../../components/Orders/OrderGrid";
import { useWindowSize } from "../../core/hooks";
import {
  invoiceStatuses,
  orderTypes,
  windowBreakpoints
} from "../../core/constants";
import LoadingSpinner from "../../components/LoadingSpinner";
import handlers from "../../core/handlers";
import CreateWaybillButton from "../../components/Orders/CreateWaybillButton";
import CreateInvoiceBasisButton from "../../components/Orders/CreateInvoiceBasisButton";
import { orderStatuses } from "../../core/order-constants";
import CloseForBulkAWB from "../../components/Orders/CloseForBulkAWB";
import CloseForBulkInvoice from "../../components/Orders/CloseForBulkInvoice";
import OrderBulkPdfButton from "../../components/Orders/OrderBulkPdfButton";
import OrdersExcelButton from "../../components/Orders/OrdersExcelButton";

function Orders() {
  const { pathname } = useLocation();
  const [controlledPathname, setControlledPathname] = useState({
    value: pathname
  });
  const [width] = useWindowSize();
  const [loading, setLoading] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const [orders, setOrders] = useState([]);
  const [query, setQuery] = useState({
    handler: "",
    status: orderStatuses.OPEN.value,
    sort: "pickupDate",
    direction: "ASC",
    ...Object.fromEntries(searchParams)
  });
  const [pageInfo, setPageInfo] = useState({ pageIndex: 0, pageCount: 0 });
  const [selectedRows, setSelectedRows] = useState(new Set());

  useEffect(() => {
    if (pathname !== controlledPathname.value) {
      setControlledPathname({ value: pathname });
      setQuery({
        handler: "",
        status: orderStatuses.OPEN.value,
        sort: "pickupDate",
        direction: "ASC"
      });
    }
  }, [pathname, controlledPathname.value]);

  useEffect(() => {
    const stringifiedQuery = JSON.stringify(query);
    setSearchParams(JSON.parse(stringifiedQuery));
    loadData();
  }, [query]);

  const loadData = async () => {
    setLoading(true);
    try {
      const response = await OrderService.getOrders(pathname, query);
      const { content, number: pageIndex, totalPages: pageCount } = response;
      setOrders(content);
      setPageInfo({ pageIndex, pageCount });
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (newPage) => {
    updateQuery({ page: newPage });
  };

  const updateQuery = (updates) => {
    const updateQuery = { ...query, ...updates };
    if (
      updateQuery.handler !== query.handler ||
      updateQuery.status !== query.status
    ) {
      setSelectedRows(new Set());
    }
    setQuery(updateQuery);
  };

  const ordersContent = () =>
    width > windowBreakpoints.medium ? (
      <OrderTable
        orderType={controlledPathname}
        orders={orders}
        selectRow={ selectRow}
        selectAll={selectAll}
        selectedRows={selectedRows}
        query={query}
        updateQuery={updateQuery}
      />
    ) : (
      <OrderGrid
        isImport={pathname.includes(orderTypes.IMPORT)}
        orders={orders}
      />
    );

  const showCreateWaybill = () =>
    (!query.status || query.status === orderStatuses.OPEN.value) &&
    (!query.invoiceStatuses ||
      query.invoiceStatuses === invoiceStatuses.OPEN.value) &&
    [
      handlers.DHL_IMPORT.value,
      handlers.DHL_EXPORT.value,
      handlers.GEODIS_IMPORT.value,
      handlers.GEODIS_EXPORT.value,
      handlers.SGL_IMPORT.value
    ].includes(query.handler);

  const showCreateInvoiceBasis = () =>
    query.status === orderStatuses.CLOSED.value &&
    query.invoiceStatuses === invoiceStatuses.INVOICE.value &&
    [
      handlers.DHL.value, 
      handlers.DHL_EXPORT.value, 
      handlers.DHL_IMPORT.value, 
      handlers.SCAN_LOGISTICS.value, 
      handlers.GEODIS_IMPORT.value, 
      handlers.GEODIS_EXPORT.value,
      handlers.SGL_IMPORT.value,
      handlers.DHL_ERICSSON.value,
      handlers.DHL_HUSQVARNA.value,
      handlers.DHL_SKF.value,
      handlers.DHL_VOLVO.value,
      handlers.DHL_XYLEM.value,
    ].includes(query.handler);

  const showGetPdf = () =>
    ([orderStatuses.OPEN.value, orderStatuses.AWB.value].includes(
      query.status
    ) ||
      !query.status) &&
    query.handler;

  const selectRow = (id) => {
    if (selectedRows.has(id)) {
      selectedRows.delete(id);
      setSelectedRows((prev) => new Set([...prev]));
    } else {
      setSelectedRows((prev) => new Set([...prev, id]));
    }
    refreshTable();
  };

  //Set all as selected
  const selectAll = () => {
    const allSelected = orders.every((order) => selectedRows.has(order.id));
    if (allSelected) {
      orders.forEach((order) => selectedRows.delete(order.id));
    } else {
      orders.forEach((order) => selectedRows.add(order.id));
    }
    setSelectedRows((prev) => new Set([...prev]));
    refreshTable();
  };

  const refreshTable = () => {
    setControlledPathname((prev) => ({ ...prev }));
    setOrders((prev) => [...prev]);
  };
  const handleCloseAWBSuccess = async () => {
    setSelectedRows(new Set());
    await loadData();
  };
  const handleCloseInvoiceSuccess = async () => {
    setSelectedRows(new Set());
    await loadData();
  };
  const showCloseForInvoice = () =>
    query.status === orderStatuses.AWB.value &&
    (!query.invoiceStatuses ||
      query.invoiceStatuses === invoiceStatuses.OPEN.value);

  const showCloseForAWB = () =>
    query.status === orderStatuses.OPEN.value &&
    (!query.invoiceStatuses ||
      query.invoiceStatuses === invoiceStatuses.OPEN.value);

  return (
    <div className="container h-100">
      <div className="mb-2">
        <OrderFilter
          isImport={pathname.includes(orderTypes.IMPORT)}
          query={query}
          updateQuery={updateQuery}
        />
      </div>
      <div className="container-fluid mb-2">
        <div className="row options-row">
          <OrdersExcelButton show={true} query={query} />
          <OrderBulkPdfButton show={showGetPdf()} query={query} />
          <CloseForBulkAWB
            show={showCloseForAWB()}
            selectedIds={selectedRows}
            handleSuccess={handleCloseAWBSuccess}
          />
          <CloseForBulkInvoice
            show={showCloseForInvoice()}
            selectedIds={selectedRows}
            handleSuccess={handleCloseInvoiceSuccess}
          />
          <CreateInvoiceBasisButton
            show={showCreateInvoiceBasis()}
            selectedHandler={query.handler}
            disabled={!orders.length}
          />
          <CreateWaybillButton
            show={showCreateWaybill()}
            selectedIds={selectedRows}
          />
        </div>
      </div>
      {!!selectedRows.size && (
        <div className="mb-2 center-y">
          <span className="me-3">{selectedRows.size} valda ordrar</span>
          <button
            className="btn btn-danger btn-sm"
            onClick={() => {
              setSelectedRows(new Set());
              refreshTable();
            }}
          >
            Rensa valda ordrar
          </button>
        </div>
      )}
      <div className="mb-2">
        {loading ? <LoadingSpinner fill={true} /> : ordersContent()}
      </div>
      <Paginator
        handlePageChange={handlePageChange}
        pageIndex={pageInfo.pageIndex}
        pageCount={pageInfo.pageCount}
      />
    </div>
  );
}

export default Orders;
