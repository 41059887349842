import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import LoadingSpinner from "../../components/LoadingSpinner";
import TransportOrderFormContent from "../../components/TransportOrders/TransportOrderFormContent";
import TransportOrderService from "../../services/TransportOrderService";

function TransportOrderCreate() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const [transportOrder, setTransportOrder] = useState({});

  useEffect(() => {
    let transporterId = Number(searchParams.get("transporterId"));
    if (!transporterId) {
      transporterId = null;
    }
    setTransportOrder((prev) => ({ ...prev, transporterId }));
  }, []);

  const updateTransportOrder = (property, update) => {
    if (typeof update === "string") {
      if (!update.trim().length) {
        update = update.trim();
      }
    }

    if (property === "transporterId") {
      update = Number(update);
    }

    setTransportOrder((prev) => ({ ...prev, [property]: update }));
  };

  const saveTransportOrder = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const response = await TransportOrderService.createTransportOrder(
        transportOrder
      );
      if (response) {
        navigate("/transport-orders/" + response.id);
      } else {
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      throw e;
    }
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-12 p-2">
          <form
            onSubmit={(event) => saveTransportOrder(event)}
            className="info-card"
          >
            <div className="info-card-header">
              <h4 className="title">Skapa bil</h4>
            </div>
            <div className="info-card-body">
              <div className="row options-row px-2">
                <div className="col-md-2">
                  <button
                    disabled={
                      loading ||
                      (!transportOrder.waybillIds?.length &&
                        !transportOrder.orderIds?.length)
                    }
                    type="submit"
                    className="btn btn-primary w-100"
                  >
                    {loading ? (
                      <LoadingSpinner fill small theme="secondary" />
                    ) : (
                      "Spara"
                    )}
                  </button>
                </div>
              </div>
              <TransportOrderFormContent
                transportOrder={transportOrder}
                onUpdate={updateTransportOrder}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default TransportOrderCreate;
