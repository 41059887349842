import { useMemo, useState } from "react";
import { useNavigate } from "react-router";
import handlers from "../../core/handlers";
import { orderStatuses } from "../../core/order-constants";
import { isImport } from "../../core/utils";
import OrderService from "../../services/OrderService";
import LoadingSpinner from "../LoadingSpinner";

function OrderOptions({ order, handleChange }) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const options = useMemo(
    () => [
      {
        label: "Stäng för AWB",
        path: "close-awb",
        warn: true,
        disabled: !order.pickupDate,
        disabledMessage: "Order måste ha pickup date",
        show: order.status === orderStatuses.OPEN.value && !isImport(order)
      },
      {
        label: "Stäng för fakturering",
        path: "close-invoice",
        warn: true,
        disabled: !order.reported,
        disabledMessage: "Order måste rapporteras",
        show:
          order.status === orderStatuses.AWB.value &&
          [handlers.DHL.value, handlers.SCAN_LOGISTICS.value].includes(
            order.handler
          )
      },
      {
        label: "Visa fraktsedel",
        path: `/waybills/${order.waybillId}`,
        navigation: true,
        show: !!order.waybillId
      },
      {
        label: "Makulera",
        path: "cancel",
        warn: true,
        show:
          !order.reported &&
          ![orderStatuses.CANCELLED.value, orderStatuses.CLOSED.value].includes(
            order.status
          )
      },
      {
        label: "Fel sändning",
        path: "wrong-shipment",
        warn: true,
        show:
          !order.reported &&
          ![orderStatuses.CANCELLED.value, orderStatuses.CLOSED.value].includes(
            order.status
          )
      },
      {
        label: "Ta bort från fraktsedel",
        path: "reopen-awb",
        warn: true,
        show:
          order.status === orderStatuses.AWB.value &&
          isImport(order) &&
          !!order.waybillId
      }
    ],
    [order]
  );

  const handleOptionClick = async ({ path, navigation }) => {
    setLoading(path);
    try {
      if (navigation) {
        return navigate(path);
      }

      await handleStatusChange(path);
    } finally {
      setLoading(false);
    }
  };

  const handleStatusChange = async (path) => {
    const response = await OrderService.updateOrder(order.id, {}, path);
    if (response) {
      handleChange();
    }
  };

  return (
    <>
      {options.map(
        (option) =>
          option.show && (
            <div key={option.path} className="col-md-4 col-lg-3 col-xl-2 p-2">
              <div
                className="wh-100"
                title={option.disabled ? option.disabledMessage : ""}
              >
                <button
                  disabled={loading === option.path || option.disabled}
                  className="btn btn-primary w-100"
                  onClick={() => handleOptionClick(option)}
                >
                  {loading === option.path ? (
                    <LoadingSpinner small={true} theme="light" />
                  ) : (
                    option.label
                  )}
                </button>
              </div>
            </div>
          )
      )}
    </>
  );
}

export default OrderOptions;
