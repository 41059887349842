import { useState, useEffect } from "react";
import AddressInputs from "../../components/Inputs/AddressInputs";
import Calendar from "react-calendar";
import { toLocaleDateString } from "../../core/utils";
import PackageInputs from "../../components/Inputs/PackageInputs";
import RequiredMarker from "../../components/Inputs/RequiredMarker";
import SubmitOrderModal from "../../components/SubmitOrderModal";

function CreateOrder() {
  const [order, setOrder] = useState({
    packages: [{}],
    consigneeAddress: {}
  });
  const [orderCreated, setOrderCreated] = useState(false);
  const [chosenDate, setChosenDate] = useState(new Date());
  const [basicInputs] = useState([
    {
      label: "Reference",
      property: "handlerReference",
      validation: {
        maxLength: 35
      }
    },
    {
      label: "Consignors Reference",
      property: "consignorsReference",
      validation: {
        maxLength: 256
      }
    },
    {
      label: "Shipment Reference",
      property: "shipmentReference",
      validation: {
        maxLength: 256
      }
    },
    {
      label: "Backbone Reference",
      property: "backboneReferenceId",
      validation: {
        optional: true,
        maxLength: 256
      }
    },
    {
      label: "Airway Bill",
      property: "airwayBill",
      validation: {
        maxLength: 256
      }
    },
    {
      label: "House no",
      property: "house",
      validation: {
        maxLength: 256
      }
    },
    {
      label: "Destination Country Code",
      property: "countryOfDestination",
      validation: {
        maxLength: 2
      },
      title: "ISO 3166-1 alpha-2"
    },
    {
      label: "Customs Code",
      property: "customsCode",
      validation: {
        optional: true,
        maxLength: 64
      }
    },
    {
      label: "Forwarder Instructions",
      property: "forwarderInstructions",
      validation: {
        maxLength: 256,
        optional: true
      }
    },
    {
      label: "Comments",
      property: "comments",
      validation: {
        maxLength: 256,
        optional: true
      }
    }
  ]);
  const [pickupInputs] = useState([
    {
      label: "Pickup Name",
      property: "pickupName",
      validation: {
        maxLength: 256
      }
    },
    {
      label: "Pickup Location",
      property: "pickupLocation",
      validation: {
        maxLength: 256
      }
    },
    {
      label: "Volume",
      property: "volume",
      validation: {
        min: 0.001
      },
      width: "col-6 col-md-3",
      type: "number"
    },
    {
      label: "Gross weight",
      property: "grossWeight",
      validation: {
        min: 0.001
      },
      width: "col-6 col-md-3",
      type: "number"
    }
  ]);

  const modalId = "credentials-modal";

  useEffect(() => {
    handleChange({ pickupDate: toLocaleDateString(chosenDate) });
  }, [chosenDate]);

  const submitForm = async (username, password) => {
    const options = {
      method: "POST",
      headers: {
        ["Content-Type"]: "application/json",
        Authorization: `Basic ${window.btoa(`${username}:${password}`)}`
      },
      body: JSON.stringify(order)
    };
    const response = await fetch("/api/v1/incoming-orders", options);
    if (response.ok) {
      setOrder({
        packages: [{}],
        consigneeAddress: {}
      });
      setOrderCreated(true);
    }
    return response;
  };

  const getCredentials = (event) => {
    event.preventDefault();
    const modalButton = document.querySelector("#modal-btn");
    modalButton.click();
  };

  const handleChange = (updates) => {
    setOrder((prev) => ({ ...prev, ...updates }));
  };

  const handleSimpleChange = ({ property, value, type }) => {
    if (type === "number") {
      value = value ? parseFloat(value) : 0;
    } else if (typeof value === "string") {
      if (!value.trim().length) {
        value = value.trim();
      }
    }
    handleChange({ [property]: value });
  };

  const addPackage = () => {
    const packages = order.packages;
    packages.push({});
    handleChange({ packages });
  };

  return (
    <>
      <SubmitOrderModal htmlId={modalId} submit={submitForm} />
      <button
        id="modal-btn"
        className="d-none"
        data-bs-toggle="modal"
        data-bs-target={`#${modalId}`}
      ></button>
      <div className="container h-100">
        {orderCreated ? (
          <div className="col-12 flex-col center">
            <i className="fs-xl text-success bi bi-check-circle"></i>
            <h2>Order created!</h2>
            <button
              className="btn btn-primary"
              onClick={() => setOrderCreated(false)}
            >
              Create new order
            </button>
          </div>
        ) : (
          <div className="row">
            <form className="col-12" onSubmit={getCredentials}>
              <div className="row">
                <div className="col-lg-6 p-2 mb-2">
                  <fieldset className="w-100 bg-light p-4 rounded">
                    <legend>Basic information</legend>

                    {basicInputs.map(
                      ({ label, property, validation = {}, title }) => (
                        <div className="col-12 mb-2" key={label}>
                          <label className="d-block">
                            {label}
                            {!validation.optional && <RequiredMarker />}
                          </label>
                          <input
                            className="w-100"
                            type="text"
                            value={order[property] || ""}
                            maxLength={validation.maxLength || undefined}
                            required={!validation.optional}
                            title={title || undefined}
                            onChange={({ target: { value } }) =>
                              handleSimpleChange({ property, value })
                            }
                          />
                        </div>
                      )
                    )}
                    <div className="col-md-6"></div>
                  </fieldset>
                </div>

                <div className="col-lg-6 p-2 mb-2">
                  <fieldset className="w-100 bg-light p-4 rounded">
                    <legend>Pickup Information</legend>

                    <label className="d-block">Pickup date</label>
                    <div className="row">
                      <div className="col-12 mb-2 center">
                        <Calendar
                          minDate={new Date()}
                          value={chosenDate}
                          onChange={setChosenDate}
                        />
                      </div>

                      {pickupInputs.map(
                        ({
                          label,
                          property,
                          type = "text",
                          width = "col-12",
                          validation = {}
                        }) => (
                          <div className={width + " mb-2"} key={label}>
                            <label className="d-block">
                              {label}
                              {!validation.optional && <RequiredMarker />}
                            </label>
                            <input
                              className="w-100"
                              type={type}
                              maxLength={
                                type === "text"
                                  ? validation.maxLength
                                  : undefined
                              }
                              step={type === "number" ? "any" : undefined}
                              min={
                                type === "number" ? validation.min : undefined
                              }
                              value={
                                order[property] || (type === "number" ? 0 : "")
                              }
                              required={!validation.optional}
                              onChange={({ target: { value } }) =>
                                handleSimpleChange({ property, value, type })
                              }
                            />
                          </div>
                        )
                      )}
                    </div>
                  </fieldset>
                </div>

                <div className="col-lg-6 p-2 mb-2">
                  <fieldset className="w-100 bg-light p-4 rounded">
                    <AddressInputs
                      entity={order.consigneeAddress}
                      legend="Consignors Address"
                      addressProperty="consigneeAddress"
                      handleChange={handleChange}
                    />
                  </fieldset>
                </div>

                <div className="col-lg-6 p-2 mb-2">
                  <fieldset className="bg-light p-4 rounded">
                    {order.agentAddress ? (
                      <AddressInputs
                        entity={order.agentAddress}
                        legend="Agent Address"
                        addressProperty="agentAddress"
                        optional={true}
                        handleChange={handleChange}
                      />
                    ) : (
                      <button
                        className="btn btn-secondary w-100"
                        type="button"
                        onClick={() => handleChange({ agentAddress: {} })}
                      >
                        Add agent address
                      </button>
                    )}
                  </fieldset>
                </div>
              </div>

              <div className="row">
                <div className="col-12 p-2 mb-2">
                  <fieldset className="bg-light p-4 rounded">
                    <legend className="spread mb-0">
                      <span>Packages</span>
                      <button
                        className="btn btn-secondary"
                        type="button"
                        onClick={addPackage}
                      >
                        Add package
                      </button>
                    </legend>
                  </fieldset>
                </div>
                {order.packages.map((orderPackage, index) => (
                  <div
                    key={"package_" + index}
                    className="col-md-6 col-lg-4 p-2 mb-2"
                  >
                    <fieldset className="bg-light p-4 rounded position-relative">
                      <div className="row">
                        <PackageInputs
                          packages={order.packages}
                          index={index}
                          orderPackage={orderPackage}
                          handleChange={handleChange}
                        />
                      </div>
                    </fieldset>
                  </div>
                ))}
              </div>

              <button className="btn btn-primary" type="submit">
                Submit
              </button>
            </form>
          </div>
        )}
      </div>
    </>
  );
}

export default CreateOrder;
