import handlers from "./handlers";
import { getHandlerName, round, toLocaleDateString } from "./utils";

const dateAccessor = (row, property = "pickupDate") =>
  row[property] ? toLocaleDateString(row[property]) : "";

const addValuesAccessor = (array, property) => {
  let value;
  if (Array.isArray(array)) {
    const reducedValue = array
      .map((item) => item[property])
      .reduce((previousValue, currentValue) => previousValue + currentValue, 0);

    value = round(reducedValue);
  }

  return value || "-";
};

export const exportOrderColumns = [
  {
    Header: "PICKUP DATE",
    accessor: "pickupDate"
  },
  {
    Header: "SPEDITÖR",
    id: "handler",
    accessor: (row) => getHandlerName(row.handler)
  },
  {
    Header: "REFERENCE",
    accessor: "consignorsReference"
  },
  {
    Header: "HOUSE",
    accessor: "house"
  },
  {
    Header: "MASTER",
    accessor: "airwayBill"
  },
  {
    Header: "PU",
    accessor: "pickupLocation"
  },
  {
    Header: "DEST.",
    accessor: "countryOfDestination"
  },
  {
    Header: "PKGS",
    disableSortBy: true,
    accessor: "packageCount"
  },
  {
    Header: "GR WEIGHT",
    accessor: "grossWeight"
  },
  {
    Header: "M³",
    accessor: "volume"
  }
];

export const importOrderColumns = [
  {
    Header: "PICKUP DATE",
    accessor: "pickupDate"
  },
  {
    Header: "SPEDITÖR",
    id: "handler",
    accessor: (row) => getHandlerName(row.handler)
  },
  {
    Header: "HOUSE",
    accessor: "house"
  },
  {
    Header: "MASTER",
    accessor: "airwayBill"
  },
  {
    Header: "CONSIGNEE",
    accessor: "consigneeAddress.name"
  },
  {
    Header: "ADDRESS",
    accessor: "consigneeAddress.addr"
  },
  {
    Header: "CITY",
    id: "city",
    accessor: (row) =>
      `${row.consigneeAddress?.zip || ""} ${row.consigneeAddress?.zip || ""}`
  },
  {
    Header: "PKGS",
    disableSortBy: true,
    accessor: "packageCount"
  },
  {
    Header: "GR WEIGHT",
    accessor: "grossWeight"
  },
  {
    Header: "M³",
    accessor: "volume"
  }
];

export const packageColumns = [
  {
    Header: "PICKUP LOCATION",
    accessor: "pickupLocation"
  },
  {
    Header: "TYPE",
    accessor: "type"
  },
  {
    Header: "PACKAGE",
    accessor: "packageNo"
  },
  {
    Header: "UNDG#",
    accessor: "dangerousGoodsCode"
  },
  {
    Header: "DIMENSION (LxWxH)",
    accessor: "dimension"
  },
  {
    Header: "GR MASS (kg)",
    accessor: "grossWeight"
  },
  {
    Header: "VOLUME (m³)",
    accessor: "volume"
  }
];

export const bomColumns = [
  {
    Header: "DATUM",
    id: "day",
    accessor: (row) => dateAccessor(row, "day")
  }
];

export const waybillColumns = [
  {
    Header: "#",
    accessor: "handlerSequence"
  },
  {
    Header: "SPEDITÖR",
    id: "handler",
    accessor: (row) => getHandlerName(row.handler)
  },
  {
    Header: "PICKUP DATE",
    id: "pickupDate",
    accessor: (row) => dateAccessor(row)
  },
  {
    Header: "CONSIGNEE",
    id: "orders.consigneeAddress.name",
    accessor: (row) => row.orders[0]?.consigneeAddress?.name || ""
  },
  {
    Header: "CITY",
    id: "orders.consigneeAddress.zip",
    accessor: (row) => {
      const address = row.orders[0]?.consigneeAddress;
      return `${address?.zip || ""} ${address?.city || ""}`;
    }
  },
  {
    Header: "PKGS",
    disableSortBy: true,
    accessor: "totalPackageCount"
  },
  {
    Header: "GR WEIGHT",
    disableSortBy: true,
    accessor: "totalGrossWeight"
  },
  {
    Header: "VOLUME (m³)",
    disableSortBy: true,
    accessor: "totalVolume"
  },
  {
    Header: "ANMÄRKNING",
    accessor: "remark",
    Cell: ({ row }) => {
      return (
        <div className="w-100 center text-warning">
          {row.original.remark && (
            <i
              className="bi bi-exclamation-triangle-fill fs-5"
              title={row.original.remark}
            ></i>
          )}
        </div>
      );
    }
  }
];

export const invoiceBasesColumns = [
  {
    Header: "ID",
    accessor: "id"
  },
  {
    Header: "SPEDITÖR",
    id: "handler",
    accessor: (row) => getHandlerName(row.handler)
  },
  {
    Header: "SKAPAD",
    id: "created",
    accessor: (row) => dateAccessor(row, "created")
  },
  {
    Header: "SKAPAD AV",
    accessor: "createdBy.name"
  },
  {
    Header: "ANTAL RADER",
    disableSortBy: true,
    accessor: "rows.length"
  }
];

export const invoiceBasisRowColumns = [
  {
    Header: "PICKUP DATE",
    accessor: "order.pickupDate"
  },
  {
    Header: "MASTER",
    accessor: "order.airwayBill"
  },
  {
    Header: (row) => {
      return row.data[0]
        ? row.data[0]?.handler === handlers.DHL_IMPORT.value
          ? "HOUSE"
          : "CONSIGNORS REF"
        : "";
    },
    id: "house/consRef",
    accessor: (row) =>
      row.order.handler === handlers.DHL_IMPORT.value
        ? row.order.house
        : row.order.consignorsReference
  },
  {
    Header: "PACKAGES",
    disableSortBy: true,
    accessor: "order.packageCount"
  },
  {
    Header: "GROSS WEIGHT",
    accessor: "order.grossWeight"
  },
  {
    Header: "VOLUME",
    accessor: "order.volume"
  },
  {
    Header: "VOLUME WT",
    accessor: "order.volumeWeight"
  },
  {
    Header: "MAX WEIGHT",
    id: "maxWeight",
    accessor: (row) =>
      row.order.grossWeight > row.order.volumeWeight
        ? row.order.grossWeight
        : row.order.volumeWeight
  },
  {
    Header: "REVENUE",
    accessor: "order.revenue"
  },
  {
    Header: "EXTR HÄMT",
    accessor: "order.extraPickup"
  },
  {
    Header: "MIN VÄRDE",
    accessor: "order.minimumValue"
  },
  {
    Header: "KOMMENTAR",
    accessor: "order.invoiceComments"
  }
];

export const transporterColumns = [
  {
    Header: "NAMN",
    accessor: "name"
  },
  {
    Header: "ANTAL BILAR",
    disableSortBy: true,
    accessor: "numberOfTransportOrders"
  }
  // TO KEEP TRANSPORT AS SIMPLE AS POSSIBLE THIS IS REMOVED FOR NOW.
  // {
  //   Header: "KONTAKTPERSON",
  //   accessor: "contactInformation.contactPerson"
  // },
  // {
  //   Header: "EMAIL",
  //   accessor: "contactInformation.email"
  // },
  // {
  //   Header: "TELEFON",
  //   accessor: "contactInformation.phoneNumber"
  // }
];

export const transportOrderColumns = [
  { Header: "id", accessor: "id" },
  { Header: "NAMN", accessor: "name" },
  {
    Header: "ANTAL RADER",
    id: "numberOfRows",
    accessor: ({ isExport, orders, waybills }) =>
      isExport ? orders.length : waybills.length
  },
  {
    Header: "PAKET",
    id: "packages",
    accessor: ({ isExport, orders, waybills }) =>
      addValuesAccessor(
        isExport ? orders : waybills,
        isExport ? "packageCount" : "totalPackageCount"
      )
  },
  {
    Header: "VIKT",
    id: "grossWeight",
    accessor: ({ isExport, orders, waybills }) =>
      addValuesAccessor(
        isExport ? orders : waybills,
        isExport ? "grossWeight" : "totalGrossWeight"
      )
  },
  {
    Header: "VOLYM (m³)",
    id: "volume",
    accessor: ({ isExport, orders, waybills }) =>
      addValuesAccessor(
        isExport ? orders : waybills,
        isExport ? "volume" : "totalVolume"
      )
  }
];
