import { useState } from "react";
import { useLocation } from "react-router-dom";
import { toLocaleDateString } from "../../core/utils";
import OrderService from "../../services/OrderService";
import LoadingSpinner from "../LoadingSpinner";

function OrdersExcelButton({ show, query }) {
  const { pathname } = useLocation();
  const [loading, setLoading] = useState(false);

  const getExcel = async () => {
    setLoading(true);
    let a;
    try {
      const type = pathname === "/import" ? "IMPORT" : "EXPORT";
      const response = await OrderService.getExcel({ ...query, type });
      if (response) {
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        a = document.createElement("a");
        a.href = url;
        a.download = `orders-${type.toLowerCase()}-${toLocaleDateString(new Date())}.xlsx`;
        a.click();
      }
    } finally {
      if (a) {
        a.remove();
      }
      setLoading(false);
    }
  };

  return (
    show && (
      <div className="col-md-3 col-lg-2">
        <button
          className="btn btn-primary w-100"
          disabled={loading}
          onClick={getExcel}
        >
          {loading ? (
            <LoadingSpinner fill={true} small={true} theme="secondary" />
          ) : (
            "Excel"
          )}
        </button>
      </div>
    )
  );
}

export default OrdersExcelButton;
