import { useEffect, useState } from "react";

function Paginator({ handlePageChange, pageIndex, pageCount }) {
  const [canGoPrevious, setCanGoPrevious] = useState(false);
  const [canGoNext, setCanGoNext] = useState(false);

  useEffect(() => {
    setCanGoPrevious(pageIndex > 0);
    setCanGoNext(pageIndex < pageCount - 1);
  }, [pageIndex, pageCount]);

  return (
    <div className="paginator mt-1">
      <button
        className="btn btn-small"
        onClick={() => handlePageChange(0)}
        disabled={!canGoPrevious}
      >
        {"<<"}
      </button>
      <button
        className="btn btn-small"
        onClick={() => handlePageChange(pageIndex - 1)}
        disabled={!canGoPrevious}
      >
        {"<"}
      </button>
      <span className="p-2">{`${pageIndex + 1} of ${
        pageCount || 1
      } pages`}</span>
      <button
        className="btn btn-small"
        onClick={() => handlePageChange(pageIndex + 1)}
        disabled={!canGoNext}
      >
        {">"}
      </button>
      <button
        className="btn btn-small"
        onClick={() => handlePageChange(pageCount - 1)}
        disabled={!canGoNext}
      >
        {">>"}
      </button>
    </div>
  );
}

export default Paginator;
