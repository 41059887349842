import { useState } from "react";
import OrderService from "../../services/OrderService";
import LoadingSpinner from "../LoadingSpinner";

function CloseForBulkInvoice({ show, selectedIds, handleSuccess }) {
  const [loading, setLoading] = useState(false);

  const closeForInvoice = async () => {
    setLoading(true);
    try {
      const response = await OrderService.closeForInvoice(
        JSON.stringify([...selectedIds])
      );
      console.log("The Responce is: " + response);
      
      if (response) {
        handleSuccess();
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    show && (
        <div className="col-md-3 col-lg-2">
        <button
          className="btn btn-primary w-100"
                disabled={!selectedIds.size || loading}
                onClick={closeForInvoice}
              >
                {loading ? (
                  <LoadingSpinner fill={true} />
                ) : (
                  "Stäng för fakturering"
                )}
              </button>
            </div>
          
    )
  );
}

export default CloseForBulkInvoice;
