import { BASE_URL } from "../core/constants";
import { get, post, put } from "../core/fetch";
import { getUrlParams } from "../core/utils";
const BASE_ORDERS_URL = BASE_URL + "/orders";

const OrderService = {
  getOrders: async (path = "", params) => {
    const paramString = getUrlParams(params).toString();
    const url = `${BASE_ORDERS_URL}${path}?${paramString}`;
    const response = await get(url);
    return await response.json();
  },
  getOrder: async (id) => {
    if (id) {
      const url = `${BASE_ORDERS_URL}/${id}`;
      const response = await get(url);
      if (response.ok) {
        return await response.json();
      }
    }
  },
  updateOrder: async (id, updates, path) => {
    const options = {
      body: updates
    };
    const url = `${BASE_ORDERS_URL}/${id}${path ? `/${path}` : ""}`;
    const response = await put(url, options);
    if (response.ok) {
      return response;
    }
  },
  createBom: async (id, bomDate) => {
    const options = {
      body: bomDate
    };
    const url = `${BASE_ORDERS_URL}/${id}/boms`;
    const response = await post(url, options);
    if (response.ok) {
      return response;
    }
  },
  getPdf: async (id) => {
    const response = await get(`${BASE_ORDERS_URL}/${id}/pdf`);
    if (response.ok) {
      return response;
    }
  },
  getPdfs: async (params) => {
    const paramString = getUrlParams(params).toString();
    const response = await get(`${BASE_ORDERS_URL}/pdf?${paramString}`);
    if (response.ok) {
      return response;
    }
  },
  getExcel: async (params) => {
    const paramString = getUrlParams(params).toString();
    const response = await get(`${BASE_ORDERS_URL}/excel?${paramString}`);
    if (response.ok) {
      return response;
    }
  },
  closeForAWB: async (ids) => {
    const options = {
      body: ids
    };
    const response = await put(`${BASE_ORDERS_URL}/close-bulk-awb`, options);
    if (response.ok) {
      return response;
      
    }
    console.log(response);
  },
  closeForInvoice: async (ids) => {
    const options = {
      body: ids
    };
    const response = await put(`${BASE_ORDERS_URL}/close-bulk-invoice`, options);
    if (response.ok) {
      return response;
    }
  }

};

export default OrderService;
