import { useEffect, useState } from "react";

function LogInModal({ htmlId, submit, error }) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  
  const handleSubmit = async (event) => {
    submit(username, password, closeModal);
  };

  const closeModal = (event) => {
    setUsername("");
    setPassword("");
    const element = document.querySelector(`#close-bom-modal`);
    element.click();
  };



  return (
    <div className="modal fade" id={htmlId} tabIndex="-1" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Log In</h5>
            <button
              type="button"
              className="btn-close"
              onClick={closeModal}
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <form className="row">
              <div className="col-12">
                <label className="d-block">User</label>
                <input
                  className="w-100"
                  type="text"
                  value={username}
                  onChange={({ target: { value } }) => setUsername(value)}
                  required
                />
              </div>
              <div className="col-12">
                <label className="d-block">Password</label>
                <input
                  className="w-100"
                  type="password"
                  value={password}
                  onChange={({ target: { value } }) => setPassword(value)}
                  required
                />
              </div>
              {error && (
                <div className="col-12 mt-2">
                  <span className="text-danger">{error}</span>
                </div>
              )}
            </form>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="d-none"
              data-bs-dismiss="modal"
              id="close-bom-modal"
            ></button>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={closeModal}
            >
              Abort
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={(event) => handleSubmit(event)}
            >
              Log In
            </button>
            {/* <div className={`${!error ? "hidden" : ""}`}>
          INCORRECT PASSWORD 
        </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default LogInModal;
