import { useState } from "react";
import WaybillService from "../../services/WaybillService";
import LoadingSpinner from "../LoadingSpinner";

function CloseForInvoiceButton({ show, selectedIds, handleSuccess }) {
  const [loading, setLoading] = useState(false);

  const closeForInvoice = async () => {
    setLoading(true);
    try {
      const response = await WaybillService.closeForInvoice(
        JSON.stringify([...selectedIds])
      );
      if (response) {
        handleSuccess();
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    show && (
      <div className="mb-2">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-3 col-lg-2">
              <button
                className="btn btn-primary w-100"
                disabled={!selectedIds.size || loading}
                onClick={closeForInvoice}
              >
                {loading ? (
                  <LoadingSpinner fill={true} />
                ) : (
                  "Stäng för fakturering"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  );
}

export default CloseForInvoiceButton;
