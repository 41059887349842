import { useState } from "react";
import RequiredMarker from "./RequiredMarker";

function AddressInputs({
  entity,
  addressProperty,
  legend,
  optional,
  handleChange
}) {
  const [inputs] = useState([
    {
      label: "Name",
      property: "name",
      validation: {
        maxLength: 64
      }
    },
    {
      label: "Address",
      property: "addr",
      validation: {
        maxLength: 64
      }
    },
    {
      label: "Zip",
      property: "zip",
      validation: {
        maxLength: 10
      }
    },
    {
      label: "City",
      property: "city",
      validation: {
        maxLength: 64
      }
    },
    {
      label: "Country Code",
      property: "countryCode",
      validation: {
        maxLength: 2
      },
      title: "ISO 3166-1 alpha-2"
    }
  ]);

  const handleAddressChange = ({ property, value }) => {
    if (typeof value === "string") {
      if (!value.trim().length) {
        value = value.trim();
      }
    }

    entity[property] = value;
    handleChange({ [addressProperty]: entity });
  };

  return (
    <>
      <legend className={optional ? "spread" : ""}>
        <span>{legend}</span>
        {optional && (
          <i
            className="bi bi-x-square clickable"
            onClick={() => handleChange({ [addressProperty]: undefined })}
          ></i>
        )}
      </legend>

      {inputs.map(({ label, property, validation, title }) => (
        <div className="col-12 mb-2" key={label}>
          <label className="d-block">
            {label}
            {!validation.optional && <RequiredMarker />}
          </label>
          <input
            className="w-100"
            type="text"
            value={entity[property] || ""}
            maxLength={validation.maxLength || undefined}
            required
            title={title || undefined}
            onChange={({ target: { value } }) =>
              handleAddressChange({
                property,
                value
              })
            }
          />
        </div>
      ))}
    </>
  );
}

export default AddressInputs;
