import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import InvoiceBasesFilter from "../../components/InvoiceBases/InvoiceBasesFilter";
import InvoiceBasesTable from "../../components/InvoiceBases/InvoiceBasesTable";
import LoadingSpinner from "../../components/LoadingSpinner";
import Paginator from "../../components/Paginator";
import { invoiceBasesColumns } from "../../core/table-columns";
import InvoiceBasisService from "../../services/InvoiceBasisService";

function InvoiceBases() {
  const [loading, setLoading] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const [columns] = useState(invoiceBasesColumns);
  const [invoiceBases, setInvoiceBases] = useState([]);
  const [query, setQuery] = useState({
    status: "",
    size: 20,
    ...Object.fromEntries(searchParams)
  });
  const [pageInfo, setPageInfo] = useState({ pageIndex: 0, pageCount: 0 });

  useEffect(() => {
    const stringifiedQuery = JSON.stringify(query, (key, value) =>
      value ? value : undefined
    );
    setSearchParams(JSON.parse(stringifiedQuery));
    loadData();
  }, [query]);

  const loadData = async () => {
    setLoading(true);
    try {
      const response = await InvoiceBasisService.getInvoiceBases(query);
      const { content, number: pageIndex, totalPages: pageCount } = response;
      setInvoiceBases(content);
      setPageInfo({ pageIndex, pageCount });
    } finally {
      setLoading(false);
    }
  };

  const updateQuery = (updates) => {
    setQuery({ ...query, ...updates });
  };

  const handlePageChange = (newPage) => {
    updateQuery({ page: newPage });
  };

  return (
    <div className="container">
      <h2>Fakturaunderlag</h2>
      <div className="mb-2">
        <InvoiceBasesFilter query={query} updateQuery={updateQuery} />
      </div>
      <div className="mb-2">
        {loading ? (
          <LoadingSpinner fill={true} />
        ) : (
          <InvoiceBasesTable
            columns={columns}
            invoiceBases={invoiceBases}
            updateQuery={updateQuery}
            query={query}
          />
        )}
      </div>
      <Paginator
        handlePageChange={handlePageChange}
        pageIndex={pageInfo.pageIndex}
        pageCount={pageInfo.pageCount}
      />
    </div>
  );
}

export default InvoiceBases;
