import { useEffect, useState } from "react";
import LoadingSpinner from "../LoadingSpinner";
import RequiredMarker from "../Inputs/RequiredMarker";
import TransportOrderService from "../../services/TransportOrderService";
import { useParams } from "react-router-dom";
import DateTimePicker from "react-datetime-picker";
import { toDateTimeString } from "../../core/utils";

function ReportTransportOrderModal({ htmlId, isExport, handleChange }) {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [pickupDate, setPickupDate] = useState(new Date());
  const [departureDate, setDepartureDate] = useState(new Date());
  const [form, setForm] = useState({});

  useEffect(() => {
    setForm((prev) => ({
      ...prev,
      reportPickupDate: pickupDate ? toDateTimeString(pickupDate) : undefined
    }));
  }, [pickupDate]);

  useEffect(() => {
    setForm((prev) => ({
      ...prev,
      reportDepartureDate: departureDate
        ? toDateTimeString(departureDate)
        : undefined
    }));
  }, [departureDate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await TransportOrderService.reportTransportOrder(
        id,
        form
      );
      if (response?.ok) {
        clearData();
        setLoading(false);
        const element = document.querySelector(`#close-${htmlId}`);
        element.click();
        await handleChange();
      }
    } finally {
      setLoading(false);
    }
  };

  const clearData = () => {
    setPickupDate(new Date());
    setDepartureDate(new Date());
    setForm({});
  };

  const updateForm = ({ target: { value, id } }) => {
    setForm((prev) => ({ ...prev, [id]: value }));
  };

  return (
    <div className="modal fade" id={htmlId} tabIndex="-1" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <form className="modal-content" onSubmit={handleSubmit}>
          <div className="modal-header">
            <h5 className="modal-title">Rapportera bil</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={clearData}
              disabled={loading}
            ></button>
          </div>
          <div className="modal-body">
            <div className="mb-3">
              <p className="mb-1">
                Observera att detta steg ersätter rapportera med QR-kod.
              </p>
              <p className="mb-1">
                Om rapport redan finns ändras inte existerande rapport-datum.
              </p>
            </div>
            {isExport && (
              <div className="mb-3">
                <label className="form-label">Hämtad</label>
                <DateTimePicker
                  className="w-100"
                  disableClock={true}
                  format="yyyy-MM-dd HH:mm"
                  value={pickupDate}
                  onChange={setPickupDate}
                  maxDate={new Date()}
                  disabled={loading}
                />
              </div>
            )}
            <div className={`${isExport ? "" : "mb-3"}`}>
              <label className="form-label">
                Lämnad {!isExport && <RequiredMarker />}
              </label>
              <DateTimePicker
                className="w-100"
                disableClock={true}
                format="yyyy-MM-dd HH:mm"
                value={departureDate}
                onChange={setDepartureDate}
                maxDate={new Date()}
                disabled={loading}
              />
            </div>
            {!isExport && (
              <div>
                <label className="form-label">
                  Kvitterad <RequiredMarker />
                </label>
                <input
                  className="form-control"
                  id="acknowledgedBy"
                  value={form.acknowledgedBy || ""}
                  onChange={updateForm}
                  type="text"
                  required
                  disabled={loading}
                />
              </div>
            )}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
              id={"close-" + htmlId}
              onClick={clearData}
              disabled={loading}
            >
              Avbryt
            </button>
            <button
              type="submit"
              className="btn btn-primary"
              disabled={
                loading || (!form.reportPickupDate && !form.reportDepartureDate)
              }
            >
              {loading ? (
                <LoadingSpinner small theme="secondary" fill />
              ) : (
                "Rapportera bil"
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ReportTransportOrderModal;
