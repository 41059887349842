import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";
import LoadingSpinner from "../../components/LoadingSpinner";
import DownloadButton from "../../components/DownloadButton";
import { exportOrderColumns, waybillColumns } from "../../core/table-columns";
import TransportOrderService from "../../services/TransportOrderService";
import AddContentModal from "../../components/TransportOrders/AddContentModal";
import AsyncButton from "../../components/AsyncButton";
import ReportTransportOrderModal from "../../components/Reporting/ReportTransportOrderModal";
import UpdateTransportOrderModal from "../../components/TransportOrders/UpdateTransportOrderModal";
import TransportOrderContentTable from "../../components/TransportOrders/TransportOrderContentTable";
import SimpleInfoTag from "../../components/SimpleInfoTag";

function TransportOrderDetails() {
  const { id } = useParams();
  const [addContentModalId] = useState("add-content-modal");
  const [reportModalId] = useState("report-modal");
  const [updateModalId] = useState("update-modal");
  const [modalStatus, setModalStatus] = useState(false);
  const [loading, setLoading] = useState(true);
  const [transportOrder, setTransportOrder] = useState({});
  const [contentProperty, setContentProperty] = useState("");
  const optionsColumn = useMemo(
    () => ({
      id: "options",
      Cell: ({ row: { original } }) => {
        return (
          <div className="wh-100">
            {!original.reported && (
              <AsyncButton
                buttonText="Ta bort"
                clickFunction={async () => await removeRow(original)}
                small
              />
            )}
          </div>
        );
      }
    }),
    [transportOrder]
  );
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    loadData();
  }, [id]);

  useEffect(() => {
    const { isExport } = transportOrder;
    setContentProperty(isExport ? "orders" : "waybills");
    const baseColumns = isExport ? exportOrderColumns : waybillColumns;
    setColumns([...baseColumns, optionsColumn]);
  }, [transportOrder]);

  const loadData = async () => {
    setLoading(true);
    try {
      const response = await TransportOrderService.getTransportOrder(id);
      setTransportOrder(response);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateTransportOrder = async () => {
    setModalStatus(false);
    loadData();
  };

  const emptyTransportOrder = async () => {
    const response = await TransportOrderService.removeContent(id);
    if (response) {
      loadData();
    }
  };

  const removeRow = async ({ id, transportOrderId }) => {
    const response = await TransportOrderService.removeContentById(
      transportOrderId,
      id
    );

    if (response) {
      const property = transportOrder.isExport ? "orders" : "waybills";
      setTransportOrder((prev) => ({
        ...prev,
        [property]: prev[property].filter((content) => content.id !== id)
      }));
    }
  };

  return (
    <>
      <AddContentModal
        htmlId={addContentModalId}
        isExport={transportOrder.isExport}
        transportOrderId={id}
        isOpen={modalStatus}
        setModalStatus={setModalStatus}
        handleChange={handleUpdateTransportOrder}
      />
      <ReportTransportOrderModal
        htmlId={reportModalId}
        isExport={transportOrder.isExport}
        handleChange={loadData}
      />
      <UpdateTransportOrderModal
        htmlId={updateModalId}
        transportOrder={transportOrder}
        handleChange={loadData}
      />
      {loading ? (
        <LoadingSpinner fill />
      ) : (
        <div className="container">
          <div className="row">
            <div className="col-12 p-2">
              <div className="info-card">
                <div className="info-card-header">
                  <h4 className="title">{transportOrder.name}</h4>
                  <SimpleInfoTag
                    content={transportOrder.isExport ? "Export" : "Import"}
                  />
                </div>
                <div className="info-card-body">
                  <div className="row options-row mb-3">
                    <div className="col-md-2">
                      <DownloadButton
                        buttonText="PDF"
                        filename={`${transportOrder.name}.pdf`}
                        getFunction={TransportOrderService.getPdf}
                        params={id}
                        disabled={!transportOrder[contentProperty].length}
                      />
                    </div>
                    <div className="col-md-2">
                      <DownloadButton
                        buttonText="Excel"
                        filename={`${transportOrder.name}.xlsx`}
                        getFunction={TransportOrderService.getExcel}
                        params={id}
                        disabled={!transportOrder[contentProperty].length}
                      />
                    </div>
                    <div className="col-md-3 col-lg-2">
                      <button
                        className="btn btn-primary w-100 text-nowrap"
                        data-bs-toggle="modal"
                        data-bs-target={`#${addContentModalId}`}
                        onClick={() => setModalStatus(true)}
                        disabled={!transportOrder.active}
                      >
                        {"Lägg till " +
                          (transportOrder.isExport ? "ordrar" : "fraktsedlar")}
                      </button>
                    </div>
                    <div className="col-md-2">
                      <AsyncButton
                        buttonText="Töm bil"
                        clickFunction={emptyTransportOrder}
                        disabled={
                          !transportOrder[contentProperty].length ||
                          transportOrder[contentProperty].every(
                            ({ reported }) => reported
                          )
                        }
                      />
                    </div>
                    <div className="col-md-3 col-lg-2">
                      <button
                        className="btn btn-primary w-100 text-nowrap"
                        data-bs-toggle="modal"
                        data-bs-target={`#${reportModalId}`}
                        disabled={transportOrder?.[contentProperty]?.every(
                          ({ reported }) => reported
                        )}
                      >
                        Rapportera bil
                      </button>
                    </div>
                    <div className="col-md-2">
                      <button
                        className="btn btn-primary w-100 text-nowrap"
                        data-bs-toggle="modal"
                        data-bs-target={`#${updateModalId}`}
                        disabled={!transportOrder.active}
                      >
                        Redigera bil
                      </button>
                    </div>
                  </div>
                  <TransportOrderContentTable
                    columns={columns}
                    content={transportOrder[contentProperty]}
                    isExport={transportOrder.isExport}
                    clickable={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default TransportOrderDetails;
