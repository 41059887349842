import { BASE_URL } from "../core/constants";
import { get, post, put } from "../core/fetch";
import { getUrlParams } from "../core/utils";
const BASE_WAYBILLS_URL = BASE_URL + "/waybills";

const WaybillService = {
  getWaybill: async (id) => {
    if (id) {
      const url = `${BASE_WAYBILLS_URL}/${id}`;
      const response = await get(url);
      if (response.ok) {
        return await response.json();
      }
    }
  },
  getWaybills: async (path = "", params) => {
    const paramString = getUrlParams(params).toString();
    const url = `${BASE_WAYBILLS_URL}${path}?${paramString}`;
    const response = await get(url);
    if (response.ok) {
      return await response.json();
    }
  },
  getWaybillList: async (params) => {
    const paramString = new URLSearchParams(params).toString();
    const response = await get(`${BASE_WAYBILLS_URL}?${paramString}`);
    if (response.ok) {
      return await response.json();
    }
  },
  createWaybill: async (ids) => {
    const options = {
      body: ids
    };
    const response = await post(`${BASE_WAYBILLS_URL}`, options);
    if (response.ok) {
      return await response.json();
    }
  },
  closeForInvoice: async (ids) => {
    const options = {
      body: ids
    };
    const response = await put(`${BASE_WAYBILLS_URL}/close-invoice`, options);
    if (response.ok) {
      return response;
    }
  },
  getPdf: async (id) => {
    const response = await get(`${BASE_WAYBILLS_URL}/${id}/pdf`);
    if (response.ok) {
      return response;
    }
  },
  getWaybillByToken: async (jwt) => {
    if (jwt) {
      try {
        const url = `${BASE_WAYBILLS_URL}/jwt?token=${jwt}`;
        const response = await fetch(url);
        if (response.ok) {
          if (response.status === 200) {
            return await response.json();
          } else {
            return {};
          }
        }
      } catch (e) {
        console.log(e);
      }
    }
  },
  getSignature: async (id) => {
    try {
      const url = `${BASE_WAYBILLS_URL}/${id}/signature`;
      const response = await get(url);
      if (response.ok) {
        return response;
      }
    } catch (e) {
      console.log(e);
    }
  }
};

export default WaybillService;
