import AsyncButton from "./AsyncButton";

function DownloadButton({
  buttonClass,
  buttonText = "Hämta",
  filename = "file",
  getFunction,
  params,
  disabled
}) {
  const getFile = async () => {
    let a;
    try {
      const response = await getFunction(params);
      if (response) {
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        a = document.createElement("a");
        a.href = url;
        a.download = filename;
        a.click();
      }
    } finally {
      if (a) {
        a.remove();
      }
    }
  };

  return (
    <AsyncButton
      buttonText={buttonText}
      buttonClass={buttonClass}
      clickFunction={getFile}
      disabled={disabled}
    />
  );
}

export default DownloadButton;
