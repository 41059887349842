import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import LoadingSpinner from "../../components/LoadingSpinner";
import Paginator from "../../components/Paginator";
import EditTransporterModal from "../../components/Transporters/EditTransporterModal";
import TransporterFilter from "../../components/Transporters/TransporterFilter";
import TransporterTable from "../../components/Transporters/TransporterTable";
import TransporterService from "../../services/TransporterService";

function Transporters() {
  const [loading, setLoading] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const [query, setQuery] = useState({
    ...Object.fromEntries(searchParams)
  });
  const [transporters, setTransporters] = useState([]);
  const [pageInfo, setPageInfo] = useState({ pageIndex: 0, pageCount: 0 });
  const [addModalId] = useState("add-transporter-modal");

  useEffect(() => {
    const stringifiedQuery = JSON.stringify(query);
    setSearchParams(JSON.parse(stringifiedQuery));
    loadData();
  }, [query]);

  const loadData = async () => {
    setLoading(true);
    try {
      const response = await TransporterService.getTransporters(query);
      const { content, number: pageIndex, totalPages: pageCount } = response;
      setTransporters(content);
      setPageInfo({ pageIndex, pageCount });
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const updateQuery = (updates) => {
    const updatedQuery = { ...query, ...updates };
    setQuery(updatedQuery);
  };

  const handlePageChange = (newPage) => {
    updateQuery({ page: newPage });
  };

  return (
    <>
      <EditTransporterModal htmlId={addModalId} afterSave={loadData} />
      <div className="container h-100">
        <h2>Transportörer</h2>
        <div className="container-fluid mb-2">
          <div className="row options-row">
            <div className="col-md-3 col-lg-3 col-xl-2">
              <button
                className="btn btn-primary w-100"
                data-bs-toggle="modal"
                data-bs-target={`#${addModalId}`}
              >
                Lägg till transportör
              </button>
            </div>
          </div>
        </div>
        <div className="mb-2">
          <TransporterFilter query={query} updateQuery={updateQuery} />
        </div>
        <div className="mb-2">
          {loading ? (
            <LoadingSpinner fill={true} />
          ) : (
            <TransporterTable
              transporters={transporters}
              query={query}
              updateQuery={updateQuery}
            />
          )}
        </div>
        <Paginator
          handlePageChange={handlePageChange}
          pageIndex={pageInfo.pageIndex}
          pageCount={pageInfo.pageCount}
        />
      </div>
    </>
  );
}

export default Transporters;
