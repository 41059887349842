import { useState } from "react";
import InvoiceBasisService from "../../services/InvoiceBasisService";
import LoadingSpinner from "../LoadingSpinner";

function InvoiceBasisPdfButton({ show, invoiceBasisId }) {
  const [loading, setLoading] = useState(false);

  const getPdf = async () => {
    setLoading(true);
    let a;
    try {
      const response = await InvoiceBasisService.getPdf(invoiceBasisId);
      if (response) {
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        a = document.createElement("a");
        a.href = url;
        a.download = `invoice-basis-${invoiceBasisId}.pdf`;
        a.click();
      }
    } finally {
      if (a) {
        a.remove();
      }
      setLoading(false);
    }
  };

  return (
    show && (
      <div className="col-md-3 col-lg-2">
        <button className="btn btn-primary w-100" onClick={getPdf}>
          {loading ? (
            <LoadingSpinner small={true} fill={true} theme="secondary" />
          ) : (
            "PDF"
          )}
        </button>
      </div>
    )
  );
}

export default InvoiceBasisPdfButton;
