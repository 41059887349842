import { useEffect, useState } from "react";
import TransportOrderService from "../../services/TransportOrderService";
import RequiredMarker from "../Inputs/RequiredMarker";
import LoadingSpinner from "../LoadingSpinner";

function UpdateTransportOrderModal({ htmlId, transportOrder, handleChange }) {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState(transportOrder?.name || "");

  useEffect(() => {
    setName(transportOrder?.name || "");
  }, [transportOrder]);

  const updateTransportOrder = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const response = await TransportOrderService.updateTransportOrder(
        transportOrder.id,
        { name }
      );
      if (response) {
        handleChange();
        const element = document.querySelector(`#close-${htmlId}`);
        element.click();
      }
    } finally {
      setLoading(false);
    }
  };

  const resetName = () => {
    setName(transportOrder.name);
  };

  return (
    <div className="modal fade" id={htmlId} tabIndex="-1" aria-hidden="true">
      <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered">
        <form
          onSubmit={(e) => updateTransportOrder(e)}
          className="modal-content"
        >
          <div className="modal-header">
            <h5 className="modal-title">Redigera bil</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={resetName}
            ></button>
          </div>
          <div className="modal-body">
            <label className="form-label">
              Namn <RequiredMarker />
            </label>
            <input
              className="form-control"
              required
              value={name || ""}
              maxLength={64}
              onChange={({ target: { value } }) => setName(value)}
            />
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
              id={"close-" + htmlId}
              onClick={resetName}
            >
              Avbryt
            </button>
            <button
              type="submit"
              className="btn btn-primary"
              disabled={loading}
            >
              {loading ? (
                <LoadingSpinner small theme="secondary" fill />
              ) : (
                "Spara"
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default UpdateTransportOrderModal;
