import { useState } from "react";

function SubmitOrderModal({ htmlId, submit }) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await submit(username, password);
      if (response?.ok) {
        return closeModal();
      } else {
        setPassword("");
      }

      if (response?.status === 401) {
        setError("Bad credentials.");
      } else if (response?.status === 403) {
        setUsername("");
        setError("You lack permission to this action.");
      } else if (response?.status === 400) {
        setError("Form invalid, check your input.");
      } else {
        throw new Error("Unkown error when submitting.");
      }
    } catch (e) {
      console.error(e);
      setError("Unknown error, please try again!");
    }
  };

  const closeModal = (event) => {
    if (event) {
      event.preventDefault();
    }

    setUsername("");
    setPassword("");
    setError(null);
    const element = document.querySelector(`#close-bom-modal`);
    element.click();
  };

  return (
    <div className="modal fade" id={htmlId} tabIndex="-1" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Credentials</h5>
            <button
              type="button"
              className="btn-close"
              onClick={closeModal}
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <form className="row">
              <div className="col-12">
                <label className="d-block">User</label>
                <input
                  className="w-100"
                  type="text"
                  value={username}
                  onChange={({ target: { value } }) => setUsername(value)}
                  required
                />
              </div>
              <div className="col-12">
                <label className="d-block">API Key</label>
                <input
                  className="w-100"
                  type="password"
                  value={password}
                  onChange={({ target: { value } }) => setPassword(value)}
                  required
                />
              </div>
              {error && (
                <div className="col-12 mt-2">
                  <span className="text-danger">{error}</span>
                </div>
              )}
            </form>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="d-none"
              data-bs-dismiss="modal"
              id="close-bom-modal"
            ></button>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={closeModal}
            >
              Abort
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={(event) => handleSubmit(event)}
            >
              Send
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubmitOrderModal;
