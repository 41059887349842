import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";
import LoadingSpinner from "../../components/LoadingSpinner";
import InformationBlock from "../../components/InformationBlock";
import PackageTable from "../../components/Orders/PackageTable";
import BomTable from "../../components/Orders/BomTable";
import {
  getDeviationName,
  getHandlerName,
  getInvoiceStatusName,
  getOrderStatusName,
  isImport,
  toLocaleDateString
} from "../../core/utils";
import OrderService from "../../services/OrderService";
import SimpleInfoTag from "../../components/SimpleInfoTag";
import OrderOptions from "../../components/Orders/OrderOptions";
import handlers from "../../core/handlers";
import AddBomModal from "../../components/Orders/AddBomModal";
import { invoiceStatuses } from "../../core/constants";
import { orderStatuses } from "../../core/order-constants";
import EditOrderModal from "../../components/Orders/EditOrderModal";
import OrderPdfButton from "../../components/Orders/OrderPdfButton";

function OrderDetails() {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState({});
  const [bomModalId] = useState("addBomModal");
  const [editModalId] = useState("editOrderModal");
  const infoBlocks = useMemo(
    () => ({
      BASIC: {
        header: "INFORMATION",
        data: [
          {
            label: "Fakturering",
            data: getInvoiceStatusName(order.invoiceStatus)
          },
          {
            label: "Avvikelse",
            data: getDeviationName(order.deviation)
          },
          {
            label: "Pickup date",
            data: order.pickupDate ? toLocaleDateString(order.pickupDate) : ""
          },
          {
            label: "Country of destination",
            data: order.countryOfDestination
          }
        ]
      },
      CALCULATIONS: {
        header: "CALCULATIONS",
        data: [
          { label: "Gross weight", data: order.grossWeight },
          { label: "Volume", data: order.volume },
          { label: "Volume weight", data: order.volumeWeight },
          { label: "Packages", data: order.packageCount },
          { label: "Revenue", data: order.revenue }
        ]
      },
      INVOICE: {
        header: "FAKTURERING",
        data: [
          { label: "Extra hämtning", data: order.extraPickup },
          { label: "Min. värde", data: order.minimumValue },
          { label: "Fakt. kommentar", data: order.invoiceComments }
        ]
      },
      CONSIGNEE: {
        header: "CONSIGNEE",
        data: [
          { label: "Namn", data: order.consigneeAddress?.name },
          { label: "Adress", data: order.consigneeAddress?.addr },
          {
            label: "Ort",
            data: `${order.consigneeAddress?.zip || ""} ${
              order.consigneeAddress?.city || ""
            }`
          }
        ],
        hide: !isImport(order)
      },
      PICKUP: {
        data: [
          {
            label: "Pickup location",
            data: order.pickupLocation,
            hide: order.handler === handlers.DHL_IMPORT.value
          },
          {
            label: "Pickup name",
            data: order.pickupName,
            hide: order.handler === handlers.DHL_IMPORT.value
          },
          { label: "Airway bill", data: order.airwayBill },
          { label: "House", data: order.house }
        ]
      },
      INSTRUCTIONS: {
        data: [
          { label: "Kommentarer", data: order.comments },
          {
            label: "Avsändarens instruktioner",
            data: order.forwarderInstructions
          }
        ]
      }
    }),
    [order]
  );

  useEffect(() => {
    loadData();
  }, [id]);

  const loadData = async () => {
    setLoading(true);
    try {
      const response = await OrderService.getOrder(id);
      setOrder(response || {});
    } finally {
      setLoading(false);
    }
  };

  const editOrderButton = () => {
    return (
      [orderStatuses.OPEN.value, orderStatuses.AWB.value].includes(
        order.status
      ) && (
        <div className="col-md-4 col-lg-3 col-xl-2 p-2">
          <button
            className="btn btn-primary w-100"
            data-bs-toggle="modal"
            data-bs-target={`#${editModalId}`}
          >
            Redigera order
          </button>
        </div>
      )
    );
  };

  return loading ? (
    <LoadingSpinner fill={true} />
  ) : (
    <>
      <AddBomModal htmlId={bomModalId} order={order} handleChange={loadData} />
      <EditOrderModal
        htmlId={editModalId}
        order={order}
        handleChange={loadData}
      />
      <div className="container">
        <div className="row">
          <div className="col-12 p-2">
            <div className="info-card">
              <div className="info-card-header">
                <h4 className="title">ORDER {order.consignorsReference}</h4>
                <SimpleInfoTag content={order.handler?.substring(0, 3) + " " + (order.branchId?order.branchId.substring(0, 3):"")} />
                <SimpleInfoTag content={getOrderStatusName(order.status)} />
              </div>
              <div className="info-card-body">
                <div className="row g-0">
                  {Object.entries(infoBlocks).map(
                    ([key, { header, data, hide }]) =>
                      !hide && (
                        <InformationBlock
                          key={key}
                          header={header}
                          data={data}
                          responsive={true}
                        />
                      )
                  )}
                </div>
              </div>
              <div className="info-card-footer">
                <div className="row g-0">
                  <OrderPdfButton orderId={id} />
                  {editOrderButton()}
                  <OrderOptions order={order} handleChange={loadData} />
                </div>
              </div>
            </div>
          </div>
          {order.handler !== handlers.DHL_IMPORT.value && (
            <div className="col-lg-8 p-2">
              <div className="info-card">
                <div className="info-card-header">
                  <h4 className="title">PACKAGES</h4>
                </div>
                <div className="info-card-body">
                  <PackageTable packages={order.packages || []} />
                </div>
              </div>
            </div>
          )}
          <div className="col-lg-4 p-2">
            <div className="info-card">
              <div className="info-card-header">
                <h4 className="title">Bom</h4>
              </div>
              <div className="info-card-body">
                {[
                  invoiceStatuses.OPEN.value,
                  invoiceStatuses.INVOICE.value
                ].includes(order.invoiceStatus) && (
                  <div className="center-y mb-2">
                    <button
                      className="btn btn-primary"
                      data-bs-toggle="modal"
                      data-bs-target={`#${bomModalId}`}
                    >
                      Ny bom
                    </button>
                  </div>
                )}
                <BomTable boms={order.boms || []} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OrderDetails;
