const handlers = {
 /* Alla: {
    value: 'Alla',
    name: 'Alla',
    color: 'black',
  },*/
  DHL: {
    name: "DHL",
    value: "DHL",
    color: "#469990",
  },
  DHL_IMPORT: {
    name: "DHL Import",
    value: "DHL_IMPORT",
    color: "#4363d8"
  },
  DHL_EXPORT: {
    name: "DHL Export",
    value: "DHL_EXPORT",
    color: "#000075"
  },
  DHL_ERICSSON: {
    name: "DHL Ericsson",
    value: "DHL_ERICSSON",
    color: "#000075"
  },
  DHL_HUSQVARNA: {
    name: "DHL Husqvarna",
    value: "DHL_HUSQVARNA",
    color: "#000075"
  },
  DHL_XYLEM: {
    name: "DHL Xylem",
    value: "DHL_XYLEM",
    color: "#000075"
  },
  DHL_SKF: {
    name: "DHL SKF",
    value: "DHL_SKF",
    color: "#000075"
  },
  DHL_VOLVO: {
    name: "DHL Volvo",
    value: "DHL_VOLVO",
    color: "#000075"
  },
  SCAN_LOGISTICS: {
    name: "Scan Logistics",
    value: "SCAN_LOGISTICS",
    color: "#e6394B"
  },
  SGL_IMPORT: {
    name: "Scan Logistics Import",
    value: "SGL_IMPORT",
    color: "#e6194B"
  },
  SCHENKER: {
    name: "Schenker",
    value: "SCHENKER",
    color: "#3cb44b",
    inactive: true
  },
  GEODIS_IMPORT: {
    name: "Geodis Import",
    value: "GEODIS_IMPORT",
    color: "#911eb4"
  },
  GEODIS_EXPORT: {
    name: "Geodis Export",
    value: "GEODIS_EXPORT",
    color: "#cf3ac7"
  }
};

export default handlers;
