import { BASE_URL } from "../core/constants";
import { del, get, post, put } from "../core/fetch";
import { getUrlParams } from "../core/utils";
const BASE_TRANSPORTERS_URL = BASE_URL + "/transporters";

const TransporterService = {
  getTransporter: async (id) => {
    const response = await get(`${BASE_TRANSPORTERS_URL}/${id}`);
    if (response.ok) {
      return await response.json();
    }
  },
  getTransporters: async (params) => {
    const paramString = getUrlParams(params).toString();
    const response = await get(`${BASE_TRANSPORTERS_URL}?${paramString}`);
    if (response.ok) {
      return await response.json();
    }
  },
  createTransporter: async (transporter) => {
    const response = await post(BASE_TRANSPORTERS_URL, {
      body: JSON.stringify(transporter)
    });
    if (response.ok) {
      return response;
    }
  },
  updateTransporter: async (transporter) => {
    const response = await put(`${BASE_TRANSPORTERS_URL}/${transporter.id}`, {
      body: JSON.stringify(transporter)
    });
    if (response.ok) {
      return response;
    }
  },
  getTransporterList: async () => {
    const response = await get(`${BASE_TRANSPORTERS_URL}/list`);
    if (response.ok) {
      return await response.json();
    }
  },
  deleteTransporter: async (id) => {
    const response = await del(`${BASE_TRANSPORTERS_URL}/${id}`);
    if (response.ok) {
      return response;
    }
  }
};

export default TransporterService;
