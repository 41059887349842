import { pageLimits } from "../../core/constants";

function TransporterFilter({ query, updateQuery }) {
  const setQuery = (updates) => {
    updateQuery({ ...updates, page: "" });
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-4 col-lg-2">
          <label className="form-label w-100">Resultat per sida</label>
          <select
            value={query.size || 60}
            className="form-select"
            onChange={(e) => setQuery({ size: e.target.value })}
          >
            {pageLimits.map((value) => (
              <option key={value} value={value}>
                {value}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
}

export default TransporterFilter;
