import { BASE_URL } from "../core/constants";
import { get } from "../core/fetch";

const STATISTICS_BASE_URL = `${BASE_URL}/statistics`;

const StatisticsService = {
  getStatistics: async () => {
    const response = await get(STATISTICS_BASE_URL);
    if (response.ok) {
      return await response.json();
    }
  }
};

export default StatisticsService;
