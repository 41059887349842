import { useEffect, useState } from "react";
import { invoiceStatuses } from "../../core/constants";
import { orderStatuses } from "../../core/order-constants";
import { exportOrderColumns, waybillColumns } from "../../core/table-columns";
import {
  getExportHandlersValues,
  getImportHandlersValues
} from "../../core/utils";
import { waybillStatuses } from "../../core/waybill-constants";
import OrderService from "../../services/OrderService";
import TransportOrderService from "../../services/TransportOrderService";
import WaybillService from "../../services/WaybillService";
import LoadingSpinner from "../LoadingSpinner";
import TransportOrderContentTable from "./TransportOrderContentTable";

function AddContentModal({
  htmlId,
  isExport,
  transportOrderId,
  isOpen,
  setModalStatus,
  handleChange
}) {
  const [columns, setColumns] = useState([]);
  const [content, setContent] = useState([]);
  const [selectedRows, setSelectedRows] = useState(new Set());
  const [loading, setLoading] = useState({ content: true });

  const loadContent = async () => {
    setLoading((prev) => ({ ...prev, content: true }));
    clearSelection();
    try {
      const contentMethod = isExport ? loadOrders : loadWaybills;
      const response = await contentMethod();
      if (response) {
        setContent(response);
      }
    } finally {
      setLoading((prev) => ({ ...prev, content: false }));
    }
  };

  const clearSelection = () => {
    setSelectedRows(new Set());
  };

  const loadWaybills = () =>
    WaybillService.getWaybillList({
      reported: false,
      inTransportOrder: false,
      status: waybillStatuses.AWB.value,
      invoiceStatus: invoiceStatuses.OPEN.value,
      handlers: getImportHandlersValues()
    });

  const loadOrders = () =>
    OrderService.getOrders("", {
      list: true,
      reported: false,
      inTransportOrder: false,
      status: [orderStatuses.OPEN.value, orderStatuses.AWB.value],
      invoiceStatuses: invoiceStatuses.OPEN.value,
      handlers: getExportHandlersValues()
    });

  useEffect(() => {
    if (isOpen) {
      loadContent();
    }
  }, [isOpen]);

  useEffect(() => {
    setColumns(isExport ? exportOrderColumns : waybillColumns);
  }, [isExport]);

  const addContent = async () => {
    setLoading((prev) => ({ ...prev, updateTransportOrder: true }));
    try {
      const response = await TransportOrderService.addContent(
        transportOrderId,
        [...selectedRows]
      );
      if (response) {
        const element = document.querySelector(`#close-${htmlId}`);
        element.click();
        handleChange();
      }
    } finally {
      setLoading((prev) => ({ ...prev, updateTransportOrder: false }));
    }
  };

  const refreshTable = () => {
    setColumns((prev) => [...prev]);
    setContent((prev) => [...prev]);
  };

  const selectRow = (id) => {
    if (selectedRows.has(id)) {
      selectedRows.delete(id);
      setSelectedRows((prev) => new Set([...prev]));
    } else {
      setSelectedRows((prev) => new Set([...prev, id]));
    }
    refreshTable();
  };

  const selectAll = () => {
    const allSelected = content.every((waybill) =>
      selectedRows.has(waybill.id)
    );

    if (allSelected) {
      content.forEach((waybill) => selectedRows.delete(waybill.id));
    } else {
      content.forEach((waybill) => selectedRows.add(waybill.id));
    }
    setSelectedRows((prev) => new Set([...prev]));
    refreshTable();
  };

  return (
    <div className="modal fade" id={htmlId} tabIndex="-1" aria-hidden="true">
      <div className="modal-dialog modal-dialog-scrollable modal-xl modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">
              {"Lägg till " + (isExport ? "ordrar" : "fraktsedlar")}
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => setModalStatus(false)}
            ></button>
          </div>
          <div className="modal-body">
            {loading.content ? (
              <LoadingSpinner fill={true} />
            ) : (
              <TransportOrderContentTable
                columns={columns}
                content={content}
                isExport={isExport}
                selectRow={selectRow}
                selectedRows={selectedRows}
                selectAll={selectAll}
                hiddenColumns={[
                  "remark",
                  "totalPackageCount",
                  "packageCount"
                ]}
              />
            )}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
              id={"close-" + htmlId}
              onClick={() => setModalStatus(false)}
            >
              Avbryt
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={addContent}
              disabled={!selectedRows.size}
            >
              {loading.updateTransportOrder ? (
                <LoadingSpinner small theme="secondary" fill />
              ) : (
                "Lägg till " + (isExport ? "ordrar" : "fraktsedlar")
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddContentModal;
