import { useEffect, useState } from "react";
import { getHandlerName } from "../../core/utils";
import StatisticsService from "../../services/StatisticsService";
import ResponsivePieChart from "../../components/ResponsivePieChart";
import LoadingSpinner from "../../components/LoadingSpinner";
import handlers from "../../core/handlers";

function Dashboard() {
  const [loading, setLoading] = useState(true);
  const [{ ORDER_STATUS }, setStatistics] = useState({});

  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      const { data } = await StatisticsService.getStatistics();
      setStatistics(data);
      setLoading(false);
    };
    loadData();
  }, []);

  const objectToArray = (object) =>
    Object.entries(object)
      .filter(([key, value]) => !handlers[key].inactive || value)
      .map(([key, value]) => ({
        key,
        name: getHandlerName(key),
        value
      }));

  return (
    <div className="container">
      <div className="row mb-4">
        <div className="col-12 p-0">
          <h1 className="m-0">Dashboard</h1>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 p-2">
          <div className="info-card">
            <div className="info-card-header">
              <h4>ORDERSTATUS: ÖPPEN</h4>
            </div>
            <div className="info-card-body">
              {loading ? (
                <LoadingSpinner fill={true} />
              ) : (
                <ResponsivePieChart data={objectToArray(ORDER_STATUS?.OPEN)} />
              )}
            </div>
          </div>
        </div>

        <div className="col-md-6 p-2">
          <div className="info-card">
            <div className="info-card-header">
              <h4>ORDERSTATUS: AWB</h4>
            </div>
            <div className="info-card-body">
              {loading ? (
                <LoadingSpinner fill={true} />
              ) : (
                <ResponsivePieChart data={objectToArray(ORDER_STATUS?.AWB)} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
