export const BASE_URL = "/api/v1";

export const windowBreakpoints = {
  small: 575,
  medium: 767,
  large: 991,
  xlarge: 1199,
  xxlarge: 1399
};

export const invoiceStatuses = {
  OPEN: {
    name: "Öppen",
    value: "OPEN"
  },
  INVOICE: {
    name: "För fakturering",
    value: "INVOICE"
  },
  INVOICED: {
    name: "Fakturerad",
    value: "INVOICED"
  },
  CANCELLED: {
    name: "Makulerad",
    value: "CANCELLED"
  }
};

export const invoiceBasisStatuses = {
  OPEN: {
    name: "Öppen",
    value: "OPEN"
  },
  CLOSED: {
    name: "Stängd",
    value: "CLOSED"
  }
};

export const orderTypes = {
  IMPORT: "import",
  EXPORT: "export"
};

export const pageLimits = [20, 40, 60, 80, 100];
