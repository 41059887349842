import { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useSortBy, useTable } from "react-table";

function InvoiceBasesTable({
  columns: headers,
  invoiceBases,
  updateQuery,
  query
}) {
  const navigate = useNavigate();
  const columns = useMemo(() => headers, [headers]);
  const data = useMemo(() => invoiceBases, [invoiceBases]);

  const {
    state: { sortBy },
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable(
    {
      columns,
      data,
      manualSortBy: true
    },
    useSortBy
  );

  const goToInvoiceBasis = (id) => {
    navigate(`/invoicebases/${id}`);
  };

  useEffect(() => {
    if (!!sortBy[0]) {
      const { id: sort } = sortBy[0];

      let direction;
      if (query.sort === sort) {
        if (query.direction) {
          direction = query.direction === "DESC" ? "ASC" : "DESC";
        } else {
          direction = "DESC";
        }
      } else {
        direction = "DESC";
      }
      updateQuery({ sort, direction });
    }
  }, [sortBy]);

  return (
    <div className="table-wrap">
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className="p-1 fs-7"
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                onClick={() => goToInvoiceBasis(row.original.id)}
                className="clickable"
              >
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()} className="p-1 pt-3 pb-3">
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default InvoiceBasesTable;
