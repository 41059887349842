import { useEffect, useRef } from "react";

export const withMountedRef = (Component) => {
  const ComponentWithMountedRef = (props) => {
    const isMounted = useRef(false);

    useEffect(() => {
      isMounted.current = true;

      return () => {
        isMounted.current = false;
      };
    }, []);

    return <Component isMounted={isMounted} {...props} />;
  };

  return ComponentWithMountedRef;
};
