import i18next from "../core/translation";

function InformationBlock({
  header,
  responsive,
  responsiveClasses = "col-md-6 col-xl-4",
  data
}) {
  return (
    <div className={`${responsive && responsiveClasses} p-2`}>
      <div className="w-100 order-information rounded border border-secondary p-2">
        {header && <span className="fs-5 fw-light">{i18next.t(header)}</span>}
        {data.map(
          ({ label, data: value, hide }) =>
            !hide && (
              <div className="spread" key={label}>
                <label>{i18next.t(label)}:</label>
                <span className="text-end pre">{value}</span>
              </div>
            )
        )}
      </div>
    </div>
  );
}

export default InformationBlock;
