import { useState } from "react";
import { useNavigate } from "react-router-dom";
import InvoiceBasesService from "../../services/InvoiceBasisService";
import LoadingSpinner from "../LoadingSpinner";

function CreateInvoiceBasisButton({ show, selectedHandler, disabled }) {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const createInvoiceBasis = async () => {
    setLoading(true);
    try {
      const response = await InvoiceBasesService.createOrderInvoiceBasis({
        handler: selectedHandler
      });
      if (response) {
        navigate(`/invoicebases/${response.id}`);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    show && (
      <div className="col-md-4 col-lg-3">
        <button
          className="btn btn-primary w-100"
          disabled={loading || !selectedHandler || disabled}
          onClick={createInvoiceBasis}
        >
          {loading ? <LoadingSpinner fill={true} /> : "Skapa fakturaunderlag"}
        </button>
      </div>
    )
  );
}

export default CreateInvoiceBasisButton;
