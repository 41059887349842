import { useState } from "react";
import { invoiceBasisStatuses, pageLimits } from "../../core/constants";
import handlers from "../../core/handlers";

function InvoiceBasesFilter({ query, updateQuery }) {
  const [handlerOptions] = useState(
    Object.values(handlers).filter(({ inactive }) => !inactive)
  );

  const setQuery = (updates) => {
    updateQuery({ ...updates, page: "" });
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-4 col-lg-2">
          <label className="form-label w-100">Speditör</label>
          <select
            value={query.handler}
            className="form-select"
            onChange={(e) => setQuery({ handler: e.target.value })}
          >
            <option value="">Alla</option>
            {handlerOptions.map((handler) => (
              <option key={handler.value} value={handler.value}>
                {handler.name}
              </option>
            ))}
          </select>
        </div>
        <div className="col-md-4 col-lg-2">
          <label className="form-label w-100">Status</label>
          <select
            value={query.status}
            className="form-select"
            onChange={(e) => setQuery({ status: e.target.value })}
          >
            {Object.values(invoiceBasisStatuses).map(({ name, value }) => (
              <option key={value} value={value}>
                {name}
              </option>
            ))}
          </select>
        </div>
        <div className="col-md-4 col-lg-2">
          <label className="form-label w-100">Resultat per sida</label>
          <select
            value={query.size || 20}
            className="form-select"
            onChange={(e) => setQuery({ size: e.target.value })}
          >
            {pageLimits.map((value) => (
              <option key={value} value={value}>
                {value}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
}

export default InvoiceBasesFilter;
