export const waybillStatuses = {
  AWB: {
    name: "Stängd för AWB",
    value: "AWB"
  },
  CLOSED: {
    name: "Stängd",
    value: "CLOSED"
  },
  CANCELLED: {
    name: "Makulerad",
    value: "CANCELLED"
  }
};
