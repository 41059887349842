function LoadingSpinner({ fill, small, theme = "primary" }) {
  const spinner = () => (
    <div
      className={`spinner-border text-${theme} ${
        small ? "spinner-border-sm" : ""
      }`}
    >
      <span className="visually-hidden">Loading...</span>
    </div>
  );

  return fill ? <div className="wh-100 center">{spinner()}</div> : spinner();
}

export default LoadingSpinner;
