import { useEffect, useState } from "react";
import Calendar from "react-calendar";

function ReportDatePicker({ chosenDate, highlightedDates, handleChange }) {
  const [milliseconds, setMilliseconds] = useState([]);
  const [monthsAndYears, setMonthsAndYears] = useState([]);

  useEffect(() => {
    setMilliseconds(highlightedDates);
    const transformedDates = highlightedDates.map((d) => {
      const date = new Date(d);
      return date.getMonth() + "-" + date.getFullYear();
    });
    setMonthsAndYears(transformedDates);
  }, [highlightedDates]);

  const getClassName = ({ date, view }) => {
    const classes = "text-light bg-primary bg-opacity-50";
    if (view === "month") {
      if (milliseconds.includes(date.getTime())) {
        return classes;
      }
    } else if (view === "year") {
      const dateMonthAndYear = date.getMonth() + "-" + date.getFullYear();
      if (monthsAndYears.includes(dateMonthAndYear)) {
        return classes;
      }
    }
  };

  return (
    <div className="row">
      <div className="col-12 center-x mb-3">
        <Calendar
          value={chosenDate}
          onChange={handleChange}
          tileClassName={getClassName}
        />
      </div>
    </div>
  );
}

export default ReportDatePicker;
