import { useEffect, useState } from "react";
import { invoiceStatuses } from "../../core/constants";
import { exportOrderColumns, waybillColumns } from "../../core/table-columns";
import {
  getExportHandlersValues,
  getImportHandlersValues
} from "../../core/utils";
import { waybillStatuses } from "../../core/waybill-constants";
import TransporterService from "../../services/TransporterService";
import WaybillService from "../../services/WaybillService";
import OrderService from "../../services/OrderService";
import RequiredMarker from "../Inputs/RequiredMarker";
import LoadingSpinner from "../LoadingSpinner";
import { orderStatuses } from "../../core/order-constants";
import TransportOrderContentTable from "./TransportOrderContentTable";

function TransportOrderFormContent({ transportOrder, onUpdate }) {
  const [loading, setLoading] = useState({
    contentList: false,
    transporters: false
  });
  const [isImport, setIsImport] = useState(true);
  const [columns, setColumns] = useState([]);
  const [contentList, setContentList] = useState([]);
  const [selectedRows, setSelectedRows] = useState(new Set());
  const [transporterOptions, setTransporterOptions] = useState([]);

  useEffect(() => {
    loadContentList();
    loadTransporters();
  }, []);

  useEffect(() => {
    onUpdate(isImport ? "waybillIds" : "orderIds", [...selectedRows]);
    refreshTable();
  }, [selectedRows]);

  useEffect(() => {
    setColumns(isImport ? waybillColumns : exportOrderColumns);
    loadContentList();
    onUpdate(isImport ? "orderIds" : "waybillIds", []);
    setSelectedRows(new Set());
  }, [isImport]);

  const loadContentList = async () => {
    setLoading((prev) => ({ ...prev, contentList: true }));
    try {
      const promise = isImport ? loadWaybills() : loadOrders();
      const response = await promise;
      if (response) {
        setContentList(response);
      }
    } finally {
      setLoading((prev) => ({ ...prev, contentList: false }));
    }
  };

  const loadWaybills = () => {
    return WaybillService.getWaybillList({
      reported: false,
      inTransportOrder: false,
      status: waybillStatuses.AWB.value,
      invoiceStatus: invoiceStatuses.OPEN.value,
      handlers: getImportHandlersValues()
    });
  };

  const loadOrders = () => {
    return OrderService.getOrders("", {
      list: true,
      reported: false,
      inTransportOrder: false,
      status: [orderStatuses.OPEN.value, orderStatuses.AWB.value],
      invoiceStatuses: invoiceStatuses.OPEN.value,
      handlers: getExportHandlersValues()
    });
  };

  const loadTransporters = async () => {
    setLoading((prev) => ({ ...prev, transporters: true }));
    try {
      const response = await TransporterService.getTransporterList();
      if (response) {
        setTransporterOptions(response);
      }
    } finally {
      setLoading((prev) => ({ ...prev, transporters: false }));
    }
  };

  const selectAll = () => {
    const allSelected = contentList.every((item) => selectedRows.has(item.id));

    let update = new Set();

    if (!allSelected) {
      update = new Set(contentList.map((item) => item.id));
    }

    setSelectedRows(update);
  };

  const selectRow = (id) => {
    if (selectedRows.has(id)) {
      selectedRows.delete(id);
      setSelectedRows((prev) => new Set([...prev]));
    } else {
      setSelectedRows((prev) => new Set([...prev, id]));
    }
  };

  const refreshTable = () => {
    setColumns((prev) => [...prev]);
    setContentList((prev) => [...prev]);
  };

  return (
    <div className="row g-0">
      <div className="col-lg-4 p-2">
        <div className="w-100 rounded border border-secondary p-2">
          <h2 className="fs-5 fw-light">Grundläggande</h2>
          <div className="mb-3">
            <label className="form-label">
              Typ <RequiredMarker />
            </label>
            <select
              value={isImport}
              className="form-select"
              onChange={({ target: { value } }) => {
                setIsImport(value === "true");
              }}
            >
              <option value={true}>Import</option>
              <option value={false}>Export</option>
            </select>
          </div>
          <div className="mb-3">
            <label className="form-label">
              Transportör <RequiredMarker />
            </label>
            {loading.transporters ? (
              <LoadingSpinner fill small />
            ) : (
              <select
                value={transportOrder.transporterId || ""}
                className="form-select"
                required
                onChange={({ target: { value } }) =>
                  onUpdate("transporterId", value)
                }
              >
                <option value="">Välj transportör</option>
                {transporterOptions.map(({ id, name }) => (
                  <option key={id} value={id}>
                    {name}
                  </option>
                ))}
              </select>
            )}
          </div>
          <div>
            <label className="form-label">
              Namn <RequiredMarker />:
            </label>
            <input
              type="text"
              className="w-100"
              value={transportOrder.name || ""}
              required
              maxLength={64}
              onChange={({ target: { value } }) => onUpdate("name", value)}
            />
          </div>
        </div>
      </div>
      <div className="col-lg-8 p-2">
        <div className="w-100 rounded border border-secondary p-2">
          <h2 className="fs-5 fw-light">
            {isImport ? "Fraktsedlar" : "Ordrar"}
          </h2>
          {loading.contentList ? (
            <LoadingSpinner fill />
          ) : (
            <TransportOrderContentTable
              columns={columns}
              content={contentList}
              clickable={false}
              selectRow={selectRow}
              selectedRows={selectedRows}
              selectAll={selectAll}
              hiddenColumns={[
                "remark",
                "totalPackageCount",
                "packageCount"
              ]}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default TransportOrderFormContent;
