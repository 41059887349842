import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";
import InformationBlock from "../../components/InformationBlock";
import LoadingSpinner from "../../components/LoadingSpinner";
import WaybillReportService from "../../services/WaybillReportService";
import SimpleInfoTag from "../../components/SimpleInfoTag";
import { getHandlerName } from "../../core/utils";
import WaybillService from "../../services/WaybillService";
import RequiredMarker from "../../components/Inputs/RequiredMarker";
import i18next from "../../core/translation";
import LanguageSelector from "../../components/LanguageSelector";

function WayBillDetails() {
  const { jwt } = useParams();
  const [pageName] = useState("mobile_report");
  const [loading, setLoading] = useState(true);
  const [waybill, setWaybill] = useState({});
  const [acknowledgedBy, setAcknowledgedBy] = useState("");
  const [remark, setRemark] = useState("");
  const [photo, setPhoto] = useState();
  const [error, setError] = useState();
  const [successfullyReported, setSuccessfullyReported] = useState(false);
  const [deliveryStatus, setDeliveryStatus] = useState("");

  const list = (items) => (
    <ul>
      {items?.map((item) => (
        <li key={item}>{item}</li>
      ))}
    </ul>
  );

  const infoBlocks = useMemo(
    () => ({
      BASIC: {
        header: "INFORMATION",
        data: [
          {
            label: "Pickup date",
            data: waybill.pickupDate || ""
          }
        ]
      },
      CALCULATIONS: {
        header: "CALCULATIONS",
        data: [
          {
            label: "Gross weight",
            data: waybill.totalGrossWeight
          },
          {
            label: "Volume",
            data: waybill.totalVolume
          },
          {
            label: "Packages",
            data: waybill.totalPackageCount
          }
        ]
      },
      CONSIGNEE: {
        header: "CONSIGNEE",
        data: [
          {
            label: "info_blocks.name",
            data: waybill.consigneeAddress?.name || ""
          },
          {
            label: "info_blocks.address",
            data: waybill.consigneeAddress?.addr || ""
          },
          {
            label: "info_blocks.city",
            data: `${waybill.consigneeAddress?.zip || ""} ${
              waybill.consigneeAddress?.city || ""
            }`
          }
        ]
      },
      WAYBILLS: {
        data: [
          {
            label: "Airway bill(s)",
            data: list(waybill.airwayBills)
          },
          {
            label: "House(s)",
            data: list(waybill.houses)
          }
        ]
      }
    }),
    [waybill]
  );

  useEffect(() => {
    loadData();
  }, [jwt]);

  const loadData = async () => {
    setLoading(true);
    try {
      const waybillResponse = await WaybillService.getWaybillByToken(jwt);
      setWaybill(waybillResponse);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const getFormData = (content) => {
    const formData = new FormData();
    const formDataContent = Object.entries(content).filter(([key]) => {
      if (key === "remark") {
        return deliveryStatus === "WITH-REMARK";
      }

      return true;
    });

    formDataContent.forEach(([key, value]) => formData.append(key, value));

    return formData;
  };

  const reportWaybill = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (!acknowledgedBy.trim()) {
      updateError("no_acknowledged");
    } else if (!photo) {
      updateError("no_photo");
    } else if (!deliveryStatus) {
      updateError("no_status");
    } else if (deliveryStatus === "WITH-REMARK" && !remark.trim()) {
      updateError("no_remark");
    } else {
      updateError();
      try {
        const formData = getFormData({ photo, acknowledgedBy, remark });
        const response = await WaybillReportService.reportWithImage(
          jwt,
          formData
        );
        if (response) {
          setSuccessfullyReported(true);
        }
      } catch (e) {
        updateError("failed");
      }
    }

    setLoading(false);
  };

  const updateError = (errorType) => {
    if (errorType) {
      return setError(`${pageName}.error_${errorType}`);
    }

    setError();
  };

  const setValidityMessage = (event, errorType = "") => {
    let validityMessage = errorType;

    if (errorType) {
      validityMessage = i18next.t(`${pageName}.error_${errorType}`);
    }

    event.target.setCustomValidity(validityMessage);
  };

  const content = () =>
    successfullyReported ? (
      <div className="center">
        <h4>{i18next.t(`${pageName}.report_success`)}</h4>
      </div>
    ) : (
      <div className="container">
        <LanguageSelector />
        <div className="row">
          <div className="col-12 p-2">
            <div className="info-card">
              <div className="info-card-header">
                <h4 className="title">
                  {i18next.t(`${pageName}.waybill_card.title`)}:{" "}
                  {waybill.handlerSequence || "n/a"}
                </h4>
                <SimpleInfoTag content={getHandlerName(waybill.handler)} />
              </div>
              <div className="info-card-body">
                <div className="row g-0">
                  {Object.entries(infoBlocks).map(([key, { header, data }]) => (
                    <InformationBlock
                      key={key}
                      header={header}
                      data={data}
                      responsive={true}
                    />
                  ))}
                </div>
              </div>
              <div className="info-card-footer"></div>
            </div>
          </div>

          <div className="col-md-6 offset-md-3 p-2">
            <form onSubmit={(e) => reportWaybill(e)} className="info-card">
              <div className="info-card-header">
                <h4 className="title">
                  {i18next.t(`${pageName}.report_card.title`)}
                </h4>
              </div>

              <div className="info-card-body">
                <label className="d-block w-100 fs-5">
                  {i18next.t(`${pageName}.report_card.acknowledged_by`)}
                  <RequiredMarker marginLeft={1} />
                </label>
                <input
                  maxLength={64}
                  className="w-100 mb-3 fs-4"
                  value={acknowledgedBy}
                  onInput={(e) => {
                    setValidityMessage(e);
                    setAcknowledgedBy(e.target.value);
                  }}
                  onInvalid={(e) => setValidityMessage(e, "no_acknowledged")}
                  required
                />

                <label className="d-block w-100 fs-5">
                  {i18next.t(`${pageName}.report_card.signature_photo`)}
                  <RequiredMarker marginLeft={1} />
                </label>
                <input
                  id="photo-input"
                  className="w-100 mb-3 fs-4"
                  type="file"
                  accept="image/*"
                  capture="environment"
                  onChange={(event) => setPhoto(event.target.files[0])}
                />

                <label className="d-block w-100 fs-5">
                  {i18next.t(`${pageName}.report_card.delivered_status_label`)}
                  <RequiredMarker marginLeft={1} />
                </label>
                <div className="form-check fs-4">
                  <input
                    className="form-check-input"
                    type="radio"
                    value="COMPLETE"
                    required
                    checked={deliveryStatus === "COMPLETE"}
                    onChange={(e) => setDeliveryStatus(e.target.value)}
                  />
                  <label className="form-check-label">{i18next.t("yes")}</label>
                </div>
                <div
                  className={`form-check ${
                    deliveryStatus === "WITH-REMARK" ? "mb-3 " : ""
                  }fs-4`}
                >
                  <input
                    className="form-check-input"
                    type="radio"
                    value="WITH-REMARK"
                    required
                    checked={deliveryStatus === "WITH-REMARK"}
                    onChange={(e) => setDeliveryStatus(e.target.value)}
                  />
                  <label className="form-check-label">
                    {i18next.t("yes")}
                    {" - "}
                    {i18next.t(`${pageName}.report_card.with_remark`)}
                  </label>
                </div>

                {deliveryStatus === "WITH-REMARK" && (
                  <>
                    <label className="d-block w-100 fs-5">
                      {i18next.t(`${pageName}.report_card.remark_label`)}
                      <RequiredMarker marginLeft={1} />
                    </label>
                    <textarea
                      maxLength={256}
                      className="w-100 mb-3 fs-4"
                      value={remark}
                      onInput={(e) => {
                        setValidityMessage(e);
                        setRemark(e.target.value);
                      }}
                      onInvalid={(e) => setValidityMessage(e, "no_remark")}
                      required
                    />
                  </>
                )}
              </div>

              {error && (
                <div className="w-100 center px-2">
                  <p className="text-danger">{i18next.t(error)}</p>
                </div>
              )}

              <div className="info-card-footer d-flex justify-content-center">
                <button type="submit" className="btn btn-success fs-5">
                  {i18next.t(`${pageName}.report_card.report_button`)}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );

  return loading ? (
    <LoadingSpinner fill={true} />
  ) : waybill.id ? (
    content()
  ) : (
    <div className="center">
      <h4>{i18next.t(`${pageName}.is_reported`)}</h4>
    </div>
  );
}

export default WayBillDetails;
