import { useState } from "react";
import { useNavigate } from "react-router-dom";
import WaybillService from "../../services/WaybillService";
import LoadingSpinner from "../LoadingSpinner";

function CreateWaybillButton({ show, selectedIds }) {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const createWaybill = async () => {
    setLoading(true);
    try {
      const response = await WaybillService.createWaybill(
        JSON.stringify([...selectedIds])
      );
      if (response) {
        navigate(`/waybills/${response.id}`);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    show && (
      <div className="col-md-3 col-lg-2">
        <button
          className="btn btn-primary w-100"
          disabled={!selectedIds.size || loading}
          onClick={createWaybill}
        >
          {loading ? <LoadingSpinner fill={true} /> : "Skapa fraktsedel"}
        </button>
      </div>
    )
  );
}

export default CreateWaybillButton;
