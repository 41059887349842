import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";
import DownloadButton from "../../components/DownloadButton";
import InformationBlock from "../../components/InformationBlock";
import LoadingSpinner from "../../components/LoadingSpinner";
import OrderGrid from "../../components/Orders/OrderGrid";
import OrderTable from "../../components/Orders/OrderTable";
import SimpleInfoTag from "../../components/SimpleInfoTag";
import WaybillPdfButton from "../../components/Waybills/WaybillPdfButton";
import { orderTypes, windowBreakpoints } from "../../core/constants";
import { useWindowSize } from "../../core/hooks";
import {
  getHandlerName,
  getInvoiceStatusName,
  getWaybillStatusName,
  isImport,
  toDateTimeString,
  toLocaleDateString
} from "../../core/utils";
import WaybillService from "../../services/WaybillService";

function WayBillDetails() {
  const { id } = useParams();
  const [width] = useWindowSize();
  const [loading, setLoading] = useState(true);
  const [waybill, setWaybill] = useState({});
  const [orderType, setOrderType] = useState({});

  const list = (items) => (
    <ul>
      {items?.map((item) => (
        <li key={item}>{item}</li>
      ))}
    </ul>
  );

  const infoBlocks = useMemo(
    () => ({
      BASIC: {
        header: "INFORMATION",
        data: [
          {
            label: "Fakturering",
            data: getInvoiceStatusName(waybill.invoiceStatus)
          },
          {
            label: "Pickup date",
            data: waybill.pickupDate
              ? toLocaleDateString(waybill.pickupDate)
              : ""
          }
        ]
      },
      CALCULATIONS: {
        header: "CALCULATIONS",
        data: [
          {
            label: "Gross weight",
            data: waybill.grossWeight
          },
          {
            label: "Volume",
            data: waybill.totalVolume
          },
          {
            label: "Packages",
            data: waybill.totalPackageCount
          }
        ]
      },
      CONSIGNEE: {
        header: "CONSIGNEE",
        data: [
          {
            label: "Namn",
            data: waybill.orders
              ? waybill.orders[0]?.consigneeAddress?.name
              : ""
          },
          {
            label: "Adress",
            data: waybill.orders ? waybill.orders[0]?.consigneeAddress.addr : ""
          },
          {
            label: "Ort",
            data: `${
              waybill.orders ? waybill.orders[0]?.consigneeAddress.zip : ""
            } ${waybill.orders ? waybill.orders[0]?.consigneeAddress.city : ""}`
          }
        ]
      },
      WAYBILLS: {
        data: [
          {
            label: "Airway bill(s)",
            data: list(waybill.airwayBills)
          },
          {
            label: "House(s)",
            data: list(waybill.houses)
          }
        ]
      },
      REMARK: {
        header: "RAPPORT-INFORMATION",
        hide: !waybill.hasSignature,
        data: [
          { label: "Kvitterad", data: waybill.acknowledgedBy },
          {
            label: "Kvitterings-datum",
            data: waybill.reportDepartureDate
              ? toDateTimeString(waybill.reportDepartureDate + "Z")
              : ""
          },
          { label: "Anmärkning", data: waybill.remark }
        ]
      }
    }),
    [waybill]
  );

  useEffect(() => {
    loadData();
  }, [id]);

  const loadData = async () => {
    setLoading(true);
    try {
      const response = await WaybillService.getWaybill(id);
      setWaybill(response);
      setOrderType(
        isImport(response)
          ? { value: orderTypes.IMPORT }
          : { value: orderTypes.EXPORT }
      );
    } finally {
      setLoading(false);
    }
  };

  return loading ? (
    <LoadingSpinner fill={true} />
  ) : (
    <div className="container">
      <div className="row">
        <div className="col-12 p-2">
          <div className="info-card">
            <div className="info-card-header">
              <h4 className="title">
                FRAKTSEDEL: {waybill.handlerSequence || "n/a"}
              </h4>
              <SimpleInfoTag content={getHandlerName(waybill.handler)} />
              <SimpleInfoTag content={getWaybillStatusName(waybill.status)} />
            </div>
            <div className="info-card-body">
              <div className="row g-0">
                {Object.entries(infoBlocks).map(
                  ([key, { header, data, hide }]) =>
                    !hide && (
                      <InformationBlock
                        key={key}
                        header={header}
                        data={data}
                        responsive={true}
                      />
                    )
                )}
              </div>
            </div>
            <div className="info-card-footer">
              <div className="row g-0">
                <WaybillPdfButton
                  waybillId={id}
                  handlerSequence={waybill.handlerSequence}
                />
                {waybill.hasSignature && (
                  <div className="col-md-4 col-lg-3 col-xl-2 p-2">
                    <DownloadButton
                      buttonText="Signatur"
                      filename={`waybill_${waybill.id}_signature`}
                      getFunction={WaybillService.getSignature}
                      params={waybill.id}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 p-2">
          <div className="info-card">
            <div className="info-card-header">
              <h4 className="title">Orders</h4>
            </div>
            <div className="info-card-body">
              {width > windowBreakpoints.medium ? (
                <OrderTable
                  orderType={orderType}
                  orders={waybill.orders}
                  manualSort={false}
                />
              ) : (
                <OrderGrid
                  isImport={isImport(waybill)}
                  orders={waybill.orders}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WayBillDetails;
