import { BASE_URL } from "../core/constants";
import { get, put } from "../core/fetch";
import { getUrlParams } from "../core/utils";
const BASE_REPORTS_URL = BASE_URL + "/reports";

const OrderReportService = {
  getDates: async () => {
    const response = await get(`${BASE_REPORTS_URL}/orders/dates`);
    if (response.ok) {
      return await response.json();
    }
  },
  getOrders: async (params) => {
    const paramString = getUrlParams(params).toString();
    const response = await get(`${BASE_REPORTS_URL}/orders?${paramString}`);
    if (response.ok) {
      return await response.json();
    }
  },
  report: async (data) => {
    const options = {
      body: data
    };
    const response = await put(`${BASE_REPORTS_URL}/orders`, options);
    if (response.ok) {
      return response;
    }
  }
};

export default OrderReportService;
