import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import InvoiceBasisExcelButton from "../../components/InvoiceBases/InvoiceBasisExcelButton";
import InvoiceBasisPdfButton from "../../components/InvoiceBases/InvoiceBasisPdfButton";
import InvoiceBasisRowsTable from "../../components/InvoiceBases/InvoiceBasisRowsTable";
import LoadingSpinner from "../../components/LoadingSpinner";
import SimpleInfoTag from "../../components/SimpleInfoTag";
import { invoiceBasisStatuses } from "../../core/constants";
import { invoiceBasisRowColumns } from "../../core/table-columns";
import { getHandlerName, getInvoiceBasisStatusName } from "../../core/utils";
import InvoiceBasisService from "../../services/InvoiceBasisService";

function InvoiceBasisDetails() {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [closing, setClosing] = useState(false);
  const [saving, setSaving] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [invoiceBasis, setInvoiceBasis] = useState({});
  const [columns, setColumns] = useState(invoiceBasisRowColumns);

  useEffect(() => {
    loadData();
  }, [id]);

  useEffect(() => {
    setColumns((prev) => [...prev]);
    setInvoiceBasis((prev) => ({ ...prev }));
  }, [editMode]);

  const loadData = async () => {
    setLoading(true);
    try {
      const response = await InvoiceBasisService.getInvoiceBasis(id);
      setInvoiceBasis(response || {});
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const closeInvoiceBasis = async () => {
    setClosing(true);
    try {
      const response = await InvoiceBasisService.closeInvoiceBasis(
        invoiceBasis.id
      );
      if (response) {
        loadData();
      }
    } finally {
      setClosing(false);
    }
  };

  const saveInvoiceBasis = async () => {
    setSaving(true);
    try {
      const updates = invoiceBasis.rows.map(
        ({ id, order: { extraPickup, minimumValue, invoiceComments } }) => ({
          id,
          extraPickup,
          minimumValue,
          invoiceComments
        })
      );
      const response = await InvoiceBasisService.updateInvoiceBasis(
        invoiceBasis.id,
        updates
      );
      if (response) {
        setEditMode(false);
        loadData();
      }
    } catch (e) {
      console.log(e);
    } finally {
      setSaving(false);
    }
  };

  const updateRow = (index, id, value) => {
    const property = id.split(".")[1];
    const newRows = [...invoiceBasis.rows];
    newRows[index].order[property] = value;
    setInvoiceBasis((prev) => ({ ...prev, rows: newRows }));
  };

  return loading ? (
    <LoadingSpinner fill={true} />
  ) : (
    <div className="container">
      <div className="row">
        <div className="col-12 p-2">
          <div className="info-card">
            <div className="info-card-header">
              <h4 className="title">Fakturaunderlag</h4>
              <SimpleInfoTag content={invoiceBasis.id} />
              <SimpleInfoTag content={getHandlerName(invoiceBasis.handler)} />
              <SimpleInfoTag
                content={getInvoiceBasisStatusName(invoiceBasis.status)}
              />
            </div>
            <div className="info-card-body">
              <div className="row mb-2 options-row">
                <InvoiceBasisPdfButton
                  invoiceBasisId={id}
                  show={
                    invoiceBasis.status === invoiceBasisStatuses.CLOSED.value
                  }
                />
                <InvoiceBasisExcelButton
                  invoiceBasisId={id}
                  show={
                    invoiceBasis.status === invoiceBasisStatuses.CLOSED.value
                  }
                />
                {invoiceBasis.status === invoiceBasisStatuses.OPEN.value && (
                  <>
                    <div className="col-md-4 col-lg-3 col-xl-2">
                      <button
                        className="btn btn-primary w-100"
                        disabled={closing || editMode}
                        onClick={closeInvoiceBasis}
                      >
                        {closing ? (
                          <LoadingSpinner
                            fill={true}
                            small={true}
                            theme={"secondary"}
                          />
                        ) : (
                          "Stäng fakturaunderlag"
                        )}
                      </button>
                    </div>

                    <div className="col-md-3 col-lg-2">
                      <button
                        className="btn btn-primary w-100"
                        disabled={closing || editMode}
                        onClick={() => setEditMode(true)}
                      >
                        Redigera
                      </button>
                    </div>
                  </>
                )}
                {editMode && (
                  <div className="col-md-3 col-lg-2">
                    <button
                      className="btn btn-primary w-100"
                      disabled={saving}
                      onClick={saveInvoiceBasis}
                    >
                      {saving ? (
                        <LoadingSpinner
                          fill={true}
                          small={true}
                          theme={"secondary"}
                        />
                      ) : (
                        "Spara"
                      )}
                    </button>
                  </div>
                )}
              </div>
              <InvoiceBasisRowsTable
                columns={columns}
                invoiceBasisRows={invoiceBasis.rows}
                editMode={editMode}
                updateRow={updateRow}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InvoiceBasisDetails;
