import { AuthenticatedTemplate, useMsal } from "@azure/msal-react";
import { Link, NavLink } from "react-router-dom";

function Navbar() {
  const { instance } = useMsal();

  const logout = async () => {
    instance
      .logoutPopup({ account: instance.getActiveAccount() })
      .then(() => instance.setActiveAccount())
      .catch((e) => console.error(e));
  };

  const links = () => {
    const dropdowns = [
      {
        label: "Rapportering",
        links: [
          { path: "/order-reports", label: "Orderrapportering" },
          { path: "/waybill-reports", label: "Fraktsedelsrapportering" }
        ]
      },
      {
        label: "Fraktsedlar",
        links: [
          { path: "waybills/import", label: "Import" },
          { path: "waybills/export", label: "Export" }
        ]
      },
      {
        label: "Orders",
        links: [
          { path: "/import", label: "Import" },
          { path: "/export", label: "Export" }
        ]
      }
    ];

    const getNavLinkClassNames = (navData) =>
      `nav-link text-end text-lg-start ${navData.isActive ? "active" : ""}`;

    return (
      <div className="collapse navbar-collapse" id="navbar-links">
        <ul className="navbar-nav mt-2 mt-lg-0">
          <li className="nav-item me-0 me-lg-2">
            <NavLink className={getNavLinkClassNames} to="transporters">
              Transportörer
            </NavLink>
          </li>
          <li className="nav-item me-0 me-lg-2">
            <NavLink className={getNavLinkClassNames} to="invoicebases">
              Fakturaunderlag
            </NavLink>
          </li>
          {dropdowns.map((dropdown) => (
            <li key={dropdown.label} className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle text-end"
                id={dropdown.label + "-button"}
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {dropdown.label}
              </a>
              <ul
                className="dropdown-menu p-2 ps-lg-0 pe-lg-0 mb-1 mb-lg-0 dropdown-menu-end dropdown-menu-lg-start"
                aria-labelledby={dropdown.label + "-button"}
              >
                {dropdown.links.map(({ label, path }) => (
                  <li key={label} className="nav-item me-0 me-lg-2">
                    <NavLink className={getNavLinkClassNames} to={path}>
                      {label}
                    </NavLink>
                  </li>
                ))}
              </ul>
            </li>
          ))}
          <li className="nav-item align-self-end">
            <button onClick={logout} className="btn btn-primary text-light">
              Logga ut
            </button>
          </li>
        </ul>
      </div>
    );
  };

  const user = () => {
    return (
      <span className="m-2 text-primary">
        {instance.getActiveAccount()?.name}
      </span>
    );
  };

  return (
    <nav className="navbar fixed-top navbar-expand-lg navbar-light bg-light">
      <div className="container-fluid">
        <Link className="navbar-brand" to="/">
          Autonomous
        </Link>
        <AuthenticatedTemplate>
          <div className="center-y">
            {user()}

            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbar-links"
              aria-controls="navbar-links"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
          </div>

          {links()}
        </AuthenticatedTemplate>
      </div>
    </nav>
  );
}

export default Navbar;
