export const orderStatuses = {
  OPEN: {
    name: "Öppen",
    value: "OPEN"
  },
  AWB: {
    name: "AWB",
    value: "AWB"
  },
  CLOSED: {
    name: "Stängd",
    value: "CLOSED"
  },
  CANCELLED: {
    name: "Makulerad",
    value: "CANCELLED"
  }
};

export const deviations = {
  NONE: {
    name: "Ingen",
    value: "NONE"
  },
  WRONG_SHIPMENT: {
    name: "Fel sändning",
    value: "WRONG_SHIPMENT"
  }
};
