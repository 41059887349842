import { useState } from "react";
import { windowBreakpoints } from "../core/constants";
import { useWindowSize } from "../core/hooks";
import AsyncButton from "./AsyncButton";

function ConfirmModal({ htmlId, header, message, onConfirm, onSuccess }) {
  const [loading, setLoading] = useState(false);
  const [width] = useWindowSize();

  const confirm = async () => {
    setLoading(true);
    const isSuccessful = await onConfirm();
    setLoading(false);
    if (isSuccessful) {
      const element = document.querySelector(`#close-${htmlId}`);
      element.click();
      onSuccess();
    }
  };

  return (
    <div className="modal fade" id={htmlId} tabIndex="-1" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{header}</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              disabled={loading}
            ></button>
          </div>
          <div className="modal-body">{message}</div>
          <div className="modal-footer">
            <div className="row w-100 g-0 justify-content-end">
              <div
                className={
                  "col-md-3 me-0 me-md-2" +
                  (width < windowBreakpoints.medium ? " mb-3" : "")
                }
              >
                <button
                  type="button"
                  className="btn btn-secondary w-100"
                  data-bs-dismiss="modal"
                  id={`close-${htmlId}`}
                  disabled={loading}
                >
                  Avbryt
                </button>
              </div>

              <div className="col-md-3">
                <AsyncButton buttonText="Ja" clickFunction={confirm} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConfirmModal;
