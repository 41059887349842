import { msalInstance } from "..";
import AuthenticationError from "./Errors/AuthenticationError";
import ResponseError from "./Errors/ResponseError";
import { accessTokenRequest } from "./msal-config";

const prepareHeadersObject = (headers = {}) => {
  if (!headers["Content-Type"]) {
    headers["Content-Type"] = "application/json";
  }

  return headers;
};

const toHeaders = (headers = {}) =>
  headers instanceof Headers ? headers : new Headers(headers);

const setAuthorizationHeader = (options, accessToken) => {
  options.headers = toHeaders(options.headers);
  options.headers.append("Authorization", `Bearer ${accessToken}`);
};

export const post = (url, options = {}) => {
  options.method = "POST";

  options.headers = prepareHeadersObject(options.headers);

  return fetchWithAuth(url, options);
};

export const get = (url, options = {}) => {
  options.method = "GET";

  return fetchWithAuth(url, options);
};

export const put = (url, options = {}) => {
  options.method = "PUT";

  options.headers = prepareHeadersObject(options.headers);

  return fetchWithAuth(url, options);
};

export const del = (url, options = {}) => {
  options.method = "DELETE";

  return fetchWithAuth(url, options);
};

export const fetchWithAuth = async (url, options) => {
  const account = msalInstance.getActiveAccount();

  const tokenRequest = {
    ...accessTokenRequest,
    account
  };

  try {
    const { accessToken } = await msalInstance.acquireTokenSilent(tokenRequest);
    setAuthorizationHeader(options, accessToken);
  } catch (e) {
    throw new AuthenticationError("Failed to aquire token silently!", e);
  }

  const response = await fetch(url, options);

  if (response.ok) {
    return response;
  } else {
    throw new ResponseError(options.method, response.status, "Request failed!");
  }
};
