import { useMemo, useState } from "react";
import { useTable } from "react-table";

function InvoiceBasisRowTableCell({
  value: initialValue,
  row: { index },
  column: { id },
  editMode,
  updateRow
}) {
  const [editableColumns] = useState([
    "order.extraPickup",
    "order.minimumValue",
    "order.invoiceComments"
  ]);

  return editMode && editableColumns.includes(id) ? (
    <input
      className="invoice-basis-row"
      value={initialValue || ""}
      onChange={({ target: { value } }) => updateRow(index, id, value)}
    />
  ) : (
    <span>{initialValue}</span>
  );
}

function InvoiceBasisRowsTable({
  columns: headers,
  invoiceBasisRows,
  editMode,
  updateRow
}) {
  const columns = useMemo(() => headers, [headers]);
  const data = useMemo(() => invoiceBasisRows, [invoiceBasisRows]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
      defaultColumn: {
        Cell: InvoiceBasisRowTableCell
      },
      editMode,
      updateRow
    });

  return (
    <div className="table-wrap">
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()} className="p-1 fs-7">
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()} className="p-1 pt-3 pb-3">
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default InvoiceBasisRowsTable;
