import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { useSortBy, useTable } from "react-table";

function WaybillTable({
  columns: headers,
  waybills,
  selectRow,
  selectedRows,
  updateQuery,
  query,
  selectAll,
  manualSort = true,
  selectReported = true,
  hiddenColumns = [],
  clickable = true
}) {
  const navigate = useNavigate();
  const columns = useMemo(() => headers, [headers]);
  const data = useMemo(() => waybills, [waybills]);
  const [sortValue, setSortValue] = useState([]);

  const {
    state: { sortBy },
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable(
    {
      columns,
      data,
      manualSortBy: manualSort,
      initialState: {
        hiddenColumns,
        sortBy: manualSort ? [] : sortValue
      }
    },
    (hooks) => {
      selectRow &&
        hooks.visibleColumns.push((columns) => [
          {
            id: "selection",
            Header: () => (
              <div
                className="wh-100 center clickable"
                onClick={() => selectAll()}
              >
                {
                  <input
                    className="table-checkbox clickable"
                    type="checkbox"
                    defaultChecked={waybills
                      .filter((waybill) => !selectReported || waybill.reported)
                      .every((waybill) => selectedRows.has(waybill.id))}
                  />
                }
              </div>
            ),
            Cell: ({ row }) => (
              <div className="wh-100 center clickable">
                {(!selectReported || row.original.reported) && (
                  <input
                    className="table-checkbox clickable"
                    type="checkbox"
                    defaultChecked={selectedRows.has(row.original.id)}
                  />
                )}
              </div>
            )
          },
          ...columns
        ]);
    },
    useSortBy
  );

  useEffect(() => {
    if (manualSort) {
      if (!!sortBy[0]) {
        const { id: sort } = sortBy[0];

        let direction;
        if (query.sort === sort) {
          if (query.direction) {
            direction = query.direction === "DESC" ? "ASC" : "DESC";
          } else {
            direction = "DESC";
          }
        } else {
          direction = "DESC";
        }
        updateQuery({ sort, direction });
      }
    } else {
      setSortValue(sortBy);
    }
  }, [sortBy]);

  const goToWaybill = (id) => {
    navigate(`/waybills/${id}`);
  };

  return (
    <div className="table-wrap">
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className="p-1 fs-7"
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                className={clickable ? "clickable" : ""}
              >
                {row.cells.map((cell) => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      className="p-1 pt-3 pb-3"
                      onClick={() =>
                        cell.column.id === "selection"
                          ? (!selectReported || row.original.reported) &&
                            selectRow(row.original.id)
                          : clickable && goToWaybill(row.original.id)
                      }
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default WaybillTable;
