import i18next from "i18next";
import { Component } from "react";
import { toast } from "react-toastify";
import ErrorView from "../../views/ErrorView";
import ResponseError from "../Errors/ResponseError";
import { getErrorType, isAuthenticationError } from "../utils";
import { withLocation } from "../with-location";

class GenericErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: {} };
  }

  componentDidCatch(error) {
    this.handleError({ reason: error });
  }

  handleError = ({ reason }) => {
    if (reason instanceof ResponseError) {
      return this.handleResponseError(reason);
    }

    this.setState({ hasError: true, error: reason });
  };

  handleResponseError = (error) => {
    switch (error.method) {
      case "POST":
      case "PUT":
      case "DELETE":
        const errorType = getErrorType(error.status);
        toast.warn(i18next.t(`errors.${errorType}.title`));
        break;
      default:
        this.setState({ hasError: true, error });
        break;
    }
  };

  componentDidMount() {
    window.addEventListener("unhandledrejection", this.handleRejection);
  }

  componentWillUnmount() {
    window.removeEventListener("unhandledrejection", this.handleRejection);
  }

  getSnapshotBeforeUpdate(prevProps) {
    return {
      routeChanged: prevProps.location.pathname !== this.props.location.pathname
    };
  }

  componentDidUpdate(prevProps, prevState, { routeChanged }) {
    if (routeChanged) {
      this.setState({ hasError: false });
    }
  }

  handleRejection = (error) => {
    if (!isAuthenticationError(error)) {
      this.handleError(error);
    }
  };

  render() {
    const { hasError, error } = this.state;

    if (hasError) {
      return <ErrorView error={error} />;
    }

    return this.props.children;
  }
}

export default withLocation(GenericErrorBoundary);
