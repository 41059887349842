import { useMemo } from "react";
import { useNavigate } from "react-router";
import { useSortBy, useTable } from "react-table";

function TransportOrderTable({ columns: headers = [], transportOrders }) {
  const navigate = useNavigate();
  const columns = useMemo(() => headers, [headers]);
  const data = useMemo(() => transportOrders, [transportOrders]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        initialState: {
          hiddenColumns: ["id"],
          sortBy: [
            {
              id: "id",
              desc: true
            }
          ]
        }
      },
      useSortBy
    );

  const goToTransportOrder = (id) => {
    navigate(`/transport-orders/${id}`);
  };

  return (
    <div className="table-wrap">
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(
                    column.getSortByToggleProps({ title: undefined })
                  )}
                  className="p-1 fs-7"
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.length ? (
            rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} className="clickable">
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        className="p-1 pt-3 pb-3"
                        onClick={() => goToTransportOrder(row.original.id)}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })
          ) : (
            <tr>
              <td
                colSpan={headerGroups.length && headerGroups[0].headers.length}
                className="text-center"
              >
                Inga bilar
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default TransportOrderTable;
