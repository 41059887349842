import { useState } from "react";
import { toLocaleDateString } from "../../core/utils";
import OrderService from "../../services/OrderService";

function AddBomModal({ htmlId, order, handleChange }) {
  const [date, setDate] = useState(toLocaleDateString(new Date()));

  const addBom = async () => {
    const response = await OrderService.createBom(
      order.id,
      new Date(date).getTime()
    );
    if (response) {
      const element = document.querySelector(`#close-bom-modal`);
      element.click();
      handleChange();
    }
  };

  return (
    <div className="modal fade" id={htmlId} tabIndex="-1" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Lägg till bom
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <form className="row">
              <div className="col-12">
                <input
                  className="w-100"
                  type="date"
                  value={date}
                  onChange={(e) =>
                    setDate(toLocaleDateString(e.target.valueAsDate))
                  }
                />
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
              id="close-bom-modal"
            >
              Avbryt
            </button>
            <button type="button" className="btn btn-primary" onClick={addBom}>
              Spara bom
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddBomModal;
