import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import LoadingSpinner from "../../components/LoadingSpinner";
import InformationBlock from "../../components/InformationBlock";
import TransporterService from "../../services/TransporterService";
import TransportOrderService from "../../services/TransportOrderService";
import EditTransporterModal from "../../components/Transporters/EditTransporterModal";
import TransportOrderTable from "../../components/TransportOrders/TransportOrderTable";
import { transportOrderColumns } from "../../core/table-columns";
import Paginator from "../../components/Paginator";
import ConfirmModal from "../../components/ConfirmModal";

function TransporterDetails() {
  const { id } = useParams();
  const [loading, setLoading] = useState({
    transporter: true,
    active: true,
    inactive: true
  });
  const [inactivePageInfo, setInactivePageInfo] = useState({
    size: 10,
    pageIndex: 0,
    pageCount: 0
  });
  const [transporter, setTransporter] = useState({ contactInformation: {} });
  const [activeTransportOrders, setActiveTransportOrders] = useState([]);
  const [inactiveTransportOrders, setInactiveTransportOrders] = useState([]);
  const [editModalId] = useState("edit-transporter-modal");
  const [deleteModalId] = useState("delete-transporter-modal");
  const navigate = useNavigate();
  const addressData = () => {
    const { address1, address2 } = transporter.contactInformation;
    return [address1, address2].filter((part) => !!part).join("\n");
  };
  const infoBlocks = useMemo(
    () => [
      // TO KEEP TRANSPORT AS SIMPLE AS POSSIBLE THIS IS REMOVED FOR NOW.
      // {
      //   header: "Kontaktperson",
      //   data: [
      //     {
      //       label: "Namn",
      //       data: transporter.contactInformation.contactPerson || ""
      //     },
      //     {
      //       label: "Email",
      //       data: transporter.contactInformation.email
      //     },
      //     {
      //       label: "Telefonnummer",
      //       data: transporter.contactInformation.phoneNumber
      //     }
      //   ]
      // },
      // {
      //   header: "Adress",
      //   data: [
      //     {
      //       label: "Adress",
      //       data: addressData()
      //     },
      //     {
      //       label: "Postnummer",
      //       data: transporter.contactInformation.zipCode
      //     },
      //     {
      //       label: "Land",
      //       data: transporter.contactInformation.country
      //     }
      //   ]
      // }
    ],
    [transporter]
  );

  useEffect(() => {
    loadData();
    loadActive();
  }, [id]);

  useEffect(() => {
    loadInactive();
  }, [inactivePageInfo.pageIndex]);

  const loadData = async () => {
    setLoading((prev) => ({ ...prev, transporter: true }));
    try {
      const response = await TransporterService.getTransporter(id);
      setTransporter(response);
    } finally {
      setLoading((prev) => ({ ...prev, transporter: false }));
    }
  };

  const loadActive = async () => {
    setLoading((prev) => ({ ...prev, active: true }));

    try {
      const response = await TransportOrderService.getTransportOrders({
        transporterId: id,
        active: true
      });

      setActiveTransportOrders(response || []);
    } finally {
      setLoading((prev) => ({ ...prev, active: false }));
    }
  };

  const loadInactive = async () => {
    setLoading((prev) => ({ ...prev, inactive: true }));

    try {
      const {
        content,
        number: pageIndex,
        totalPages: pageCount
      } = await TransportOrderService.getTransportOrders({
        transporterId: id,
        active: false,
        size: inactivePageInfo.size,
        page: inactivePageInfo.pageIndex
      });

      setInactiveTransportOrders(content || []);
      setInactivePageInfo((prev) => ({ ...prev, pageIndex, pageCount }));
    } finally {
      setLoading((prev) => ({ ...prev, inactive: false }));
    }
  };

  const deleteTransporter = async () => {
    const response = await TransporterService.deleteTransporter(transporter.id);
    return !!response;
  };

  return (
    <>
      <EditTransporterModal
        htmlId={editModalId}
        transporter={transporter}
        afterSave={loadData}
      />
      <ConfirmModal
        htmlId={deleteModalId}
        header="Ta bort transportör"
        message="Är du säker på att du vill ta bort denna transportör?"
        onConfirm={deleteTransporter}
        onSuccess={() => navigate("/transporters")}
      />
      {loading.transporter ? (
        <LoadingSpinner fill={true} />
      ) : (
        <div className="container">
          <div className="row">
            <div className="col-12 p-2">
              <div className="info-card">
                <div className="info-card-header">
                  <h4>{transporter.name}</h4>
                </div>
                <div className="info-card-body">
                  <div className="row options-row">
                    <div className="col-md-3 col-xl-2">
                      <button
                        type="button"
                        className="btn btn-primary w-100"
                        data-bs-toggle="modal"
                        data-bs-target={`#${editModalId}`}
                      >
                        Redigera transportör
                      </button>
                    </div>
                    <div className="col-md-2">
                      <button
                        type="button"
                        className="btn btn-primary w-100"
                        onClick={() =>
                          navigate({
                            pathname: "/transport-orders/create",
                            search: "?transporterId=" + id
                          })
                        }
                      >
                        Lägg till bil
                      </button>
                    </div>
                    {!transporter.numberOfTransportOrders && (
                      <div className="col-md-3 col-xl-2">
                        <button
                          type="button"
                          className="btn btn-primary w-100"
                          data-bs-toggle="modal"
                          data-bs-target={`#${deleteModalId}`}
                        >
                          Ta bort transportör
                        </button>
                      </div>
                    )}
                  </div>
                  <div className="row g-0">
                    {infoBlocks.map(({ header, data }, index) => (
                      <InformationBlock
                        key={header + "_" + index}
                        header={header}
                        responsive
                        responsiveClasses="col-xl-6"
                        data={data}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 p-2">
              <div className="info-card">
                <div className="info-card-header">
                  <h4>Aktiva bilar</h4>
                </div>
                <div className="info-card-body">
                  {loading.active ? (
                    <LoadingSpinner fill={true} />
                  ) : (
                    <TransportOrderTable
                      columns={transportOrderColumns}
                      transportOrders={activeTransportOrders}
                    />
                  )}
                </div>
              </div>
            </div>

            <div className="col-12 p-2">
              <div className="info-card">
                <div className="info-card-header">
                  <h4>Inaktiva bilar</h4>
                </div>
                <div className="info-card-body">
                  {loading.inactive ? (
                    <LoadingSpinner fill={true} />
                  ) : (
                    <TransportOrderTable
                      columns={transportOrderColumns}
                      transportOrders={inactiveTransportOrders}
                    />
                  )}
                  <Paginator
                    handlePageChange={(newIndex) =>
                      setInactivePageInfo((prev) => ({
                        ...prev,
                        pageIndex: newIndex
                      }))
                    }
                    pageIndex={inactivePageInfo.pageIndex}
                    pageCount={inactivePageInfo.pageCount}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default TransporterDetails;
