import { useEffect, useState } from "react";
import "react-calendar/dist/Calendar.css";
import OrderReportService from "../../services/OrderReportService";
import {
  arraySorter,
  getHandlerName,
  toLocaleDateString
} from "../../core/utils";
import SimpleInfoTag from "../../components/SimpleInfoTag";
import ReportDatePicker from "../../components/Reporting/ReportDatePicker";

function OrderReport() {
  const [loading, setLoading] = useState(true);
  const [dates, setDates] = useState([]);
  const [chosenDate, setChosenDate] = useState(new Date());
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    loadDates();
  }, []);

  const loadDates = async () => {
    const data = await OrderReportService.getDates();
    if (data) {
      setDates(data.filter((date) => !!date));
    }
  };

  useEffect(() => {
    loadOrders();
  }, [chosenDate]);

  const loadOrders = async () => {
    try {
      setLoading(true);
      const data = await OrderReportService.getOrders({
        pickupDate: toLocaleDateString(chosenDate)
      });
      if (data) {
        data.sort((a, b) => arraySorter(a, b));
        setOrders(data);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (date) => {
    setChosenDate(date);
  };

  const updateOrder = (order, key, value) => {
    const clonedOrders = [...orders];
    const foundOrder = orders.find((o) => o.id === order.id);
    if (foundOrder) {
      foundOrder[key] = value;
    }
    setOrders(clonedOrders);
  };

  const saveOrders = async () => {
    try {
      setLoading(true);
      console.log("saveOrders: ", JSON.stringify(orders))
      const response = await OrderReportService.report(JSON.stringify(orders));
      console.log("result: ", response, response.body)
      if (response) {
        await loadDates();
        await loadOrders();
      }
    } catch (e) {
      console.log("error saving orders in OrderReport: ", e)
    }
    finally {
      setLoading(false);
    }
  };

  return (
    <div className="container">
      <h2>Order-rapportering</h2>
      <ReportDatePicker
        chosenDate={chosenDate}
        highlightedDates={dates}
        handleChange={handleChange}
      />
      <div className="row">
        <form className="col-12 border border-secondary rounded p-2">
          {orders.length ? (
            orders.map((order) => (
              <div
                key={order.id}
                className="row border border-primary rounded p-1 mb-1 g-0"
              >
                <div className="col-md-1 p-1">
                  <label className="form-label">SPEDITÖR</label>
                  <div>
                    <SimpleInfoTag content={getHandlerName(order.handler)} />
                  </div>
                </div>
                <div className="col-md-1 p-1">
                  <label className="form-label">PICKUP DATE</label>
                  <input
                    className="form-control"
                    type="text"
                    value={
                      order.pickupDate
                        ? toLocaleDateString(order.pickupDate)
                        : ""
                    }
                    disabled
                  />
                </div>
                <div className="col-md-2 p-1">
                  <label className="form-label">CONSIGNORS REF</label>
                  <input
                    className="form-control"
                    type="text"
                    value={order.consignorsReference || ""}
                    disabled
                  />
                </div>
                {/* <div className="col-md-2 p-1">
                  <label className="form-label">MASTER</label>
                  <input
                    className="form-control"
                    type="text"
                    value={order.airwayBill || ""}
                    disabled
                  />
                </div> */}
                {/* Här ska PU och HAWB in (Önskemål från MFT 2023-10-17) */}
                <div className="col-md-1 p-1">
                  <label className="form-label">HAWB</label>
                  <input
                    className="form-control"
                    type="text"
                    value={order.house || ""}
                    disabled
                  />
                </div>
                <div className="col-md-3 p-1">
                  <label className="form-label">PU</label>
                  <input
                    className="form-control"
                    type="text"
                    value={order.pickupLocation || ""}
                    disabled
                  />
                </div>
                <div className="col-md-2 p-1">
                  <label className="form-label">HÄMTAD</label>
                  <input
                    className="form-control"
                    value={order.reportPickupDate || ""}
                    onChange={({ target }) =>
                      updateOrder(order, "reportPickupDate", target.value)
                    }
                    type="text"
                    placeholder="yyyy-MM-dd HH:mm"
                    disabled={loading}
                  />
                </div>
                <div className="col-md-2 p-1">
                  <label className="form-label">LÄMNAD</label>
                  <input
                    className="form-control"
                    value={order.reportDepartureDate || ""}
                    onChange={({ target }) => {
                      updateOrder(order, "reportDepartureDate", target.value);
                    }}
                    type="text"
                    placeholder="yyyy-MM-dd HH:mm"
                    disabled={loading}
                  />
                </div>
              </div>
            ))
          ) : (
            <div className="center-x">
              <span>Ingen data</span>
            </div>
          )}
          <div className="row">
            <div className="col-md-2">
              <button
                type="button"
                onClick={saveOrders}
                className="btn btn-primary w-100"
                disabled={loading}
              >
                Spara
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default OrderReport;
