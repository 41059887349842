import { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import LoadingSpinner from "../../components/LoadingSpinner";
import Paginator from "../../components/Paginator";
import CloseForInvoiceButton from "../../components/Waybills/CloseForInvoiceButton";
import CreateInvoiceBasisButton from "../../components/Waybills/CreateInvoiceBasisButton";
import WaybillFilter from "../../components/Waybills/WaybillFilter";
import WaybillTable from "../../components/Waybills/WaybillTable";
import { invoiceStatuses } from "../../core/constants";
import handlers from "../../core/handlers";
import { waybillColumns } from "../../core/table-columns";
import { waybillStatuses } from "../../core/waybill-constants";
import WaybillService from "../../services/WaybillService";

function Waybills() {
  const { pathname } = useLocation();
  const [controlledPathname, setControlledPathname] = useState(pathname);
  const [loading, setLoading] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const [columns, setColumns] = useState(waybillColumns);
  const [waybills, setWaybills] = useState([]);
  const [query, setQuery] = useState({
    handler: "",
    status: waybillStatuses.AWB.value,
    ...Object.fromEntries(searchParams)
  });
  const [pageInfo, setPageInfo] = useState({ pageIndex: 0, pageCount: 0 });
  const [selectedRows, setSelectedRows] = useState(new Set());

  useEffect(() => {
    if (pathname !== controlledPathname) {
      setControlledPathname(pathname);
      setSelectedRows(new Set());
      setQuery({ handler: "", status: waybillStatuses.AWB.value });
    }
  }, [pathname]);

  useEffect(() => {
    const stringifiedQuery = JSON.stringify(query);
    setSearchParams(JSON.parse(stringifiedQuery));
    loadData();
  }, [query]);

  const loadData = async () => {
    setLoading(true);
    try {
      const path = pathname.includes("/import") ? "/import" : "/export";
      const response = await WaybillService.getWaybills(path, query);
      const { content, number: pageIndex, totalPages: pageCount } = response;
      setWaybills(content);
      setPageInfo({ pageIndex, pageCount });
    } finally {
      setLoading(false);
    }
  };

  const updateQuery = (updates) => {
    const updateQuery = { ...query, ...updates };
    if (
      updateQuery.handler !== query.handler ||
      updateQuery.status !== query.status
    ) {
      setSelectedRows(new Set());
    }
    setQuery(updateQuery);
  };

  const handlePageChange = (newPage) => {
    updateQuery({ page: newPage });
  };

  const showCloseForInvoice = () =>
    query.status === waybillStatuses.AWB.value &&
    (!query.invoiceStatuses ||
      query.invoiceStatuses === invoiceStatuses.OPEN.value);

  const showCreateInvoiceBasis = () =>
    query.status === waybillStatuses.CLOSED.value &&
    query.invoiceStatuses === invoiceStatuses.INVOICE.value &&
    [
      handlers.DHL_EXPORT.value,
      handlers.DHL_IMPORT.value,
      handlers.GEODIS_IMPORT.value,
      handlers.GEODIS_EXPORT,
      handlers.SGL_IMPORT.value
    ].includes(query.handler);

  const selectRow = (id) => {
    if (selectedRows.has(id)) {
      selectedRows.delete(id);
      setSelectedRows((prev) => new Set([...prev]));
    } else {
      setSelectedRows((prev) => new Set([...prev, id]));
    }
    refreshTable();
  };

  //Set all as selected
  const selectAll = () => {
    const allSelected = waybills
      .filter((waybill) => waybill.reported)
      .every((waybill) => selectedRows.has(waybill.id));

    if (allSelected) {
      waybills.forEach(
        (waybill) => waybill.reported && selectedRows.delete(waybill.id)
      );
    } else {
      waybills.forEach(
        (waybill) => waybill.reported && selectedRows.add(waybill.id)
      );
    }
    setSelectedRows((prev) => new Set([...prev]));
    refreshTable();
  };

  const refreshTable = () => {
    setColumns((prev) => [...prev]);
    setWaybills((prev) => [...prev]);
  };

  const handleCloseInvoiceSuccess = async () => {
    setSelectedRows(new Set());
    await loadData();
  };

  return (
    <div className="container h-100">
      <div className="mb-2">
        <WaybillFilter
          isImport={pathname.includes("/import")}
          query={query}
          updateQuery={updateQuery}
        />
      </div>
      <CloseForInvoiceButton
        show={showCloseForInvoice()}
        selectedIds={selectedRows}
        handleSuccess={handleCloseInvoiceSuccess}
      />
      <CreateInvoiceBasisButton
        show={showCreateInvoiceBasis()}
        selectedHandler={query.handler}
      />
      {!!selectedRows.size && (
        <div className="mb-2 center-y">
          <span className="me-3">{selectedRows.size} valda fraksedlar</span>
          <button
            className="btn btn-danger btn-sm"
            onClick={() => {
              setSelectedRows(new Set());
              refreshTable();
            }}
          >
            Rensa valda fraktsedlar
          </button>
        </div>
      )}
      <div className="mb-2">
        {loading ? (
          <LoadingSpinner fill={true} />
        ) : (
          <WaybillTable
            columns={columns}
            waybills={waybills}
            selectRow={showCloseForInvoice() && selectRow}
            selectAll={selectAll}
            selectedRows={selectedRows}
            updateQuery={updateQuery}
            query={query}
          />
        )}
      </div>
      <Paginator
        handlePageChange={handlePageChange}
        pageIndex={pageInfo.pageIndex}
        pageCount={pageInfo.pageCount}
      />
    </div>
  );
}

export default Waybills;
