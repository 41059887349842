import "./App.scss";
import "bootstrap/dist/js/bootstrap.bundle.min";
import {
  Outlet,
  Navigate,
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate
} from "react-router-dom";
import { useLocation } from "react-router";
import Login from "./views/Login/Login";
import Orders from "./views/Orders/Orders";
import Navbar from "./components/Navbar";
import OrderDetails from "./views/Orders/OrderDetails";
import WayBillDetails from "./views/Waybills/WaybillDetails";
import Dashboard from "./views/Dashboard/Dashboard";
import OrderReport from "./views/Reporting/OrderReport";
import Waybills from "./views/Waybills/Waybills";
import WaybillReport from "./views/Reporting/WaybillReport";
import WaybillMobileReport from "./views/Reporting/WaybillMobileReport";
import InvoiceBases from "./views/InvoiceBases/InvoiceBases";
import InvoiceBasisDetails from "./views/InvoiceBases/InvoiceBasisDetails";
import CreateOrder from "./views/Orders/CreateOrder";
import OrderList from "./views/Orders/OrderList";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useIsAuthenticated,
  useMsal
} from "@azure/msal-react";
import { useEffect } from "react";
import GenericErrorBoundary from "./core/ErrorBoundaries/GenericErrorBoundary";
import AuthenticationErrorBoundary from "./core/ErrorBoundaries/AuthenticationErrorBoundary";
import Transporters from "./views/Transporters/Transporters";
import TransporterDetails from "./views/Transporters/TransporterDetails";
import TransportOrderDetails from "./views/TransportOrders/TransportOrderDetails";
import TransportOrderCreate from "./views/TransportOrders/TransportOrderCreate";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AllDetail from "./views/Orders/AllDetail";

function PrivateRoutes() {
  const location = useLocation();
  const navigate = useNavigate();
  const isAuthenicated = useIsAuthenticated();

  useEffect(() => {
    if (!isAuthenicated) {
      navigate("/login", { from: location });
    }
  }, [isAuthenicated]);

  return (
    <AuthenticationErrorBoundary>
      <AuthenticatedTemplate>
        <Outlet />
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <Navigate to="login" replace state={{ from: location }} />
      </UnauthenticatedTemplate>
    </AuthenticationErrorBoundary>
  );
}

function App() {
  const { accounts, instance } = useMsal();
  instance.setActiveAccount(accounts[0]);

  return (
    <div className="app">
      <Router>
        <Navbar />
        <div className="content container-fluid">
          <ToastContainer />
          <GenericErrorBoundary>
            <Routes>
              <Route path="/create-order" element={<CreateOrder />}></Route>
              <Route path ="/kundportal"  element={<OrderList />}></Route>
              <Route path="/alldetail/:id" element={<AllDetail />}></Route>
              <Route path="/login" element={<Login />} />
              <Route
                path="/report-waybill/:jwt"
                element={<WaybillMobileReport />}
              />
              <Route path="/" element={<PrivateRoutes />}>
                <Route path="/" exact element={<Dashboard />} />
                <Route path="/waybills/import" element={<Waybills />} />
                <Route path="/waybills/export" element={<Waybills />} />
                <Route path="/import" element={<Orders />} />
                <Route path="/export" element={<Orders />} />
                <Route path="/orders/:id" element={<OrderDetails />} />
                <Route path="/waybills/:id" element={<WayBillDetails />} />
                <Route path="/order-reports" element={<OrderReport />} />
                <Route path="/waybill-reports" element={<WaybillReport />} />
                <Route
                  path="/invoicebases/:id"
                  element={<InvoiceBasisDetails />}
                />
                <Route path="/invoicebases" element={<InvoiceBases />} />
                <Route
                  path="/transporters/:id"
                  element={<TransporterDetails />}
                />
                <Route path="/transporters" element={<Transporters />} />
                <Route
                  path="/transport-orders/create"
                  element={<TransportOrderCreate />}
                />
                <Route
                  path="/transport-orders/:id"
                  element={<TransportOrderDetails />}
                />
              </Route>
            </Routes>
          </GenericErrorBoundary>
        </div>
      </Router>
    </div>
  );
}

export default App;
