import { useEffect, useState } from "react";
import { invoiceStatuses, pageLimits } from "../../core/constants";
import handlers from "../../core/handlers";
import { waybillStatuses } from "../../core/waybill-constants";

function WaybillFilter({ isImport, query, updateQuery }) {
  const [handlerOptions, setHandlerOptions] = useState([]);
  const [filter, setFilter] = useState({
    handlerSequence: ""
  });

  useEffect(() => {
    const displayedHandlers = Object.values(handlers).filter(
      ({ value, inactive }) =>
        !inactive &&
        ((isImport && value.endsWith("IMPORT")) ||
          (!isImport && !value.endsWith("IMPORT")))
    );

    setHandlerOptions(displayedHandlers);
  }, [isImport]);

  const setQuery = (updates) => {
    updateQuery({ ...updates, page: "" });
  };

  const handleInputChange = ({ target }) => {
    setFilter({ ...filter, [target.id]: target.value });
  };

  const search = (event) => {
    event.preventDefault();
    setQuery(filter);
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-3 col-lg-2 mb-2">
          <button
            className="btn btn-primary w-100"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#filters"
            aria-expanded="true"
            aria-controls="filters"
          >
            Filters
          </button>
        </div>
        <div className="col-12 mb-2 collapse show" id="filters">
          <form
            className="p-2 border border-secondary rounded"
            onSubmit={search}
          >
            <div className="row">
              <div className="col-md-6 col-xl-4">
                <label className="form-label w-100">
                  NUMBER
                  <input
                    id="handlerSequence"
                    className="form-control"
                    type="text"
                    onChange={handleInputChange}
                  />
                </label>
              </div>
            </div>
            <div className="row">
              <div className="col-md-3 col-lg-2">
                <button type="submit" className="btn btn-primary w-100">
                  Sök
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="row">
        <div className="col-md-4 col-lg-2">
          <label className="form-label w-100">Speditör</label>
          <select
            value={query.handler}
            className="form-select"
            onChange={(e) => setQuery({ handler: e.target.value })}
          >
            <option value="">Alla</option>
            {handlerOptions.map((handler) => (
              <option key={handler.value} value={handler.value}>
                {handler.name}
              </option>
            ))}
          </select>
        </div>
        <div className="col-md-4 col-lg-2">
          <label className="form-label w-100">Status</label>
          <select
            value={query.status}
            className="form-select"
            onChange={(e) => setQuery({ status: e.target.value })}
          >
            <option value="">Alla</option>
            {Object.values(waybillStatuses).map(({ name, value }) => (
              <option key={value} value={value}>
                {name}
              </option>
            ))}
          </select>
        </div>
        <div className="col-md-4 col-lg-2">
          <label className="form-label w-100">Faktureringsstatus</label>
          <select
            value={query.invoiceStatuses}
            className="form-select"
            onChange={(e) => setQuery({ invoiceStatuses: e.target.value })}
          >
            <option value="">Alla</option>
            {Object.values(invoiceStatuses).map(({ name, value }) => (
              <option key={value} value={value}>
                {name}
              </option>
            ))}
          </select>
        </div>
        <div className="col-md-4 col-lg-2">
          <label className="form-label w-100">Resultat per sida</label>
          <select
            value={query.size || 60}
            className="form-select"
            onChange={(e) => setQuery({ size: e.target.value })}
          >
            {pageLimits.map((value) => (
              <option key={value} value={value}>
                {value}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
}

export default WaybillFilter;
