import { useState } from "react";
import { withMountedRef } from "../core/with-mounted-ref";
import LoadingSpinner from "./LoadingSpinner";

function AsyncButton({
  isMounted,
  buttonTheme = "primary",
  buttonClass = "w-100 text-nowrap",
  buttonText = "Klicka",
  clickFunction,
  small = false,
  disabled = false
}) {
  const [loading, setLoading] = useState(false);

  const handleClick = async (e) => {
    setLoading(true);
    e.preventDefault();
    e.stopPropagation();
    try {
      await clickFunction();
    } finally {
      if (isMounted.current) {
        setLoading(false);
      }
    }
  };

  return (
    <button
      className={
        `btn btn-${buttonTheme} ${small ? "btn-sm " : ""}` + buttonClass
      }
      onClick={handleClick}
      disabled={disabled || loading}
    >
      {loading ? (
        <LoadingSpinner
          theme={buttonTheme === "primary" ? "secondary" : "primary"}
          fill
          small
        />
      ) : (
        buttonText
      )}
    </button>
  );
}

export default withMountedRef(AsyncButton);
