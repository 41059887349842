import { BASE_URL } from "../core/constants";
import { get, put } from "../core/fetch";
import { getUrlParams } from "../core/utils";
const BASE_REPORTS_URL = BASE_URL + "/reports";

const WaybillReportService = {
  getDates: async () => {
    const response = await get(`${BASE_REPORTS_URL}/waybills/dates`);
    if (response.ok) {
      return await response.json();
    }
  },
  getWaybills: async (params) => {
    const paramString = getUrlParams(params).toString();
    const response = await get(`${BASE_REPORTS_URL}/waybills?${paramString}`);
    if (response.ok) {
      return await response.json();
    }
  },
  report: async (data) => {
    const options = {
      body: data
    };
    const response = await put(`${BASE_REPORTS_URL}/waybills`, options);
    if (response.ok) {
      return response;
    }
  },
  reportWithImage: async (token, data) => {
    const options = {
      method: "PUT",
      body: data
    };
    const response = await fetch(
      `${BASE_REPORTS_URL}/waybills/jwt?token=${token}`,
      options
    );
    if (response.ok) {
      return response;
    }
  }
};

export default WaybillReportService;
