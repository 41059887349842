import i18next from "i18next";
import { COOKIES, getCookie } from "./utils";

i18next.init({
  lng: getCookie(COOKIES.language) || "sv",
  resources: {
    en: {
      translation: {
        yes: "Yes",
        errors: {
          bad_request: {
            title: "Your API call is incorrect."
          },
          unauthorized: {
            title: "You are not authorized for this action."
          },
          not_found: {
            title: "Could not find the resource."
          },
          generic: {
            title: "Something went wrong."
          }
        },
        info_blocks: {
          name: "Name",
          address: "Address",
          city: "City"
        },
        mobile_report: {
          report_success: "Report successfull!",
          is_reported: "The waybill is already reported.",
          error_no_acknowledged: "You have to enter your name!",
          error_no_photo: "You have to add a photo!",
          error_no_status: "You have to choose delivery status!",
          error_no_remark: "You have to enter a remark!",
          error_failed:
            "Report failed! Give the waybill to MFT if the problem persists.",
          waybill_card: {
            title: "WAYBILL"
          },
          report_card: {
            title: "REPORT",
            acknowledged_by: "Your name",
            signature_photo: "Photo of signature",
            delivered_status_label: "Is all cargo delivered?",
            with_remark: "with remark",
            remark_label: "Remark",
            report_button: "Report waybill delivered"
          }
        }
      }
    },
    sv: {
      translation: {
        yes: "Ja",
        errors: {
          bad_request: {
            title: "Ditt API-anrop är felaktigt."
          },
          unauthorized: {
            title: "Du saknar rättighet att utföra denna handling."
          },
          not_found: {
            title: "Kunde inte hitta resursen."
          },
          generic: {
            title: "Någonting gick fel."
          }
        },
        info_blocks: {
          name: "Namn",
          address: "Adress",
          city: "Ort"
        },
        mobile_report: {
          report_success: "Rapportering klar!",
          is_reported: "Fraktsedeln är redan rapporterad.",
          error_no_acknowledged: "Du måste skriva in ditt namn!",
          error_no_photo: "Du måste välja ett foto!",
          error_no_status: "Du måste välja leveransstatus!",
          error_no_remark: "Du måste skriva in en anmärkning!",
          error_failed:
            "Rapport misslyckades! Lämna fraktsedeln till MFT om problemet kvarstår.",
          waybill_card: {
            title: "FRAKTSEDEL"
          },
          report_card: {
            title: "RAPPORTERA",
            acknowledged_by: "Ditt namn",
            signature_photo: "Foto på signatur",
            delivered_status_label: "Är allt gods leverat?",
            with_remark: "med anmärkning",
            remark_label: "Anmärkning",
            report_button: "Rapportera fraktsedel levererad"
          }
        }
      }
    }
  }
});

export default i18next;
