import { useMemo, useState } from "react";
import handlers from "../../core/handlers";
import { orderStatuses } from "../../core/order-constants";
import { isImport } from "../../core/utils";
import OrderService from "../../services/OrderService";
import LoadingSpinner from "../LoadingSpinner";

function EditOrderModal({ htmlId, order, handleChange }) {
  const [loading, setLoading] = useState(false);
  const [updatedOrder, setUpdatedOrder] = useState({ ...order });
  const formParts = useMemo(
    () => ({
      BASIC: {
        header: "INFORMATION",
        content: [
          {
            label: "Pickup date",
            data: updatedOrder.pickupDate,
            input: true,
            id: "pickupDate",
            inputType: "date"
          },
          {
            label: "Country of destination",
            data: updatedOrder.countryOfDestination,
            input: true,
            id: "countryOfDestination"
          }
        ]
      },
      CALCULATIONS: {
        header: "CALCULATIONS",
        content: [
          { label: "Gross weight", data: updatedOrder.grossWeight },
          { label: "Volume", data: updatedOrder.volume },
          { label: "Volume weight", data: updatedOrder.volumeWeight },
          { label: "Packages", data: updatedOrder.packageCount },
          {
            label: "Revenue",
            data: updatedOrder.revenue,
            input: true,
            id: "revenue",
            disabled: ![
              orderStatuses.OPEN.value,
              orderStatuses.AWB.value
            ].includes(updatedOrder.status)
          }
        ]
      },
      INVOICE: {
        header: "FAKTURERING",
        content: [
          {
            label: "Extra hämtning",
            data: updatedOrder.extraPickup,
            input: true,
            id: "extraPickup"
          },
          {
            label: "Min. värde",
            data: updatedOrder.minimumValue,
            input: true,
            id: "minimumValue"
          },
          {
            label: "Fakt. kommentar",
            data: updatedOrder.invoiceComments,
            input: true,
            id: "invoiceComments"
          }
        ]
      },
      CONSIGNEE: {
        header: "CONSIGNEE",
        content: [
          {
            label: "Namn",
            data: updatedOrder.consigneeAddress?.name,
            input: true,
            id: "consigneeAddress.name",
            disabled: updatedOrder.status !== orderStatuses.OPEN.value
          },
          {
            label: "Adress",
            data: updatedOrder.consigneeAddress?.addr,
            input: true,
            id: "consigneeAddress.addr",
            disabled: updatedOrder.status !== orderStatuses.OPEN.value
          },
          {
            label: "Postkod",
            data: updatedOrder.consigneeAddress?.zip,
            input: true,
            id: "consigneeAddress.zip",
            disabled: updatedOrder.status !== orderStatuses.OPEN.value
          },
          {
            label: "Ort",
            data: updatedOrder.consigneeAddress?.city,
            input: true,
            id: "consigneeAddress.city",
            disabled: updatedOrder.status !== orderStatuses.OPEN.value
          }
        ],
        hide: !isImport(updatedOrder)
      },
      PICKUP: {
        content: [
          {
            label: "Pickup location",
            data: updatedOrder.pickupLocation,
            hide: updatedOrder.handler === handlers.DHL_IMPORT.value,
            input: true,
            id: "pickupLocation"
          },
          {
            label: "Pickup name",
            data: updatedOrder.pickupName,
            hide: updatedOrder.handler === handlers.DHL_IMPORT.value,
            input: true,
            id: "pickupName"
          },
          {
            label: "Airway bill",
            data: updatedOrder.airwayBill,
            input: true,
            id: "airwayBill"
          },
          { label: "House", data: updatedOrder.house, input: true, id: "house" }
        ]
      },
      INSTRUCTIONS: {
        content: [
          {
            label: "Kommentarer",
            data: updatedOrder.comments,
            input: true,
            id: "comments"
          },
          {
            label: "Avsändarens instruktioner",
            data: updatedOrder.forwarderInstructions,
            input: true,
            id: "forwarderInstructions"
          }
        ]
      }
    }),
    [updatedOrder]
  );

  const saveOrder = async () => {
    setLoading(true);
    try {
      const response = await OrderService.updateOrder(
        order.id,
        JSON.stringify(updatedOrder)
      );
      if (response) {
        setLoading(false);
        const element = document.querySelector(`#close-edit-modal`);
        element.click();
        handleChange();
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const handleInputChange = ({ target: { id, value } }) => {
    const idParts = id.split(".");
    if (idParts.length > 1) {
      const clonedPart = updatedOrder[idParts[0]]
        ? { ...updatedOrder[idParts[0]] }
        : {};
      clonedPart[idParts[1]] = value;
      setUpdatedOrder((prev) => ({ ...prev, [idParts[0]]: clonedPart }));
    } else {
      setUpdatedOrder((prev) => ({ ...prev, [id]: value }));
    }
  };

  return (
    <div className="modal fade" id={htmlId} tabIndex="-1" aria-hidden="true">
      <div className="modal-dialog modal-xl modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Redigera order
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <form className="container-fluid">
              <div className="row g-0">
                {Object.entries(formParts).map(
                  ([key, { header, content, hide }]) =>
                    !hide && (
                      <div key={key} className="col-md-6 col-xl-4 p-2">
                        <div className="w-100 rounded border border-secondary p-2">
                          {header && (
                            <span className="fs-5 fw-light">{header}</span>
                          )}
                          {content.map(
                            ({
                              label,
                              data,
                              input,
                              inputType,
                              hide,
                              id,
                              disabled
                            }) => {
                              return (
                                !hide && (
                                  <div className="spread mb-2" key={label}>
                                    <label className="col-4">{label}:</label>
                                    {input ? (
                                      <input
                                        id={id}
                                        type={inputType ? inputType : "text"}
                                        className="col-8 text-end"
                                        value={data || ""}
                                        disabled={disabled}
                                        onChange={handleInputChange}
                                      />
                                    ) : (
                                      <span className="text-end">{data}</span>
                                    )}
                                  </div>
                                )
                              );
                            }
                          )}
                        </div>
                      </div>
                    )
                )}
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
              id="close-edit-modal"
            >
              Avbryt
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={saveOrder}
              disabled={loading}
            >
              {loading ? <LoadingSpinner /> : "Spara order"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditOrderModal;
