import { BASE_URL } from "../core/constants";
import { del, get, post, put } from "../core/fetch";
import { getUrlParams } from "../core/utils";

const BASE_TRANSPORT_ORDER_URL = BASE_URL + "/transport-orders";

const getDocument = async (id, type) => {
  const response = await get(`${BASE_TRANSPORT_ORDER_URL}/${id}/${type}`);
  if (response.ok) {
    return response;
  }
};

const TransportOrderService = {
  getTransportOrder: async (id) => {
    const response = await get(`${BASE_TRANSPORT_ORDER_URL}/${id}`);
    if (response.ok) {
      return await response.json();
    }
  },
  getPdf: async (id) => {
    return getDocument(id, "pdf");
  },
  getExcel: async (id) => {
    return getDocument(id, "excel");
  },
  createTransportOrder: async (transportOrder) => {
    const response = await post(BASE_TRANSPORT_ORDER_URL, {
      body: JSON.stringify(transportOrder)
    });
    if (response.ok) {
      return await response.json();
    }
  },
  addContent: async (transportOrderId, contentIds) => {
    const response = await put(
      `${BASE_TRANSPORT_ORDER_URL}/${transportOrderId}/content`,
      { body: JSON.stringify(contentIds) }
    );
    if (response.ok) {
      return response;
    }
  },
  removeContent: async (id) => {
    const response = await del(`${BASE_TRANSPORT_ORDER_URL}/${id}/content`);
    if (response.ok) {
      return response;
    }
  },
  removeContentById: async (transportOrderId, contentId) => {
    const response = await del(
      `${BASE_TRANSPORT_ORDER_URL}/${transportOrderId}/content/${contentId}`
    );
    if (response.ok) {
      return response;
    }
  },
  reportTransportOrder: async (id, payload) => {
    const response = await put(`${BASE_TRANSPORT_ORDER_URL}/${id}/report`, {
      body: JSON.stringify(payload)
    });
    return response;
  },
  getTransportOrders: async (params) => {
    const paramString = getUrlParams(params).toString();
    const response = await get(`${BASE_TRANSPORT_ORDER_URL}?${paramString}`);
    if (response.ok) {
      return await response.json();
    }
  },
  updateTransportOrder: async (id, payload) => {
    const response = await put(`${BASE_TRANSPORT_ORDER_URL}/${id}`, {
      body: JSON.stringify(payload)
    });
    if (response.ok) {
      return response;
    }
  }
};

export default TransportOrderService;
