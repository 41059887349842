import { invoiceBasisStatuses, invoiceStatuses } from "./constants";
import AuthenticationError from "./Errors/AuthenticationError";
import handlers from "./handlers";
import { deviations, orderStatuses } from "./order-constants";
import { waybillStatuses } from "./waybill-constants";

export const getUrlParams = (object) => {
  const sanitizedObject = {};
  Object.entries(object).forEach(([key, value]) => {
    if (value || value === false) {
      sanitizedObject[key] = value;
    }
  });

  const params = new URLSearchParams(sanitizedObject);

  return params;
};

export const toLocaleDateString = (date, locale = "sv-SE") =>
  new Date(date).toLocaleDateString([locale]);

export const toDateTimeString = (
  date,
  options = { hour: "2-digit", minute: "2-digit" }
) => {
  if (!date) {
    return "";
  } else if (!(date instanceof Date)) {
    date = new Date(date);
  }

  return date.toLocaleDateString("sv-SE", options);
};

export const getName = (constants, value) => constants[value]?.name;

export const getHandlerName = (value) => getName(handlers, value);

export const getOrderStatusName = (value) => getName(orderStatuses, value);

export const getDeviationName = (value) => getName(deviations, value);

export const getInvoiceStatusName = (value) => getName(invoiceStatuses, value);

export const getWaybillStatusName = (value) => getName(waybillStatuses, value);

export const getInvoiceBasisStatusName = (value) =>
  getName(invoiceBasisStatuses, value);

export const isImport = (entity) => entity.handler?.endsWith("IMPORT");

export const arraySorter = (a, b, property = "handler") => {
  switch (typeof a[property]) {
    case "string": {
      if (a[property] < b[property]) {
        return -1;
      } else if (a[property] < b[property]) {
        return 1;
      } else {
        return 0;
      }
    }
    case "number": {
      return a[property] - b[property];
    }
    default: {
      return 0;
    }
  }
};

export const getImportHandlersValues = () => {
  return Object.entries(handlers)
    .filter(([key]) => key.endsWith("IMPORT"))
    .map(([key, { value }]) => value);
};

export const getExportHandlersValues = () => {
  return Object.entries(handlers)
    .filter(([key, { inactive }]) => !key.endsWith("IMPORT") && !inactive)
    .map(([key, { value }]) => value);
};

export const round = (number) => {
  if (typeof number === "number") {
    return Number(Math.round(number + "e2") + "e-2");
  } else {
    return NaN;
  }
};

export const getCookie = (cookieName) => {
  const name = cookieName + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookies = decodedCookie.split(";");

  for (let cookie of cookies) {
    cookie = cookie.trimStart();
    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }

  return "";
};

export const COOKIES = {
  language: "language"
};

export const isAuthenticationError = (error) => {
  return (
    error instanceof AuthenticationError ||
    error?.reason instanceof AuthenticationError ||
    error?.status === 401 ||
    error?.reason?.status === 401
  );
};

export const getErrorType = (status) => {
  switch (status) {
    case 400:
      return "bad_request";
    case 403:
      return "unauthorized";
    case 404:
      return "not_found";
    default:
      return "generic";
  }
};
