import { useNavigate } from "react-router-dom";

export const withNavigation = (Component) => {
  const ComponentWithNavigation = (props) => {
    const navigate = useNavigate();

    return <Component navigate={navigate} {...props}></Component>;
  };

  return ComponentWithNavigation;
};
