import { useEffect, useState } from "react";
import ReportDatePicker from "../../components/Reporting/ReportDatePicker";
import SimpleInfoTag from "../../components/SimpleInfoTag";
import {
  arraySorter,
  getHandlerName,
  toLocaleDateString
} from "../../core/utils";
import WaybillReportService from "../../services/WaybillReportService";

function WaybillReport() {
  const [loading, setLoading] = useState(true);
  const [dates, setDates] = useState([]);
  const [chosenDate, setChosenDate] = useState(new Date());
  const [waybills, setWaybills] = useState([]);
  const [primaryOrder, setPrimaryOrder] = useState("handler");

  useEffect(() => {
    loadDates();
  }, []);

  useEffect(() => {
    loadWaybills();
  }, [chosenDate]);

  useEffect(() => {
    setWaybills((prev) => [
      ...prev.sort((a, b) => arraySorter(a, b, primaryOrder))
    ]);
  }, [primaryOrder]);

  const loadDates = async () => {
    const data = await WaybillReportService.getDates();
    if (data) {
      setDates(data.filter((date) => !!date));
    }
  };

  const loadWaybills = async () => {
    try {
      setLoading(true);
      const data = await WaybillReportService.getWaybills({
        pickupDate: toLocaleDateString(chosenDate)
      });
      if (data) {
        data.sort((a, b) => arraySorter(a, b, primaryOrder));
        setWaybills(data);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (date) => {
    setChosenDate(date);
  };

  const updateWaybill = (waybill, key, value) => {
    const clonedWaybills = [...waybills];
    const foundWaybill = clonedWaybills.find((w) => w.id === waybill.id);
    if (foundWaybill) {
      foundWaybill[key] = value;
    }
    setWaybills(clonedWaybills);
  };

  const saveWaybills = async () => {
    try {
      setLoading(true);
      const response = await WaybillReportService.report(
        JSON.stringify(waybills)
      );
      if (response) {
        await loadDates();
        await loadWaybills();
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container">
      <h2>Fraktsedel-rapportering</h2>
      <ReportDatePicker
        chosenDate={chosenDate}
        highlightedDates={dates}
        handleChange={handleChange}
      />
      <div className="row mb-2">
        <div className="col-md-4 col-lg-2 g-0">
          <label className="form-label">Sortering</label>
          <select
            className="form-select"
            value={primaryOrder}
            onChange={(e) => setPrimaryOrder(e.target.value)}
          >
            <option value="handler">Speditör</option>
            <option value="handlerSequence">Fraktsedel</option>
          </select>
        </div>
      </div>
      <div className="row">
        <form className="col-12 border border-secondary rounded p-2">
          {waybills.length ? (
            waybills.map((waybill) => (
              <div
                key={waybill.id}
                className="row border border-primary rounded p-1 mb-1 g-0"
              >
                <div className="col-md-1 p-1">
                  <label className="form-label">SPEDITÖR</label>
                  <div>
                    <SimpleInfoTag content={getHandlerName(waybill.handler)} />
                  </div>
                </div>
                <div className="col-md-2 p-1">
                  <label className="form-label">PICKUP DATE</label>
                  <input
                    className="form-control"
                    type="text"
                    value={
                      waybill.pickupDate
                        ? toLocaleDateString(waybill.pickupDate)
                        : ""
                    }
                    disabled
                  />
                </div>
                <div className="col-md-2 p-1">
                  <label className="form-label">FRAKTSEDEL</label>
                  <input
                    className="form-control"
                    type="text"
                    value={waybill.handlerSequence || ""}
                    disabled
                  />
                </div>
                <div className="col-md-2 p-1">
                  <label className="form-label">HOUSE</label>
                  <input
                    className="form-control"
                    type="text"
                    value={waybill.houses || ""}
                    disabled
                  />
                </div>
                <div className="col-md-2 p-1">
                  <label className="form-label">HÄMTAD</label>
                  <input
                    className="form-control"
                    value={waybill.reportPickupDate || ""}
                    onChange={({ target }) =>
                      updateWaybill(waybill, "reportPickupDate", target.value)
                    }
                    type="text"
                    placeholder="yyyy-MM-dd HH:mm"
                    disabled={loading}
                  />
                </div>
                <div className="col-md-2 p-1">
                  <label className="form-label">LÄMNAD</label>
                  <input
                    className="form-control"
                    value={waybill.reportDepartureDate || ""}
                    onChange={({ target }) =>
                      updateWaybill(
                        waybill,
                        "reportDepartureDate",
                        target.value
                      )
                    }
                    type="text"
                    placeholder="yyyy-MM-dd HH:mm"
                    disabled={loading}
                  />
                </div>
                <div className="col-md-1 p-1">
                  <label className="form-label">KVITTERAD</label>
                  <input
                    className="form-control"
                    value={waybill.acknowledgedBy || ""}
                    onChange={({ target }) =>
                      updateWaybill(waybill, "acknowledgedBy", target.value)
                    }
                    type="text"
                    disabled={loading}
                  />
                </div>
              </div>
            ))
          ) : (
            <div className="center-x">
              <span>Ingen data</span>
            </div>
          )}
          <div className="row">
            <div className="col-md-2">
              <button
                type="button"
                onClick={saveWaybills}
                className="btn btn-primary w-100"
                disabled={loading}
              >
                Spara
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default WaybillReport;
